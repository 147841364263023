import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import LandImage from "../../assets/landImage.png";
import CircularProgress from "@mui/material/CircularProgress";
import configParam from "../../config";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";


const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.replace(url);
  };

  // useEffect(() => {
  //   userDetails();
  // }, []);

  // const getUserDetails = async (id, token) => {
  //   token = token.replace(/"/g, "");
  //   id = id.replace(/"/g, "");
  //    try {
  //     await axios.get(`${API.GET_USER}?id=${id}`, {
  //       headers: {
  //         authorization: `Bearer ${token}`
  //       }
  //     }).then((res) => {
  //       if (res.status === 200) {
  //         let userData = res.data.data.user[0];
  //         userData["web_token"] = `Bearer ${token}`;
  //         localStorage.setItem("user", JSON.stringify(userData));
  //       }
  //     }).catch((error) => {
  //       console.log("ERROR", error);
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  useEffect(() => {
    const userDetails = async () => {
      const url = window.location.href;
      const response = decodeURIComponent(url);
      const myArray = response.split("?");
  
      if (myArray.length > 1) {
        const queryParameters = new URLSearchParams(window.location.search)
        const code = queryParameters.get("code");
        console.log("codeeee", code);
 
        if (code) {
          try {
            // Call the API to check the user's authentication status
            const Apiurl = configParam.API_URL;
            console.log("Apiurl",Apiurl);

            const response = await axios.get(`${Apiurl + "login"}?code=${code}`);
            console.log("responseresponse",response);
            
            if (response.status === 200) {
              const userData = response.data;
              const access_token = userData.data.access_token.replace(/"/g, "");
              const refresh_token=userData.data.refresh_token.replace(/"/g, "");
              const token=jwtDecode(access_token);
              if (token) {
                console.log("tokennn", token);
                await getUserDetails(token.userId, access_token,refresh_token);
              }
              else {
                localStorage.setItem("user", "");
                setError("Access denied");
              }
            }
          } catch (error) {
            // Handle any errors during the API call or authentication process
            console.error("Error during authentication:", error);
        
          }
        }
      }
    };
    const getUserDetails = async (id, access_token,refresh_token) => {
      access_token = access_token.replace(/"/g, "");
      console.log("access_token",access_token);
      console.log("refresshtoken", refresh_token)
      try {
        const response =  await axios.get(`${API.GET_USER}?id=${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }
        });

        console.log("responsee", response);
        if (response.status === 200) {
          if (response && response.data) {      
            let userData = response.data.user[0];
            userData["accesstoken"] = `${access_token}`;
            localStorage.setItem("user", JSON.stringify(userData));
            localStorage.setItem("accesstoken", access_token);
            localStorage.setItem("refreshtoken",refresh_token);
            navigate(`/case`);
            window.location.reload();
          }
        } else {
       
        }
      } catch (error) {
  
        // Handle other types of errors
       
  
      }
    }
    userDetails()
  }, [navigate]);




  // const userDetails = async () => {
  //   const url = window.location.href;
  //   const decodedUrl = decodeURIComponent(url);
  //   console.log("decodedUrl:", decodedUrl);

  //   const myArray = decodedUrl.split("?");
  //   if (myArray.length > 1) {
  //     const queryParameters = new URLSearchParams(window.location.search);
  //     console.log("queryParameters", queryParameters);

  //     const error = queryParameters.get("err");
  //     if (error) {
  //       console.error("Error:", error);
  //       setError(error || "Access denied");
  //       return; // Exit the function
  //     }

  //     const code = queryParameters.get("code");
  //     console.log("code", code);

  //     if (code) {
  //       const Apiurl = configParam.API_URL;
  //       const response = await axios.get(`${Apiurl + "login"}?code=${code}`);
  //       if (response.status === 200) {
  //         const userData = response.data;
  //         console.log("userData75", userData);
  //         let token = userData.data.access_token.replace(/"/g, ""); // Assuming this is a valid string.
 
  //         console.log("token123", token);
  //         const token1 = jwtDecode(token); // Decodes the token into an object.
  //        const accesstoken = token.replace(/"/g, "");

  //         console.log("Decoded token:", token1);

  //         const id = token1.userId;
      
  //         const apiUrl = `${API.GET_USER}?id=${id}`;
  //         console.log("Request URL:", apiUrl);
      
  //         try {
  //           const userResponse = await axios.get(apiUrl, {
  //             headers: {
  //               Authorization: `Bearer ${accesstoken}`, // Use the original access_token here.
  //             },
  //           });      
  //           if (userResponse.status === 200 && userResponse.data) {
  //             const user = userResponse.data;
  //             console.log("userData:", user);
  //             user["web_token"] = token; // Attach the token to the user data.
  //             localStorage.setItem("user", JSON.stringify(user));
  //             localStorage.setItem("accesstoken", token);
  //             navigate(path);
  //           } else {
  //             console.log("Server error");
  //             localStorage.setItem("userDetails", "");
  //             setError("Error fetching user details. Please try again.");
  //           }
  //         } catch (error) {
  //           console.error("Request failed:", error);
  //           setError(
  //             "An error occurred while fetching user details. Please try again."
  //           );
  //         } finally {
  //           setLoading(false);
  //         }
  //       }
  //     }
      
  //   }
  // };

  // const userDetails = async () => {
  //   const url = window.location.href;
  //   const Response = decodeURIComponent(url);
  //   const myArray = Response.split("?");
  //   if (myArray.length > 1) {
  //     const queryParameters = new URLSearchParams(window.location.search)
  //     const UserDetails = queryParameters.get("UserData");
  //     const UserSGID = queryParameters.get("sgid");
  //     const token = queryParameters.get("token");
  //     const err = queryParameters.get("err");
  //     const currentUserDetails = JSON.parse(UserDetails)
  //     if (currentUserDetails && Object.keys(currentUserDetails).length > 0) {
  //       await getUserDetails(UserSGID, token);
  //       navigate(`/case`);
  //       // window.location.href = "/case";
  //     } else {
  //       localStorage.setItem("user", "");
  //       setError(err || "Access denied");
  //       setLoading(false);
  //     }
  //   }
  // }

  return (
    <Grid container style={{ overflowY: "hidden", fontWeight: 600, height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Grid item md={6} xl={6} xs={12}>
        <div style={{ textAlign: "center" }}>
          {
            <img
              src={LandImage}
              alt=""
              style={{
                objectFit: "cover",
                horizontalAlignment: "center",
                verticalAlignment: "center",
                width: "100%",
                maxWidth: "600px"
              }}
            />
          }
        </div>
        <div style={{ textAlign: "center", marginTop: -10 }}>
          {!loading ? (
            <Button
              variant="contained"
              size="small"
              style={{
                width: "150px",
                backgroundColor: "#203468",
                textTransform: "capitalize",
                marginBottom: 25
              }}
              onClick={onHandleSSO}
            >
              Login
            </Button>
          ) : (
            <CircularProgress />
          )}
          <Typography
            variant="subtitle1"
            style={{ color: "red" }}
          >
            {error}&nbsp;
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;