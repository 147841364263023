import Login from "./pages/login/Login";
import Trials from "./pages/trials/Trials";
import CustomerInfo from "./pages/CrossFired/Stepper/index";
import UserProfile from "./pages/UserManagement/UserProfile";
import Dashboard from "./pages/Dashboard/index";

import { ReactComponent as NewCaseLight } from "../src/assets/sidebar/NewCase.svg";
import { ReactComponent as DashboardLight } from "../src/assets/sidebar/DashboardLight.svg";
import { ReactComponent as NewCaseSelected } from "../src/assets/sidebar/NewCaseSelected.svg";
import { ReactComponent as UserManagementLight } from "../src/assets/sidebar/UserManagementLight.svg";


const Routes = {
  mainRoutes: [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      icon: DashboardLight,
    },
    {
      path: "/case",
      name: "Case",
      component: Trials,
      icon: NewCaseLight,
      iconSelected: NewCaseSelected,
    },
    {
      path: "/AddCustomerInfo",
      name: "AddCustomerInfo",
      component: CustomerInfo,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/userprofile",
      name: "User Management",
      component: UserProfile,
      icon: UserManagementLight,
    },
  ],
};

export default Routes;
