import React, { useState, useEffect } from "react";
import { Typography, Grid, Card } from "@mui/material";
import ChartComponent from "../Chart/Chart";
import axios from "axios";
import API from "../../pages/Services/API";
import PieChart from "../Chart/PieChart";
import DashboardCards from "../Chart/Cards";
import Skeleton from "@mui/material/Skeleton";

const Index = () => {
  const [graphData, setGraphData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const userDetails = localStorage.getItem("user");
  const userValue = JSON.parse(userDetails);
  const profileName = userValue.first_name + " " + userValue.last_name;
  const access_token = localStorage.getItem("accesstoken");


  const getTrialsCount = () => {
    axios
      .get(API.GET_TRIALS_COUNT, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          const zoneCount = response.data.result;
          setGraphData(zoneCount.rows);
        } else {
          setError("Failed to fetch data");
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setError("An error occurred while fetching data");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTrialsCount();
  }, []);

  if (isLoading) {
    return <div className="skeleton-dashboard" style={{ width: "100%" }}>
      <Grid container style={{ justifyContent: "space-between", marginTop: "30px" }}>
        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
        <Skeleton style={{ width: "19%", height: 110, transform: "none" }} />
      </Grid>
      <Grid container style={{ justifyContent: "space-between", marginTop: "20px" }}>
        <Skeleton style={{ width: "33%", height: 400, transform: "none" }} />
        <Skeleton style={{ width: "65%", height: 400, transform: "none" }} />
      </Grid>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const trialsData = [
    { name: "Total Trials", value: 0 },
    { name: "America", value: 0 },
    { name: "Asia", value: 0 },
    { name: "Europe", value: 0 },
    { name: "Central", value: 0 },
  ];

  // Initialize variables to store the total trials count and trials count per zone name
  let totalTrialsCount = 0;
  const zoneTrialsCount = {};

  // Loop through the data array
  graphData.forEach((item) => {
    const trials = parseInt(item.total_trials); // Convert total_trials to an integer
    totalTrialsCount += trials; // Increment the total trials count

    // Increment the trials count for each zone name
    const zoneName = item.zone_name;
    zoneTrialsCount[zoneName] = (zoneTrialsCount[zoneName] || 0) + trials;
  });

  return (
    <Grid container spacing={1}>
      {/* Show Graphs */}
      <Grid item xs={12}>
        <Typography
          id="user_name"
          style={{ fontSize: "20px", fontWeight: 600 }}
        >
          Welcome {profileName}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid>
          <DashboardCards
            trialsData={trialsData}
            totalTrials={totalTrialsCount || 0}
            americaTrials={
              zoneTrialsCount.America ? zoneTrialsCount.America : 0
            }
            asiaTrials={zoneTrialsCount.Asia ? zoneTrialsCount.Asia : 0}
            europeTrials={zoneTrialsCount.Europe ? zoneTrialsCount.Europe : 0}
            centralTrials={
              zoneTrialsCount.Central ? zoneTrialsCount.Central : 0
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xl={4} md={4} xs={12} style={{ marginTop: "24px" }}>
            <Card
              style={{
                boxShadow: "none",
                marginRight: "0",
                height: "100%",
                border: "1px solid #E8E8E8",
                backgroundColor: "#FCFCFC",
                padding: 10,
              }}
            >
              {graphData.length > 0 ? (
                <PieChart series={graphData} />
              ) : (
                <div>No data available</div>
              )}
            </Card>
          </Grid>
          <Grid
            item
            xl={8}
            md={8}
            xs={12}
            className="pl-md"
            style={{ marginTop: "24px" }}
          >
            <Card
              style={{
                marginRight: "0px",
                boxShadow: "none",
                backgroundColor: "#FCFCFC",
                border: "1px solid #E8E8E8",
                padding: 10,
              }}
            >
              {graphData.length > 0 ? (
                <ChartComponent series={graphData} />
              ) : (
                <div>No data available</div>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;