import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';


const SuccessDialog = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
         {/* <TaskAltIcon style={{ marginLeft: '40%' }} fontSize="large" color="success" /> */} 
          <DialogContentText>
            <p style={{ color: 'green', fontWeight: 'bold' }}>
              {props.msg ? props.msg : 'Form submitted successfully'}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessDialog;
