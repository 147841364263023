import { selector, useRecoilValue } from "recoil";
import {
  fuelInfo,
  furnaceDetails,
  temparatures,
  fumesComposition,
  dustsAnalysis,
  customerInfoData,
  fumesElemCorrelation,
  selectedTrialObj,
} from "../recoil/atoms";
import configParam from "../config";

const roundUp = configParam.Round_Up;
const deepCopy = configParam.DEEP_COPY;
const getVisitData = configParam.GET_DATA;
const getVisitNo = configParam.getVisitNumbers;

//SUM Formula
const getSumVal = (sample) =>
  sample
    ? roundUp(
      sample.reduce((sum, value) => sum + value, 0),
      2
    )
    : 0;

const functionForNullCheck = (option, val1, val2, val3) => {
  if (val2 !== null && val2.length > 0) {
    if (option === "roundup") {
      return roundUp(val1 / val2.length, val3);
    }
    else {
      return (val1 / val2.length);
    }
  }
  else {
    return null;
  }
}

//Common Function
const commonFunction = (position, CFF, EFF) => {
  const customerInfo = useRecoilValue(customerInfoData);
  const { furnaceType, chamberType } = customerInfo;

  if (furnaceType === "End Fired") {
    if (chamberType === "One Side") {
      return position === "singleValue" ? [EFF] : [EFF.symmetricData];
    } else {
      return handlePositionForEndFired(chamberType, position, EFF);
    }
  } else {
    if (chamberType === "One Side") {
      return position === "singleValue" ? Object.values(CFF) : Object.values(CFF.symmetricData);
    } else {
      return handlePositionForCrossFired(position, CFF);
    }
  }
};

const handlePositionForEndFired = (chamberType, position, EFF) => {
  switch (position) {
    case "left":
      return [EFF.left];
    case "singleValue":
      return [EFF];
    default:
      return [EFF.right];
  }
};

const handlePositionForCrossFired = (position, CFF) => {
  switch (position) {
    case "left":
      return Object.values(CFF.left);
    case "singleValue":
      return Object.values(CFF);
    default:
      // Handle default case, if any
      break;
  }
};

// Customer Information
export const setCustDetails = selector({
  key: "setCustDetails",
  get: ({ get }) => {
    let tempCustInfo = get(getVisitData())[getVisitNo()]["customerInfo"];
    return tempCustInfo?.customerInfo;
  },
  set: ({ set }) => {
    let tempCustInfo = getVisitData()[getVisitNo()]["customerInfo"];
    if (tempCustInfo) {
      set(customerInfoData, tempCustInfo);
    }
  },
});

// Gas & Fuel Info
export const setGasFuelDetails = selector({
  key: "setGasFuelDetails",
  get: ({ get }) => {
    let tempFlows = get(getVisitData())[getVisitNo()]["fuelData"];
    return tempFlows?.fuelData;
  },
  set: ({ set }) => {
    let tempFlows = getVisitData()[getVisitNo()]["fuelData"];
    if (tempFlows) {
      set(fuelInfo, tempFlows);
    }
  },
});

// Batch and Regenerator Info
export const setFurnaceDetails = selector({
  key: "setFurnaceDetails",
  get: ({ get }) => {
    let tempFurnace = get(getVisitData())[getVisitNo()]["furnaceDetails"];
    return tempFurnace?.furnaceDetails;
  },
  set: ({ set }) => {
    let tempFurnace = getVisitData()[getVisitNo()]["furnaceDetails"];
    if (tempFurnace) {
      set(furnaceDetails, tempFurnace);
    }
  },
});

// Temperatures Info
export const setTemparaturesDetails = selector({
  key: "setTemparaturesDetails",
  get: ({ get }) => {
    let tempTemparatures = get(getVisitData())[getVisitNo()]["temparatures"];
    return tempTemparatures?.temparatures;
  },
  set: ({ set }) => {
    let tempTemparatures = getVisitData()[getVisitNo()]["temparatures"];
    if (tempTemparatures) {
      set(temparatures, tempTemparatures);
    }
  },
});

// Fumes Composition Info
export const setFumesCompositionDetails = selector({
  key: "setFumesCompositionDetails",
  get: ({ get }) => {
    let tempFumesCompo = get(getVisitData())[getVisitNo()]["fumesComposition"];
    return tempFumesCompo?.fumesComposition;
  },
  set: ({ set }) => {
    let tempFumesCompo = getVisitData()[getVisitNo()]["fumesComposition"];
    if (tempFumesCompo) {
      set(fumesComposition, tempFumesCompo);
    }
  },
});

// Dusts Analysis Info
export const setDustsAnalysisDetails = selector({
  key: "setDustsAnalysisDetails",
  get: ({ get }) => {
    let tempDusts = get(getVisitData())[getVisitNo()]["dustsAnalysis"];
    return tempDusts?.dustsAnalysis;
  },
  set: ({ set }) => {
    let tempDusts = getVisitData()[getVisitNo()]["dustsAnalysis"];
    if (tempDusts) {
      set(dustsAnalysis, tempDusts);
    }
  },
});

//Flows
export const calcGazData = selector({
  key: "calcGazData",
  get: ({ get }) => {
    const tmpGaz = get(fuelInfo);
    const gazOrder = [
      "h2", "co", "ch4", "c2h4", "c2h6", "c3h6", "c3h8", "c4h8", "c4h10", "c5h12", "co2", "n2", "o2",
    ];
    const gasAnalysisVol = gazOrder !== null && gazOrder.length > 0
      ? gazOrder
        .map((key) => tmpGaz.gaz.volPercentage[key])
        .filter(Boolean)
        .join(", ")
      : "";
    const gasAnalysisVolArray = gasAnalysisVol.split(", ");
    let gasAnalysisVolSum = gasAnalysisVolArray.reduce(
      (acc, cur) => roundUp(acc + parseFloat(cur), 3),
      0
    );
    return {
      gazDetails: {
        gasAnalysisVol: gasAnalysisVol,
        gasAnalysisVolSum: gasAnalysisVolSum
      },
    };
  },
});

export const calcFuelData = selector({
  key: "calcFuelData",
  get: ({ get }) => {
    const tmpFuel = get(fuelInfo);
    const fuelOrder = [
      "carbon", "hydrogen", "oxygen", "nitrogen", "sulphur", "humidity",
    ];
    const fuelAnalysisMass = fuelOrder !== null && fuelOrder.length > 0
      ? fuelOrder
        .map((key) => tmpFuel.oil.analysisPercMass[key])
        .filter(Boolean)
        .join(", ")
      : "";
    const fuelMassArray = fuelAnalysisMass.split(", ");
    let fuelAnalysisMassSum = fuelMassArray.reduce(
      (acc, cur) => roundUp(acc + parseFloat(cur), 3),
      0
    );
    const fuelCarbonMoles = roundUp((fuelMassArray[0] * 10) / 12, 2);
    const fuelHydrogenMoles = roundUp((fuelMassArray[1] * 10) / 2, 2);
    const fuelOxygenMoles = roundUp((fuelMassArray[2] * 10) / 32, 2);
    const fuelNitrogenMoles = roundUp((fuelMassArray[3] * 10) / 28, 2);
    const fuelSulphurMoles = roundUp((fuelMassArray[4] * 10) / 32, 2);
    const fuelHumidityMoles = roundUp((fuelMassArray[5] * 10) / 18, 2);
    const totalFuelMoles = roundUp(
      fuelCarbonMoles +
      fuelHydrogenMoles +
      fuelOxygenMoles +
      fuelNitrogenMoles +
      fuelSulphurMoles +
      fuelHumidityMoles,
      2
    );
    return {
      fuelDetails: {
        fuelAnalysisMass: fuelAnalysisMass,
        fuelAnalysisMassSum: fuelAnalysisMassSum,
        fuelCarbonMoles: fuelCarbonMoles,
        fuelHydrogenMoles: fuelHydrogenMoles,
        fuelOxygenMoles: fuelOxygenMoles,
        fuelNitrogenMoles: fuelNitrogenMoles,
        fuelSulphurMoles: fuelSulphurMoles,
        fuelHumidityMoles: fuelHumidityMoles,
        totalFuelMoles: totalFuelMoles,
      },
    };
  },
});

export const consumptionGasLeftData = selector({
  key: "consumptionGasLeftData",
  get: ({ get }) => {
    const tmpConsumpGasLeft = get(fuelInfo);
    let customerData = useRecoilValue(customerInfoData);
    const consumpGasLeft =
      customerData.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConsumpGasLeft.gaz.consumptionCFF,
          tmpConsumpGasLeft.gaz.consumptionEFF
        )
        : commonFunction(
          "left",
          tmpConsumpGasLeft.gaz.consumptionCFF,
          tmpConsumpGasLeft.gaz.consumptionEFF
        );
    let consumpGasLeftSide1 = [];
    consumpGasLeft.map((data) => {
      if (data) {
        consumpGasLeftSide1.push(Number(data));
      }
    });
    let sumConsumpGasLeftSide;
    let gazLeftPercentage;
    let gazLeftPercentageSide = [];
    if (customerData.furnaceType === "Cross Fired") {
      sumConsumpGasLeftSide = getSumVal(consumpGasLeftSide1);
    }
    for (let val of consumpGasLeftSide1) {
      if (customerData.furnaceType === "Cross Fired") {
        if (val === 0 && sumConsumpGasLeftSide === 0) {
          gazLeftPercentage = 0;
        } else {
          gazLeftPercentage = roundUp((val / sumConsumpGasLeftSide) * 100, 0);
        }
        gazLeftPercentageSide.push(gazLeftPercentage);
      }
    }
    let gazLeftTotalAvgPercentage;
    if (customerData.furnaceType === "Cross Fired") {
      gazLeftTotalAvgPercentage =
        getSumVal(gazLeftPercentageSide);
    }
    return {
      consumpGasLeftDetails: {
        sumConsumpGasLeftSide: sumConsumpGasLeftSide,
        gazLeftPercentageSide: gazLeftPercentageSide,
        gazLeftTotalAvgPercentage: gazLeftTotalAvgPercentage,
      },
    };
  },
});

export const consumptionGasRightData = selector({
  key: "consumptionGasRightData",
  get: ({ get }) => {
    const tmpConsumpGasRight = get(fuelInfo);
    let customerData = useRecoilValue(customerInfoData);
    console.log(customerData, "customerData");
    let consumpGasRight =
      customerData.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConsumpGasRight.gaz.consumptionCFF,
          tmpConsumpGasRight.gaz.consumptionEFF
        )
        : commonFunction(
          "right",
          tmpConsumpGasRight.gaz.consumptionCFF,
          tmpConsumpGasRight.gaz.consumptionEFF
        );
    let consumpGasRightSide1 = [];
    consumpGasRight.map((data) => {
      if (data) {
        consumpGasRightSide1.push(Number(data));
      }
    });
    let sumOfConsumpGasRightSide;
    let gazRightPercentage;
    let gazRightPercentageSide = [];
    if (customerData.furnaceType === "Cross Fired") {
      sumOfConsumpGasRightSide = getSumVal(consumpGasRightSide1);
    }
    for (let val of consumpGasRightSide1) {
      if (customerData.furnaceType === "Cross Fired") {
        if (val === 0 && sumOfConsumpGasRightSide === 0) {
          gazRightPercentage = 0;
        } else {
          gazRightPercentage = roundUp((val / sumOfConsumpGasRightSide) * 100, 0);
        }
        gazRightPercentageSide.push(gazRightPercentage);
      }
    }

    let gazRightTotalAvgPercentage;
    if (customerData.furnaceType === "Cross Fired") {
      gazRightTotalAvgPercentage =
        getSumVal(gazRightPercentageSide);
    }
    let result = {
      consumpGasRightDetails: {
        sumConsumpGasRightSide: sumOfConsumpGasRightSide,
        gazRightPercentageSide: gazRightPercentageSide,
        gazRightTotalAvgPercentage: gazRightTotalAvgPercentage,
      }
    }
    return result;
  }
});

export const consumptionFuelLeftData = selector({
  key: "consumptionFuelLeftData",
  get: ({ get }) => {
    const tmpConsumpFuelLeft = get(fuelInfo);
    let customerData = useRecoilValue(customerInfoData);
    const consumpFuelLeft =
      customerData.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConsumpFuelLeft.oil.consumptionCFF,
          tmpConsumpFuelLeft.oil.consumptionEFF
        )
        : commonFunction(
          "left",
          tmpConsumpFuelLeft.oil.consumptionCFF,
          tmpConsumpFuelLeft.oil.consumptionEFF
        );
    let consumpFuelLeftSide1 = [];
    consumpFuelLeft.map((data) => {
      if (data) {
        consumpFuelLeftSide1.push(Number(data));
      }
    });
    let sumConsumpFuelLeftSide;
    let fuelLeftPercentage;
    let fuelLeftPercentageSide = [];
    if (customerData.furnaceType === "Cross Fired") {
      sumConsumpFuelLeftSide = getSumVal(consumpFuelLeftSide1);
    }
    for (let val of consumpFuelLeftSide1) {
      if (customerData.furnaceType === "Cross Fired") {
        if (val === 0 && sumConsumpFuelLeftSide === 0) {
          fuelLeftPercentage = 0;
        } else {
          fuelLeftPercentage = roundUp((val / sumConsumpFuelLeftSide) * 100, 0);
        }
        fuelLeftPercentageSide.push(fuelLeftPercentage);
      }
    }
    let fuelLeftTotalAvgPercentage;
    if (customerData.furnaceType === "Cross Fired") {
      fuelLeftTotalAvgPercentage = getSumVal(fuelLeftPercentageSide);
    }
    return {
      consumpFuelLeftDetails: {
        consumpFuelLeftSide1: consumpFuelLeftSide1,
        sumConsumpFuelLeftSide: sumConsumpFuelLeftSide,
        fuelLeftPercentageSide: fuelLeftPercentageSide,
        fuelLeftTotalAvgPercentage: fuelLeftTotalAvgPercentage,
      },
    };
  },
});

export const consumptionFuelRightData = selector({
  key: "consumptionFuelRightData",
  get: ({ get }) => {
    const tmpConsumpFuelRight = get(fuelInfo);
    let customerData = useRecoilValue(customerInfoData);
    const consumpFuelRight =
      customerData.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConsumpFuelRight.oil.consumptionCFF,
          tmpConsumpFuelRight.oil.consumptionEFF
        )
        : commonFunction(
          "right",
          tmpConsumpFuelRight.oil.consumptionCFF,
          tmpConsumpFuelRight.oil.consumptionEFF
        );
    let consumpFuelRightSide1 = [];
    consumpFuelRight.map((data) => {
      if (data) {
        consumpFuelRightSide1.push(Number(data));
      }
    });
    let sumConsumpFuelRightSide;
    let fuelRightPercentage;
    let fuelRightPercentageSide = [];
    if (customerData.furnaceType === "Cross Fired") {
      sumConsumpFuelRightSide = getSumVal(consumpFuelRightSide1);
    }
    for (let val of consumpFuelRightSide1) {
      if (customerData.furnaceType === "Cross Fired") {
        if (val === 0 && sumConsumpFuelRightSide === 0) {
          fuelRightPercentage = 0;
        } else {
          fuelRightPercentage = roundUp((val / sumConsumpFuelRightSide) * 100, 0);
        }
        fuelRightPercentageSide.push(fuelRightPercentage);
      }
    }
    let fuelRightTotalAvgPercentage;
    if (customerData.furnaceType === "Cross Fired") {
      fuelRightTotalAvgPercentage =
        getSumVal(fuelRightPercentageSide);
    }
    return {
      consumpFuelRightDetails: {
        consumpFuelRightSide1: consumpFuelRightSide1,
        sumConsumpFuelRightSide: sumConsumpFuelRightSide,
        fuelRightPercentageSide: fuelRightPercentageSide,
        fuelRightTotalAvgPercentage: fuelRightTotalAvgPercentage,
      },
    };
  },
});

export const calcBatchLeftData = selector({
  key: "calcBatchLeftData",
  get: ({ get }) => {
    const tmpBatchLeftData = get(furnaceDetails);
    const batchLeft = commonFunction(
      "left",
      tmpBatchLeftData.batchData.distributionCFF,
      tmpBatchLeftData.batchData.distributionEFF
    );
    const batchLeftSide = [];
    batchLeft.map((data) => {
      if (data) {
        batchLeftSide.push(Number(data));
      }
    });
    const sumBatchLeft = getSumVal(batchLeftSide);
    const sumBatchLeftPercentage = sumBatchLeft;
    return {
      batchLeftDetails: {
        sumBatchLeftPercentage: sumBatchLeftPercentage,
      },
    };
  },
});

export const calcBatchRightData = selector({
  key: "calcBatchRightData",
  get: ({ get }) => {
    const tmpBatchRightData = get(furnaceDetails);
    const batchRight = commonFunction(
      "right",
      tmpBatchRightData.batchData.distributionCFF,
      tmpBatchRightData.batchData.distributionEFF
    );
    const batchRightSide = [];
    batchRight.map((data) => {
      if (data) {
        batchRightSide.push(Number(data));
      }
    });
    const sumBatchRightPercentage = getSumVal(batchRightSide);
    return {
      batchRightDetails: {
        sumBatchRightPercentage: sumBatchRightPercentage,
      },
    };
  },
});

export const regenDesignData = selector({
  key: "regenDesignData",
  get: ({ get }) => {
    const tmpRegenData = get(furnaceDetails);
    const regenDepth = commonFunction(
      "singleValue",
      tmpRegenData.regeneratorDesign.chamberDimensionsCFF.depth,
      tmpRegenData.regeneratorDesign.chamberDimensionsEFF.depth
    );
    const regeneratorDepth = [];
    regenDepth.map((data) => {
      if (data) {
        regeneratorDepth.push(Number(data));
      }
    });
    const regenWidth = commonFunction(
      "singleValue",
      tmpRegenData.regeneratorDesign.chamberDimensionsCFF.width,
      tmpRegenData.regeneratorDesign.chamberDimensionsEFF.width
    );
    const regeneratorWidth = [];
    regenWidth.map((data) => {
      if (data) {
        regeneratorWidth.push(Number(data));
      }
    });
    const regenThickness = commonFunction(
      "singleValue",
      tmpRegenData.regeneratorDesign.packingPropertiesCFF.thickness,
      tmpRegenData.regeneratorDesign.packingPropertiesEFF.thickness
    );
    const regeneratorThickness = [];
    regenThickness.map((data) => {
      if (data) {
        regeneratorThickness.push(Number(data));
      }
    });
    const regenDepthChannels = commonFunction(
      "singleValue",
      tmpRegenData.regeneratorDesign.noOfChannelsCFF.depthChannels,
      tmpRegenData.regeneratorDesign.noOfChannelsEFF.depthChannels
    );
    const regeneratorDepthChannels = [];
    regenDepthChannels.map((data) => {
      if (data) {
        regeneratorDepthChannels.push(Number(data));
      }
    });
    const regenWidthChannels = commonFunction(
      "singleValue",
      tmpRegenData.regeneratorDesign.noOfChannelsCFF.widthChannels,
      tmpRegenData.regeneratorDesign.noOfChannelsEFF.widthChannels
    );
    const regeneratorWidthChannels = [];
    regenWidthChannels.map((data) => {
      if (data) {
        regeneratorWidthChannels.push(Number(data));
      }
    });
    //Flows Geometry
    const numberOfFluesA = regeneratorWidthChannels;
    const numberOfFluesB = regeneratorDepthChannels;
    const availableAreaA = regeneratorWidth
      .reduce((acc, width, index) => {
        const flueAvailableAreaA = numberOfFluesA[index] + 1;
        const thickness = regeneratorThickness[index];
        const areaA = (width - flueAvailableAreaA * thickness);
        acc.push(areaA);
        return acc;
      }, [])
      .map((area) => roundUp(area, 4));
    const availableAreaB = regeneratorDepth
      .reduce((acc, width, index) => {
        const flueAvailableAreaB = numberOfFluesB[index] + 1;
        const thickness = regeneratorThickness[index];
        const areaB = (width - flueAvailableAreaB * thickness);
        acc.push(areaB);
        return acc;
      }, [])
      .map((area) => roundUp(area, 4));
    return {
      regenDesignDetails: {
        numberOfFluesA: numberOfFluesA,
        numberOfFluesB: numberOfFluesB,
        availableAreaA: availableAreaA,
        availableAreaB: availableAreaB,
      },
    };
  },
});

//Efficiencies Air & Fumes Temperatures
export const airFumesTempData = selector({
  key: "airFumesTempData",
  get: ({ get }) => {
    const tmpAirFumesTemp = get(temparatures);
    const customerDetails = useRecoilValue(customerInfoData);
    let bottomColdAirRightAverage = [];
    let bottomColdAirLeftAverage = [];
    let topPreheatedAirLeftAverage = [];
    let topPreheatedAirRightAverage = [];
    let bottomColdFumesLeftAverage = [];
    let bottomColdFumesRightAverage = [];
    let topHotFumesLeftAverage = [];
    let topHotFumesRightAverage = [];
    //Bottom cold air left data
    const bottomColdAirLeft =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpAirFumesTemp.airTemperatureCFF.bottom,
          tmpAirFumesTemp.airTemperatureEFF.bottom
        )
        : commonFunction(
          "left",
          tmpAirFumesTemp.airTemperatureCFF.bottom,
          tmpAirFumesTemp.airTemperatureEFF.bottom
        );
    //Bottom cold air right data
    const bottomColdAirRight =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpAirFumesTemp.airTemperatureCFF.bottom,
          tmpAirFumesTemp.airTemperatureEFF.bottom
        )
        : commonFunction(
          "right",
          tmpAirFumesTemp.airTemperatureCFF.bottom,
          tmpAirFumesTemp.airTemperatureEFF.bottom
        );
    let bottomColdAirLeftSide = [];
    bottomColdAirLeft.map((data) => {
      if (data) {
        bottomColdAirLeftSide.push(Number(data));
      }
    });
    let bottomColdAirRightSide = [];
    bottomColdAirRight.map((data) => {
      if (data) {
        bottomColdAirRightSide.push(Number(data));
      }
    });
    //Left Side
    let nonZeroValuesLeft = [];
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesLeft = bottomColdAirLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdAirLeftSum = [];
      bottomColdAirLeftSum = nonZeroValuesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirLeftAverage = functionForNullCheck("roundup", bottomColdAirLeftSum, nonZeroValuesLeft, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let bottomColdAirLeftSum2 = bottomColdAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdAirRightSum1 = bottomColdAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirLeftAverage = roundUp(
        (bottomColdAirLeftSum2 + bottomColdAirRightSum1) / 2,
        2
      );
    } else {
      bottomColdAirLeftAverage = bottomColdAirLeftSide;
    }
    //Right Side
    let nonZeroValuesRight = [];
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesRight = bottomColdAirRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdAirRightSum = [];
      bottomColdAirRightSum = nonZeroValuesRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirRightAverage = functionForNullCheck("roundup", bottomColdAirRightSum, nonZeroValuesRight, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let bottomColdAirLeftSum3 = bottomColdAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdAirRightSum3 = bottomColdAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirRightAverage = roundUp(
        (bottomColdAirLeftSum3 + bottomColdAirRightSum3) / 2,
        2
      );
    } else {
      bottomColdAirRightAverage = bottomColdAirRightSide;
    }
    //Top preheated air left data
    const topPreheatedAirLeft =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpAirFumesTemp.airTemperatureCFF.top,
          tmpAirFumesTemp.airTemperatureEFF.top
        )
        : commonFunction(
          "left",
          tmpAirFumesTemp.airTemperatureCFF.top,
          tmpAirFumesTemp.airTemperatureEFF.top
        );
    //Top preheated air right data
    const topPreheatedAirRight =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpAirFumesTemp.airTemperatureCFF.top,
          tmpAirFumesTemp.airTemperatureEFF.top
        )
        : commonFunction(
          "right",
          tmpAirFumesTemp.airTemperatureCFF.top,
          tmpAirFumesTemp.airTemperatureEFF.top
        );
    let topPreheatedAirLeftSide = [];
    topPreheatedAirLeft.map((data) => {
      if (data) {
        topPreheatedAirLeftSide.push(Number(data));
      }
    });
    let topPreheatedAirRightSide = [];
    topPreheatedAirRight.map((data) => {
      if (data) {
        topPreheatedAirRightSide.push(Number(data));
      }
    });
    //Left Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesLeft = topPreheatedAirLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topPreheatedAirLeftSum = nonZeroValuesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirLeftAverage = functionForNullCheck("roundup", topPreheatedAirLeftSum, nonZeroValuesLeft, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let topPreheatedAirLeftSum2 = topPreheatedAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topPreheatedAirRightSum1 = topPreheatedAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirLeftAverage = roundUp(
        (topPreheatedAirLeftSum2 + topPreheatedAirRightSum1) / 2,
        2
      );
    } else {
      topPreheatedAirLeftAverage = topPreheatedAirLeftSide;
    }
    //Right Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesRight = topPreheatedAirRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topPreheatedAirRightSum2 = nonZeroValuesRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirRightAverage = functionForNullCheck("roundUp", topPreheatedAirRightSum2, nonZeroValuesRight, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let topPreheatedAirLeftSum3 = topPreheatedAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topPreheatedAirRightSum3 = topPreheatedAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirRightAverage = roundUp(
        (topPreheatedAirLeftSum3 + topPreheatedAirRightSum3) / 2,
        2
      );
    } else {
      topPreheatedAirRightAverage = topPreheatedAirRightSide;
    }
    //Bottom cold fumes left data
    const bottomColdFumesLeft =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpAirFumesTemp.fumesTemperatureCFF.bottom,
          tmpAirFumesTemp.fumesTemperatureEFF.bottom
        )
        : commonFunction(
          "left",
          tmpAirFumesTemp.fumesTemperatureCFF.bottom,
          tmpAirFumesTemp.fumesTemperatureEFF.bottom
        );
    //Bottom cold fumes right data
    const bottomColdFumesRight =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpAirFumesTemp.fumesTemperatureCFF.bottom,
          tmpAirFumesTemp.fumesTemperatureEFF.bottom
        )
        : commonFunction(
          "right",
          tmpAirFumesTemp.fumesTemperatureCFF.bottom,
          tmpAirFumesTemp.fumesTemperatureEFF.bottom
        );
    let bottomColdFumesLeftSide = [];
    bottomColdFumesLeft.map((data) => {
      if (data) {
        bottomColdFumesLeftSide.push(Number(data));
      }
    });
    let bottomColdFumesRightSide = [];
    bottomColdFumesRight.map((data) => {
      if (data) {
        bottomColdFumesRightSide.push(Number(data));
      }
    });
    //Left Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesLeft = bottomColdFumesLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdFumesLeftSum = nonZeroValuesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesLeftAverage = functionForNullCheck("roundUp", bottomColdFumesLeftSum, nonZeroValuesLeft, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let bottomColdFumesLeftSum2 = bottomColdFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdFumesRightSum1 = bottomColdFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesLeftAverage = roundUp(
        (bottomColdFumesLeftSum2 + bottomColdFumesRightSum1) / 2,
        2
      );
    } else {
      bottomColdFumesLeftAverage = bottomColdFumesLeftSide;
    }
    //Right Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesRight = bottomColdFumesRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdFumesRightSum2 = nonZeroValuesRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesRightAverage = functionForNullCheck("roundup", bottomColdFumesRightSum2, nonZeroValuesRight, 2);
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let bottomColdFumesLeftSum3 = bottomColdFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdFumesRightSum3 = bottomColdFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesRightAverage = roundUp(
        (bottomColdFumesLeftSum3 + bottomColdFumesRightSum3) / 2,
        2
      );
    } else {
      bottomColdFumesRightAverage = bottomColdFumesRightSide;
    }
    //Top hot fumes left data
    const topHotFumesLeft =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpAirFumesTemp.fumesTemperatureCFF.top,
          tmpAirFumesTemp.fumesTemperatureEFF.top
        )
        : commonFunction(
          "left",
          tmpAirFumesTemp.fumesTemperatureCFF.top,
          tmpAirFumesTemp.fumesTemperatureEFF.top
        );
    //Top hot fumes right data
    const topHotFumesRight =
      customerDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpAirFumesTemp.fumesTemperatureCFF.top,
          tmpAirFumesTemp.fumesTemperatureEFF.top
        )
        : commonFunction(
          "right",
          tmpAirFumesTemp.fumesTemperatureCFF.top,
          tmpAirFumesTemp.fumesTemperatureEFF.top
        );
    let topHotFumesLeftSide = [];
    topHotFumesLeft.map((data) => {
      if (data) {
        topHotFumesLeftSide.push(Number(data));
      }
    });
    const topHotFumesRightSide = [];
    topHotFumesRight.map((data) => {
      if (data) {
        topHotFumesRightSide.push(Number(data));
      }
    });
    //Left Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesLeft = topHotFumesLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topHotFumesLeftSum1 = nonZeroValuesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesLeftAverage = functionForNullCheck("roundup",
        topHotFumesLeftSum1, nonZeroValuesLeft,
        2
      );
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let topHotFumesRightSum1 = topHotFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topHotFumesLeftSum2 = topHotFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesLeftAverage = roundUp(
        (topHotFumesRightSum1 + topHotFumesLeftSum2) / 2,
        2
      );
    } else {
      topHotFumesLeftAverage = topHotFumesLeftSide;
    }
    //Right Side
    if (
      customerDetails.furnaceType === "Cross Fired" &&
      (customerDetails.chamberType === "Both Side" ||
        customerDetails.chamberType === "One Side")
    ) {
      nonZeroValuesRight = topHotFumesRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      // let topHotFumesRightSum2 = nonZeroValuesRight.reduce(
      //   (acc, cur) => acc + cur,
      //   0
      // );
      // let topHotFumesRightAverage = [];
      // topHotFumesRightAverage = functionForNullCheck("roundup",
      //   topHotFumesRightSum2, nonZeroValuesRight,
      //   2
      // );
    } else if (
      customerDetails.furnaceType === "End Fired" &&
      customerDetails.chamberType === "Both Side"
    ) {
      let topHotFumesLeftSum3 = topHotFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topHotFumesRightSum3 = topHotFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesRightAverage = roundUp(
        (topHotFumesLeftSum3 + topHotFumesRightSum3) / 2,
        2
      );
    } else {
      topHotFumesRightAverage = topHotFumesRightSide;
    }
    //Themal Efficiency% Total/Average left data
    const themalEffiTotalAvgLeft = roundUp(
      ((topPreheatedAirLeftAverage - bottomColdAirLeftAverage) /
        (topHotFumesLeftAverage - bottomColdAirLeftAverage)) *
      100,
      1
    );
    //Themal Efficiency% Total/Average right data
    const themalEffiTotalAvgRight = roundUp(
      ((topPreheatedAirRightAverage - bottomColdAirRightAverage) /
        (topHotFumesRightAverage - bottomColdAirRightAverage)) *
      100,
      1
    );
    //Themal Efficiency% left data
    const themalEfficiencyLeft = topPreheatedAirLeftSide.map((num, index) => {
      if (
        num === 0 &&
        bottomColdAirLeftSide[index] === 0 &&
        topHotFumesLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          ((num - bottomColdAirLeftSide[index]) /
            (topHotFumesLeftSide[index] - bottomColdAirLeftSide[index])) *
          100,
          1
        );
      }
    });
    //Themal Efficiency% right data
    const themalEfficiencyRight = topPreheatedAirRightSide.map((num, index) => {
      if (
        num === 0 &&
        bottomColdAirRightSide[index] === 0 &&
        topHotFumesRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          ((num - bottomColdAirRightSide[index]) /
            (topHotFumesRightSide[index] - bottomColdAirRightSide[index])) *
          100,
          1
        );
      }
    });
    return {
      airFumesTempDetails: {
        bottomColdAirLeftAverage: bottomColdAirLeftAverage,
        bottomColdAirRightAverage: bottomColdAirRightAverage,
        topPreheatedAirLeftAverage: topPreheatedAirLeftAverage,
        topPreheatedAirRightAverage: topPreheatedAirRightAverage,
        bottomColdFumesLeftAverage: bottomColdFumesLeftAverage,
        bottomColdFumesRightAverage: bottomColdFumesRightAverage,
        topHotFumesLeftAverage: topHotFumesLeftAverage,
        topHotFumesRightAverage: topHotFumesRightAverage,
        themalEffiTotalAvgLeft: themalEffiTotalAvgLeft,
        themalEffiTotalAvgRight: themalEffiTotalAvgRight,
        themalEfficiencyLeft: themalEfficiencyLeft,
        themalEfficiencyRight: themalEfficiencyRight,
      },
    };
  },
});

//Fumes Gas Composition
export const fumesGasCompLeftData = selector({
  key: "fumesGasCompLeftData",
  get: ({ get }) => {
    const tmpConcentrationLeftData = get(fumesComposition);
    const custDet = useRecoilValue(customerInfoData);
    const o2ConcentrationLeft =
      custDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2,
          tmpConcentrationLeftData.compositionEFF.o2
        )
        : commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2,
          tmpConcentrationLeftData.compositionEFF.o2
        );
    const co2ConcentrationLeft =
      custDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.co2,
          tmpConcentrationLeftData.compositionEFF.co2
        )
        : commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.co2,
          tmpConcentrationLeftData.compositionEFF.co2
        );
    const h2oConcentrationLeft =
      custDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.h2o,
          tmpConcentrationLeftData.compositionEFF.h2o
        )
        : commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.h2o,
          tmpConcentrationLeftData.compositionEFF.h2o
        );
    const o2BottomConLeft =
      custDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2Bottom,
          tmpConcentrationLeftData.compositionEFF.o2Bottom
        )
        : commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2Bottom,
          tmpConcentrationLeftData.compositionEFF.o2Bottom
        );
    const o2TopConLeft =
      custDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2Top,
          tmpConcentrationLeftData.compositionEFF.o2Top
        )
        : commonFunction(
          "left",
          tmpConcentrationLeftData.compositionCFF.o2Top,
          tmpConcentrationLeftData.compositionEFF.o2Top
        );
    const o2ConcentrationLeftSide = [];
    o2ConcentrationLeft.map((data) => {
      if (data) {
        o2ConcentrationLeftSide.push(Number(data));
      }
    });
    const co2ConcentrationLeftSide = [];
    co2ConcentrationLeft.map((data) => {
      if (data) {
        co2ConcentrationLeftSide.push(Number(data));
      }
    });
    const h2oConcentrationLeftSide = [];
    h2oConcentrationLeft.map((data) => {
      if (data) {
        h2oConcentrationLeftSide.push(Number(data));
      }
    });
    const o2BottomConLeftSide = [];
    o2BottomConLeft.map((data) => {
      if (data) {
        o2BottomConLeftSide.push(Number(data));
      }
    });
    const o2TopConLeftSide = [];
    o2TopConLeft.map((data) => {
      if (data) {
        o2TopConLeftSide.push(Number(data));
      }
    });

    let nonZeroValueLeft = [];
    nonZeroValueLeft = o2BottomConLeftSide
      .map(Number)   // Convert string values to numbers
      .filter(val => val !== 0 && val !== '');
    const totalO2BottomConLeftSide = nonZeroValueLeft.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const avgO2BottomConLeftSide = functionForNullCheck("roundup",
      totalO2BottomConLeftSide, nonZeroValueLeft,
      2
    );

    nonZeroValueLeft = o2TopConLeftSide
      .map(Number)   // Convert string values to numbers
      .filter(val => val !== 0 && val !== '');
    const totalO2TopConLeftSide = nonZeroValueLeft.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const avgO2TopConLeftSide = functionForNullCheck("roundup",
      totalO2TopConLeftSide, nonZeroValueLeft,
      2
    );

    const sumN2ConcentrationLeft = co2ConcentrationLeftSide.map(
      (num, index) => {
        if (
          num === 0 &&
          h2oConcentrationLeftSide[index] === 0 &&
          o2ConcentrationLeftSide[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            100 -
            num -
            h2oConcentrationLeftSide[index] -
            o2ConcentrationLeftSide[index],
            2
          );
        }
      }
    );
    const totalPercentageVolLeft = co2ConcentrationLeftSide.map(
      (num, index) => {
        if (
          num === 0 &&
          sumN2ConcentrationLeft[index] === 0 &&
          h2oConcentrationLeftSide[index] === 0 &&
          o2ConcentrationLeftSide[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            num +
            sumN2ConcentrationLeft[index] +
            h2oConcentrationLeftSide[index] +
            o2ConcentrationLeftSide[index],
            2
          );
        }
      }
    );
    const calFumesCompRLeft = co2ConcentrationLeftSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcentrationLeft[index] === 0 &&
        h2oConcentrationLeftSide[index] === 0 &&
        o2ConcentrationLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcentrationLeft[index] * 28 +
            h2oConcentrationLeftSide[index] * 18 +
            o2ConcentrationLeftSide[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });
    const calFumesCompMLeft = co2ConcentrationLeftSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcentrationLeft[index] === 0 &&
        h2oConcentrationLeftSide[index] === 0 &&
        o2ConcentrationLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcentrationLeft[index] * 28 +
            h2oConcentrationLeftSide[index] * 18 +
            o2ConcentrationLeftSide[index] * 32) /
          100,
          2
        );
      }
    });
    const totalCO2PercentageMassLeft = co2ConcentrationLeftSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 44) / calFumesCompMLeft[index]) * 100,
            1
          );
        }
      }
    );
    const totalH2OPercentageMassLeft = h2oConcentrationLeftSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 18) / calFumesCompMLeft[index]) * 100,
            1
          );
        }
      }
    );
    const totalN2PercentageMassLeft = sumN2ConcentrationLeft.map(
      (num, index) => {
        if (num === 0 && calFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 28) / calFumesCompMLeft[index]) * 100,
            1
          );
        }
      }
    );
    const totalO2PercentageMassLeft = o2ConcentrationLeftSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 32) / calFumesCompMLeft[index]) * 100,
            1
          );
        }
      }
    );
    return {
      concentrationLeftDetails: {
        avgO2BottomConLeftSide: avgO2BottomConLeftSide,
        avgO2TopConLeftSide: avgO2TopConLeftSide,
        sumN2ConcentrationLeft: sumN2ConcentrationLeft,
        totalPercentageVolLeft: totalPercentageVolLeft,
        calFumesCompRLeft: calFumesCompRLeft,
        calFumesCompMLeft: calFumesCompMLeft,
        totalCO2PercentageMassLeft: totalCO2PercentageMassLeft,
        totalH2OPercentageMassLeft: totalH2OPercentageMassLeft,
        totalN2PercentageMassLeft: totalN2PercentageMassLeft,
        totalO2PercentageMassLeft: totalO2PercentageMassLeft,
      },
    };
  },
});

export const fumesGasCompRightData = selector({
  key: "fumesGasCompRightData",
  get: ({ get }) => {
    const tmpConcentrationRightData = get(fumesComposition);
    const custDetails = useRecoilValue(customerInfoData);
    const o2ConcentrationRight =
      custDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2,
          tmpConcentrationRightData.compositionEFF.o2
        )
        : commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2,
          tmpConcentrationRightData.compositionEFF.o2
        );
    const co2ConcentrationRight =
      custDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.co2,
          tmpConcentrationRightData.compositionEFF.co2
        )
        : commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.co2,
          tmpConcentrationRightData.compositionEFF.co2
        );
    const h2oConcentrationRight =
      custDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.h2o,
          tmpConcentrationRightData.compositionEFF.h2o
        )
        : commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.h2o,
          tmpConcentrationRightData.compositionEFF.h2o
        );
    const o2BottomConRight =
      custDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2Bottom,
          tmpConcentrationRightData.compositionEFF.o2Bottom
        )
        : commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2Bottom,
          tmpConcentrationRightData.compositionEFF.o2Bottom
        );
    const o2TopConRight =
      custDetails.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2Top,
          tmpConcentrationRightData.compositionEFF.o2Top
        )
        : commonFunction(
          "right",
          tmpConcentrationRightData.compositionCFF.o2Top,
          tmpConcentrationRightData.compositionEFF.o2Top
        );
    const o2ConcentrationRightSide = [];
    o2ConcentrationRight.map((data) => {
      if (data) {
        o2ConcentrationRightSide.push(Number(data));
      }
    });
    const co2ConcentrationRightSide = [];
    co2ConcentrationRight.map((data) => {
      if (data) {
        co2ConcentrationRightSide.push(Number(data));
      }
    });
    const h2oConcentrationRightSide = [];
    h2oConcentrationRight.map((data) => {
      if (data) {
        h2oConcentrationRightSide.push(Number(data));
      }
    });
    const o2BottomConRightSide = [];
    o2BottomConRight.map((data) => {
      if (data) {
        o2BottomConRightSide.push(Number(data));
      }
    });
    const o2TopConRightSide = [];
    o2TopConRight.map((data) => {
      if (data) {
        o2TopConRightSide.push(Number(data));
      }
    });
    let nonZeroValueRight = [];
    nonZeroValueRight = o2BottomConRightSide
      .map(Number)   // Convert string values to numbers
      .filter(val => val !== 0 && val !== '');
    const totalO2BottomConRightSide = nonZeroValueRight.reduce(
      (acc, cur) => roundUp(acc + cur, 2),
      0
    );
    const avgO2BottomConRightSide = functionForNullCheck("roundup",
      totalO2BottomConRightSide, nonZeroValueRight,
      2
    );

    nonZeroValueRight = o2TopConRightSide
      .map(Number)   // Convert string values to numbers
      .filter(val => val !== 0 && val !== '');
    const totalO2TopConRightSide = nonZeroValueRight.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const avgO2TopConRightSide = functionForNullCheck("roundup",
      totalO2TopConRightSide, nonZeroValueRight,
      2
    );

    const sumN2ConcentrationRight = co2ConcentrationRightSide.map(
      (num, index) => {
        if (
          num === 0 &&
          h2oConcentrationRightSide[index] === 0 &&
          o2ConcentrationRightSide[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            100 -
            num -
            h2oConcentrationRightSide[index] -
            o2ConcentrationRightSide[index],
            2
          );
        }
      }
    );
    const totalPercentageVolRight = co2ConcentrationRightSide.map(
      (num, index) => {
        if (
          num === 0 &&
          sumN2ConcentrationRight[index] === 0 &&
          h2oConcentrationRightSide[index] === 0 &&
          o2ConcentrationRightSide[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            num +
            sumN2ConcentrationRight[index] +
            h2oConcentrationRightSide[index] +
            o2ConcentrationRightSide[index],
            2
          );
        }
      }
    );
    const calFumesCompRRight = co2ConcentrationRightSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcentrationRight[index] === 0 &&
        h2oConcentrationRightSide[index] === 0 &&
        o2ConcentrationRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcentrationRight[index] * 28 +
            h2oConcentrationRightSide[index] * 18 +
            o2ConcentrationRightSide[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });
    const calFumesCompMRight = co2ConcentrationRightSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcentrationRight[index] === 0 &&
        h2oConcentrationRightSide[index] === 0 &&
        o2ConcentrationRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcentrationRight[index] * 28 +
            h2oConcentrationRightSide[index] * 18 +
            o2ConcentrationRightSide[index] * 32) /
          100,
          2
        );
      }
    });
    const totalCO2PercentageMassRight = co2ConcentrationRightSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 44) / calFumesCompMRight[index]) * 100,
            1
          );
        }
      }
    );
    const totalH2OPercentageMassRight = h2oConcentrationRightSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 18) / calFumesCompMRight[index]) * 100,
            1
          );
        }
      }
    );
    const totalN2PercentageMassRight = sumN2ConcentrationRight.map(
      (num, index) => {
        if (num === 0 && calFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 28) / calFumesCompMRight[index]) * 100,
            1
          );
        }
      }
    );
    const totalO2PercentageMassRight = o2ConcentrationRightSide.map(
      (num, index) => {
        if (num === 0 && calFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 32) / calFumesCompMRight[index]) * 100,
            1
          );
        }
      }
    );
    return {
      concentrationRightDetails: {
        avgO2BottomConRightSide: avgO2BottomConRightSide,
        avgO2TopConRightSide: avgO2TopConRightSide,
        sumN2ConcentrationRight: sumN2ConcentrationRight,
        totalPercentageVolRight: totalPercentageVolRight,
        calFumesCompRRight: calFumesCompRRight,
        calFumesCompMRight: calFumesCompMRight,
        totalCO2PercentageMassRight: totalCO2PercentageMassRight,
        totalH2OPercentageMassRight: totalH2OPercentageMassRight,
        totalN2PercentageMassRight: totalN2PercentageMassRight,
        totalO2PercentageMassRight: totalO2PercentageMassRight,
      },
    };
  },
});

//Dusts Analysis
export const dustsAnalysisData = selector({
  key: "dustsAnalysisData",
  get: ({ get }) => {
    //CFF
    const tmpDustsAnalysis = get(dustsAnalysis);
    const dustsAnalysisSIO2 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.carryoversCFF.sio2,
      tmpDustsAnalysis.carryoversEFF.sio2
    );
    const dustsAnalysisCAO = commonFunction(
      "singleValue",
      tmpDustsAnalysis.carryoversCFF.cao,
      tmpDustsAnalysis.carryoversEFF.cao
    );
    const dustsAnalysisMGO = commonFunction(
      "singleValue",
      tmpDustsAnalysis.carryoversCFF.mgo,
      tmpDustsAnalysis.carryoversEFF.mgo
    );
    const dustsAnalysisAL2O3 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.carryoversCFF.al2o3,
      tmpDustsAnalysis.carryoversEFF.al2o3
    );
    const dustsCarryOversSIO2 = [];
    dustsAnalysisSIO2.map((data) => {
      if (data) {
        dustsCarryOversSIO2.push(Number(data));
      }
    });
    const dustsCarryOversCAO = [];
    dustsAnalysisCAO.map((data) => {
      if (data) {
        dustsCarryOversCAO.push(Number(data));
      }
    });
    const dustsCarryOversMGO = [];
    dustsAnalysisMGO.map((data) => {
      if (data) {
        dustsCarryOversMGO.push(Number(data));
      }
    });
    const dustsCarryOversAL2O3 = [];
    dustsAnalysisAL2O3.map((data) => {
      if (data) {
        dustsCarryOversAL2O3.push(Number(data));
      }
    });
    const dustsAnalysisNA2O = commonFunction(
      "singleValue",
      tmpDustsAnalysis.alkalineCompoundsCFF.na2o,
      tmpDustsAnalysis.alkalineCompoundsEFF.na2o
    );
    const dustsAnalysisK2O = commonFunction(
      "singleValue",
      tmpDustsAnalysis.alkalineCompoundsCFF.k2o,
      tmpDustsAnalysis.alkalineCompoundsEFF.k2o
    );
    const dustsAnalysisSO4 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.alkalineCompoundsCFF.so4,
      tmpDustsAnalysis.alkalineCompoundsEFF.so4
    );
    const dustsAlkalineNA2O = [];
    dustsAnalysisNA2O.map((data) => {
      if (data) {
        dustsAlkalineNA2O.push(Number(data));
      }
    });
    const dustsAlkalineK2O = [];
    dustsAnalysisK2O.map((data) => {
      if (data) {
        dustsAlkalineK2O.push(Number(data));
      }
    });
    const dustsAlkalineSO4 = [];
    dustsAnalysisSO4.map((data) => {
      if (data) {
        dustsAlkalineSO4.push(Number(data));
      }
    });
    const dustsAnalysisP2O5 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.p2o5,
      tmpDustsAnalysis.othersEFF.p2o5
    );
    const dustsAnalysisCR2O3 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.cr2o3,
      tmpDustsAnalysis.othersEFF.cr2o3
    );
    const dustsAnalysisFE2O3 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.fe2o3,
      tmpDustsAnalysis.othersEFF.fe2o3
    );
    const dustsAnalysisPBO = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.pbo,
      tmpDustsAnalysis.othersEFF.pbo
    );
    const dustsAnalysisV2O5 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.v2o5,
      tmpDustsAnalysis.othersEFF.v2o5
    );
    const dustsAnalysisCL = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.cl,
      tmpDustsAnalysis.othersEFF.cl
    );
    const dustsAnalysisNO3 = commonFunction(
      "singleValue",
      tmpDustsAnalysis.othersCFF.no3,
      tmpDustsAnalysis.othersEFF.no3
    );
    const dustsOthersP2O5 = [];
    dustsAnalysisP2O5.map((data) => {
      if (data) {
        dustsOthersP2O5.push(Number(data));
      }
    });
    const dustsOthersCR2O3 = [];
    dustsAnalysisCR2O3.map((data) => {
      if (data) {
        dustsOthersCR2O3.push(Number(data));
      }
    });
    const dustsOthersFE2O3 = [];
    dustsAnalysisFE2O3.map((data) => {
      if (data) {
        dustsOthersFE2O3.push(Number(data));
      }
    });
    const dustsOthersPBO = [];
    dustsAnalysisPBO.map((data) => {
      if (data) {
        dustsOthersPBO.push(Number(data));
      }
    });
    const dustsOthersV2O5 = [];
    dustsAnalysisV2O5.map((data) => {
      if (data) {
        dustsOthersV2O5.push(Number(data));
      }
    });
    const dustsOthersCL = [];
    dustsAnalysisCL.map((data) => {
      if (data) {
        dustsOthersCL.push(Number(data));
      }
    });
    const dustsOthersNO3 = [];
    dustsAnalysisNO3.map((data) => {
      if (data) {
        dustsOthersNO3.push(Number(data));
      }
    });
    const dustsAnalysisArray = [
      dustsCarryOversSIO2,
      dustsCarryOversCAO,
      dustsCarryOversMGO,
      dustsCarryOversAL2O3,
      dustsAlkalineNA2O,
      dustsAlkalineK2O,
      dustsAlkalineSO4,
      dustsOthersP2O5,
      dustsOthersCR2O3,
      dustsOthersFE2O3,
      dustsOthersPBO,
      dustsOthersV2O5,
      dustsOthersCL,
      dustsOthersNO3,
    ];
    const sumDustsAnalysisArray = dustsAnalysisArray.reduce((acc, curr) => {
      curr.forEach((num, index) => {
        acc[index] = roundUp((acc[index] || 0) + num, 2);
      });
      return acc;
    }, []);
    return {
      dustsAnalysisDetails: {
        dustsCarryOversSIO2: dustsCarryOversSIO2,
        dustsCarryOversCAO: dustsCarryOversCAO,
        dustsCarryOversMGO: dustsCarryOversMGO,
        dustsCarryOversAL2O3: dustsCarryOversAL2O3,
        dustsAlkalineNA2O: dustsAlkalineNA2O,
        dustsAlkalineK2O: dustsAlkalineK2O,
        dustsAlkalineSO4: dustsAlkalineSO4,
        dustsOthersP2O5: dustsOthersP2O5,
        dustsOthersCR2O3: dustsOthersCR2O3,
        dustsOthersFE2O3: dustsOthersFE2O3,
        dustsOthersPBO: dustsOthersPBO,
        dustsOthersV2O5: dustsOthersV2O5,
        dustsOthersCL: dustsOthersCL,
        dustsOthersNO3: dustsOthersNO3,
        sumDustsAnalysisArray: sumDustsAnalysisArray,
      },
    };
  },
});

// Excel Data Calcualtions
export const calcExcelData = selector({
  key: "calcExcelData",
  get: ({ get }) => {
    const tempGas = get(fuelInfo);
    const customerDet = useRecoilValue(customerInfoData);

    //Density vs Air
    const gazPlace = [
      "h2", "co", "ch4", "c2h4", "c2h6", "c3h6", "c3h8", "c4h8", "c4h10", "c5h12", "co2", "n2", "o2",
    ];
    const gasAnalysisVol = gazPlace !== null && gazPlace.length > 0
      ? gazPlace
        .map((key) => tempGas.gaz.volPercentage[key])
        .filter(Boolean)
        .join(", ")
      : "";
    const gasVol = gasAnalysisVol.split(", ");
    const gazDensity = tempGas.gaz.density;
    const arrGazDensity = Object.values(gazDensity);
    const totalDensity = gasVol.reduce((acc, val, index) => {
      const density = arrGazDensity[index];
      return roundUp(acc + (val * density) / 100, 2);
    }, 0);

    //Air/combustible ratio
    const gazAirCombustion = tempGas.gaz.airCombustion;
    const arrGazAirCombustion = Object.values(gazAirCombustion);
    const totalAirCombustible = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazAirCombustion[index]) / 100, 2);
    }, 0);
    const gazAirCombustionEFF = tempGas.gaz.airCombustionEFF;
    const arrGazAirCombustionEFF = Object.values(gazAirCombustionEFF);
    const totalAirCombustibleEFF = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazAirCombustionEFF[index]) / 100, 2);
    }, 0);
    console.log(totalAirCombustibleEFF, "totalAirCombustibleEFF")

    //Combustion Fumes CO2
    const gazCombustionFumesCO2 = tempGas.gaz.combustionFumes.co2;
    const arrGazCombustionFumesCO2 = Object.values(gazCombustionFumesCO2);
    const totalCombustionFumesCO2 = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazCombustionFumesCO2[index]) / 100, 2);
    }, 0);

    //Combustion Fumes H2O
    const gazCombustionFumesH2O = tempGas.gaz.combustionFumes.h2o;
    const arrGazCombustionFumesH2O = Object.values(gazCombustionFumesH2O);
    const totalCombustionFumesH2O = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazCombustionFumesH2O[index]) / 100, 2);
    }, 0);

    //Combustion Heat LHV
    const gazCombustionHeatLHV = tempGas.gaz.combustionHeat.lhv;
    const arrGazCombustionHeatLHV = Object.values(gazCombustionHeatLHV);
    const totalCombustionHeatLHV = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazCombustionHeatLHV[index]) / 100, 2);
    }, 0);

    //Combustion Heat HHV
    const gazCombustionHeatHHV = tempGas.gaz.combustionHeat.hhv;
    const arrGazCombustionHeatHHV = Object.values(gazCombustionHeatHHV);
    const totalCombustionHeatHHV = gasVol.reduce((acc, val, index) => {
      return roundUp(acc + (val * arrGazCombustionHeatHHV[index]) / 100, 2);
    }, 0);

    //Smole Index Dry
    const gasDataNitrogen = tempGas.gaz.volPercentage.n2;
    const smoleIndexDry = [
      `0.79 * totalAirCombustible`,
      `gasDataNitrogen / 100`,
      `totalCombustionFumesCO2`,
    ];

    const sumValue = smoleIndexDry.reduce((accumulator, currentValue) => {
      let tempValue = 0;
      if (currentValue.includes("totalAirCombustible")) {
        const factor = parseFloat(
          currentValue.substring(0, currentValue.indexOf("*"))
        );
        tempValue = factor * totalAirCombustible;
      } else if (currentValue.includes("0.79")) {
        tempValue = parseFloat(currentValue) * totalAirCombustible;
      } else if (currentValue.includes("gasDataNitrogen")) {
        tempValue = gasDataNitrogen / 100;
      } else if (currentValue.includes("totalCombustionFumesCO2")) {
        tempValue = totalCombustionFumesCO2;
      }
      return accumulator + tempValue;
    }, 0);
    const sumGazSmokeIndexDry = roundUp(sumValue, 2);

    //Smole Index Wet
    const sumGazSmokeIndexWet = [
      sumGazSmokeIndexDry,
      totalCombustionFumesH2O,
    ].reduce((accumulator, currentValue) => accumulator + currentValue);

    //Fuel Air Moles of Oxygen
    const tempFuel = get(fuelInfo);
    const fuelOrder1 = [
      "carbon", "hydrogen", "oxygen", "nitrogen", "sulphur", "humidity",
    ];
    const fuelAnalysisMass1 = fuelOrder1 !== null && fuelOrder1.length > 0
      ? fuelOrder1
        .map((key) => tempFuel.oil.analysisPercMass[key])
        .filter(Boolean)
        .join(", ")
      : "";
    const fuelMassArray1 = fuelAnalysisMass1.split(", ");
    const fuelCarbonMoles1 = roundUp((fuelMassArray1[0] * 10) / 12, 2);
    const fuelHydrogenMoles1 = roundUp((fuelMassArray1[1] * 10) / 2, 2);
    const fuelOxygenMoles1 = roundUp((fuelMassArray1[2] * 10) / 32, 2);
    const fuelNitrogenMoles1 = roundUp((fuelMassArray1[3] * 10) / 28, 2);
    const fuelSulphurMoles1 = roundUp((fuelMassArray1[4] * 10) / 32, 2);
    const fuelHumidityMoles1 = roundUp((fuelMassArray1[5] * 10) / 18, 2);
    const fuelAirMolesOxygen = [
      fuelCarbonMoles1,
      fuelHydrogenMoles1 / 2,
      fuelOxygenMoles1,
      fuelSulphurMoles1,
    ].reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    });

    const sumFuelAirMolesOxygen = roundUp(fuelAirMolesOxygen, 3);

    //Fuel Air Moles of Nitrogen
    const sumFuelAirMolesNitrogen = roundUp(
      (79 * sumFuelAirMolesOxygen) / 21,
      3
    );

    //Sum Fuel Air Moles
    const sumFuelAirMoles = roundUp(
      sumFuelAirMolesOxygen + sumFuelAirMolesNitrogen,
      2
    );

    // Air/combustible ratio
    // Combustion Fumes
    const airPouvoir = roundUp(0.0224 * sumFuelAirMoles, 2);
    const combustionFumesCO2 = roundUp(0.0224 * fuelCarbonMoles1, 2);

    const combustionFumesH2O = [fuelHydrogenMoles1, fuelHumidityMoles1].reduce(
      (total, current) => {
        return total + current;
      }
    );
    const sumCombustionFumesH2O = roundUp(combustionFumesH2O * 0.0224, 2);
    const combustionFumesN2 = [
      fuelNitrogenMoles1,
      sumFuelAirMolesNitrogen,
    ].reduce((total, current) => {
      return total + current;
    });
    const sumCombustionFumesN2 = roundUp(combustionFumesN2 * 0.0224, 2);

    const combustionFumesSO2 = roundUp(0.0224 * fuelSulphurMoles1, 2);

    // Smoke Index
    const fuelSmokeIndexDry = [
      combustionFumesCO2,
      combustionFumesSO2,
      sumCombustionFumesN2,
    ].reduce((total, current) => {
      return total + current;
    });

    const fuelSmokeIndexWet = [
      combustionFumesCO2,
      combustionFumesSO2,
      sumCombustionFumesN2,
      sumCombustionFumesH2O,
    ].reduce((total, current) => {
      return roundUp(total + current, 2);
    });

    // Combustion Heat
    const combustionHeatHHV =
      [
        fuelCarbonMoles1,
        fuelHydrogenMoles1,
        fuelSulphurMoles1,
        fuelOxygenMoles1,
      ].reduce((total, current, index) => {
        let value;
        switch (index) {
          case 0: // Carbon moles
            value = current * 102;
            break;
          case 1: // Hydrogen moles
            value = current * 54;
            break;
          case 2: // Sulphur moles
            value = 80 * current;
            break;
          case 3: // Oxygen moles
            value = -current;
            break;
          default:
            value = 0;
        }
        return total + value;
      }, 0) * 0.0011625;

    const sumCombustionHeatHHV = roundUp(combustionHeatHHV, 2);

    const combustionHeatLHV =
      [
        fuelCarbonMoles1,
        fuelHydrogenMoles1,
        fuelSulphurMoles1,
        fuelOxygenMoles1,
      ].reduce((accumulator, currentValue, index) => {
        let value;
        switch (index) {
          case 0:
            // Carbon moles
            value = currentValue * 102;
            break;
          case 1:
            // Hydrogen moles
            value = currentValue * (54 - 13);
            break;
          case 2:
            // Sulphur moles
            value = 80 * currentValue;
            break;
          case 3:
            // Oxygen moles
            value = -currentValue;
            break;
          default:
            value = 0;
        }
        return accumulator + value;
      }, 0) * 0.0011625;

    const sumCombustionHeatLHV = roundUp(combustionHeatLHV, 2);

    //Flows - Fuel - Gaz & others Excel Calculations
    //DFN (Neutral Fumes Flow)  //DFN Gaz
    const consumptionsGasLeft = commonFunction(
      "left",
      tempFuel.gaz.consumptionCFF,
      tempFuel.gaz.consumptionEFF
    );
    let consumpGasLeftSide2 = [];
    consumptionsGasLeft.map((data) => {
      if (data) {
        consumpGasLeftSide2.push(Number(data));
      }
    });
    let flowsDFNGazLeft = consumpGasLeftSide2.map((element) => {
      return roundUp(element * sumGazSmokeIndexWet, 1);
    });
    const consumptionsGasRight = commonFunction(
      "right",
      tempFuel.gaz.consumptionCFF,
      tempFuel.gaz.consumptionEFF
    );
    let consumpGasRightSide2 = [];
    consumptionsGasRight.map((data) => {
      if (data) {
        consumpGasRightSide2.push(Number(data));
      }
    });
    let flowsDFNGazRight = consumpGasRightSide2.map((element) => {
      return roundUp(element * sumGazSmokeIndexWet, 1);
    });
    const totalFlowsDFNGazLeft = flowsDFNGazLeft.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      },
      0
    );
    const totalFlowsDFNGazRight = flowsDFNGazRight.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      },
      0
    );

    //DFN Fuel
    const consumptionsFuelLeft = commonFunction(
      "left",
      tempFuel.oil.consumptionCFF,
      tempFuel.oil.consumptionEFF
    );
    let consumpFuelLeftSide2 = [];
    consumptionsFuelLeft.map((data) => {
      if (data) {
        consumpFuelLeftSide2.push(Number(data));
      }
    });
    const flowsDFNFuelLeft = consumpFuelLeftSide2.map((element) => {
      return roundUp(element * fuelSmokeIndexWet, 2);
    });
    const consumptionsFuelRight = commonFunction(
      "right",
      tempFuel.oil.consumptionCFF,
      tempFuel.oil.consumptionEFF
    );
    let consumpFuelRightSide2 = [];
    consumptionsFuelRight.map((data) => {
      if (data) {
        consumpFuelRightSide2.push(Number(data));
      }
    });
    const flowsDFNFuelRight = consumpFuelRightSide2.map((element) => {
      return roundUp(element * fuelSmokeIndexWet, 2);
    });
    const totalFlowsDFNFuelLeft = flowsDFNFuelLeft.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      },
      0
    );
    const totalFlowsDFNFuelRight = flowsDFNFuelRight.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      },
      0
    );

    //DFN Total
    const custoDetails = useRecoilValue(customerInfoData);
    if (custoDetails.furnaceType === "Cross Fired") {
      if (flowsDFNGazLeft.length === 0) {
        flowsDFNGazLeft = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (flowsDFNGazLeft.length === 0) {
        flowsDFNGazLeft = [0];
      }
    }
    console.log(flowsDFNGazLeft, "flowsDFNGazLeft11")
    const flowsDFNTotalLeft = flowsDFNGazLeft.map((value, index) => {
      let DFNTotalLeft = (typeof flowsDFNFuelLeft[index] !== 'undefined') ? flowsDFNFuelLeft[index] : 0;
      return roundUp(value + DFNTotalLeft, 2);
    });
    if (custoDetails.furnaceType === "Cross Fired") {
      if (flowsDFNGazRight.length === 0) {
        flowsDFNGazRight = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (flowsDFNGazRight.length === 0) {
        flowsDFNGazRight = [0];
      }
    }
    const flowsDFNTotalRight = flowsDFNGazRight.map((value, index) => {
      let DFNTotalRight = (typeof flowsDFNFuelRight[index] !== 'undefined') ? flowsDFNFuelRight[index] : 0;
      return roundUp(value + DFNTotalRight, 2);
    });
    const flowsTotalDFNTotalLeft = flowsDFNTotalLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsTotalDFNTotalRight = flowsDFNTotalRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //DAN (Neutral Air Flow)  //DAN Gaz
    let flowsDANGazLeft = consumpGasLeftSide2.map((element) => {
      return roundUp(element * totalAirCombustible, 1);
    });
    let flowsDANGazRight = consumpGasRightSide2.map((element) => {
      return roundUp(element * totalAirCombustible, 1);
    });
    const flowsDANTotalGazLeft = flowsDANGazLeft.reduce(
      (accumulator, currentValue) => {
        return roundUp(accumulator + currentValue, 2);
      },
      0
    );
    const flowsDANTotalGazRight = flowsDANGazRight.reduce(
      (accumulator, currentValue) => {
        return roundUp(accumulator + currentValue, 2);
      },
      0
    );

    //DAN Fuel
    const flowsDANFuelLeft = consumpFuelLeftSide2.map((element) => {
      return roundUp(element * airPouvoir, 2);
    });
    const flowsDANFuelRight = consumpFuelRightSide2.map((element) => {
      return roundUp(element * airPouvoir, 2);
    });
    const flowsDANTotalFuelLeft = flowsDANFuelLeft.reduce(
      (accumulator, currentValue) => {
        return roundUp(accumulator + currentValue, 2);
      },
      0
    );
    const flowsDANTotalFuelRight = flowsDANFuelRight.reduce(
      (accumulator, currentValue) => {
        return roundUp(accumulator + currentValue, 2);
      },
      0
    );

    //DAN Total
    if (custoDetails.furnaceType === "Cross Fired") {
      if (flowsDANGazLeft.length === 0) {
        flowsDANGazLeft = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (flowsDANGazLeft.length === 0) {
        flowsDANGazLeft = [0];
      }
    }
    const flowsDANTotalLeft = flowsDANGazLeft.map((value, index) => {
      let DANTotalLeft = (typeof flowsDANFuelLeft[index] !== 'undefined') ? flowsDANFuelLeft[index] : 0;
      return roundUp(value + DANTotalLeft, 2);
    });
    console.log(flowsDANTotalLeft, "flowsDANTotalLeft")
    if (custoDetails.furnaceType === "Cross Fired") {
      if (flowsDANGazRight.length === 0) {
        flowsDANGazRight = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (flowsDANGazRight.length === 0) {
        flowsDANGazRight = [0];
      }
    }
    const flowsDANTotalRight = flowsDANGazRight.map((value, index) => {
      let DANTotalRight = (typeof flowsDANFuelRight[index] !== 'undefined') ? flowsDANFuelRight[index] : 0;
      return roundUp(value + DANTotalRight, 2);
    });
    const flowsTotalDANTotalLeft = flowsDANTotalLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsTotalDANTotalRight = flowsDANTotalRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //DGC (Batch Gaz Flow)  //Dehydration kg/batch
    const tmpBatchLeftData = get(furnaceDetails);
    const batchPullRate = tmpBatchLeftData.batchData.pullRate;
    const batchH2O = tmpBatchLeftData.batchData.h2oPercBatch;
    const batchLossIgn = tmpBatchLeftData.batchData.ignitionLoss;

    const batchLeftDeh = commonFunction(
      "left",
      tmpBatchLeftData.batchData.distributionCFF,
      tmpBatchLeftData.batchData.distributionEFF
    );
    const batchLeftSideDeh = [];
    batchLeftDeh.map((data) => {
      if (data) {
        batchLeftSideDeh.push(Number(data));
      }
    });
    const flowsDGCDehydrationkGLeft = batchLeftSideDeh.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        roundUp(
          (((1000 + 1000 * (batchLossIgn / 100)) * batchH2O) / 100) *
          (currentValue / 100),
          2
        ),
      ],
      []
    );
    const flowsDGCDehydrationkGRight = batchLeftSideDeh.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        roundUp(
          (((1000 + 1000 * (batchLossIgn / 100)) * batchH2O) / 100) *
          (currentValue / 100),
          2
        ),
      ],
      []
    );
    const totalFlowsDGCDehydrationkGLeft = flowsDGCDehydrationkGLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const totalFlowsDGCDehydrationkGRight = flowsDGCDehydrationkGRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Dehydration Nm3/h
    const flowsDGCDehydrationNM3Left = flowsDGCDehydrationkGLeft.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        roundUp((((currentValue * 0.0224) / 0.018) * batchPullRate) / 24, 2),
      ],
      []
    );
    const flowsDGCDehydrationNM3Right = flowsDGCDehydrationkGRight.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        roundUp((((currentValue * 0.0224) / 0.018) * batchPullRate) / 24, 2),
      ],
      []
    );
    const totalFlowsDGCDehydrationNM3Left = flowsDGCDehydrationNM3Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const totalFlowsDGCDehydrationNM3Right = flowsDGCDehydrationNM3Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Decarbonation kg
    const batchCullet = tmpBatchLeftData.batchData.cullet;

    const flowsDGCDecarbonationKGLeft = flowsDGCDehydrationkGLeft.map(
      (value, index) => {
        const batchLeftSideValue = batchLeftSideDeh[index];
        return roundUp(
          (1000 * (batchLossIgn / 100) - value) *
          (1 - batchCullet / 100) *
          (batchLeftSideValue / 100),
          2
        );
      }
    );
    const flowsDGCDecarbonationKGRight = flowsDGCDehydrationkGRight.map(
      (value, index) => {
        const batchLeftSideValue = batchLeftSideDeh[index];
        return roundUp(
          (1000 * (batchLossIgn / 100) - value) *
          (1 - batchCullet / 100) *
          (batchLeftSideValue / 100),
          2
        );
      }
    );
    const totalFlowsDGCDecarbonationKGLeft = flowsDGCDecarbonationKGLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const totalFlowsDGCDecarbonationKGRight =
      flowsDGCDecarbonationKGRight.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );

    //Decarbonation Nm3/h
    const flowsDGCDecarbonationNM3Left = flowsDGCDecarbonationKGLeft.map(
      (value) => {
        return roundUp((((value * 0.0224) / 0.044) * batchPullRate) / 24, 2);
      }
    );
    const flowsDGCDecarbonationNM3Right = flowsDGCDecarbonationKGRight.map(
      (value) => {
        return roundUp((((value * 0.0224) / 0.044) * batchPullRate) / 24, 2);
      }
    );
    const totalFlowsDGCDecarbonationNM3Left =
      flowsDGCDecarbonationNM3Left.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );
    const totalFlowsDGCDecarbonationNM3Right =
      flowsDGCDecarbonationNM3Right.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );

    //DGC Total
    const flowsDGCTotalLeft = flowsDGCDehydrationNM3Left.map((value, index) =>
      roundUp(value + flowsDGCDecarbonationNM3Left[index], 2)
    );
    const flowsDGCTotalRight = flowsDGCDehydrationNM3Right.map((value, index) =>
      roundUp(value + flowsDGCDecarbonationNM3Right[index], 2)
    );
    const flowsTotalDGCTotalLeft = flowsDGCTotalLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsTotalDGCTotalRight = flowsDGCTotalRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Combustion Correlation  //Air/Energy ratio
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpGasLeftSide2.length === 0) {
        consumpGasLeftSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpGasLeftSide2.length === 0) {
        consumpGasLeftSide2 = [0];
      }
    }
    const flowsCombustionCorrAirLeft = consumpGasLeftSide2.map((gas, index) => {
      const fuel = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
      const calValueLeft = roundUp(
        (gas / (gas + fuel)) * totalAirCombustible +
        (fuel / (gas + fuel)) * airPouvoir,
        2
      );
      return (isNaN(calValueLeft === '') || calValueLeft === '') ? 0 : calValueLeft;
    });
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpGasRightSide2.length === 0) {
        consumpGasRightSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpGasRightSide2.length === 0) {
        consumpGasRightSide2 = [0];
      }
    }
    const flowsCombustionCorrAirRight = consumpGasRightSide2.map(
      (gas, index) => {
        const fuel = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
        const calValueRight = roundUp(
          (gas / (gas + fuel)) * totalAirCombustible +
          (fuel / (gas + fuel)) * airPouvoir,
          2
        );
        return (isNaN(calValueRight === '') || calValueRight === '') ? 0 : calValueRight;
      }
    );
    const filteredFlowsLeft = flowsCombustionCorrAirLeft.filter(value => value !== 0 && value !== '');
    let flowsCombCorrAirLeft = filteredFlowsLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    let flowsTotalCombustionCorrAirLeft =
      functionForNullCheck("calc", flowsCombCorrAirLeft, filteredFlowsLeft);
    const filteredFlowsRight = flowsCombustionCorrAirRight.filter(value => value !== 0 && value !== '');
    let flowsCombCorrAirRight = filteredFlowsRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    let flowsTotalCombustionCorrAirRight =
      functionForNullCheck("calc", flowsCombCorrAirRight, filteredFlowsRight);

    //Correlation
    const flowsCorrelationLeft = commonFunction(
      "left",
      tmpBatchLeftData.batchData.correlationCFF,
      tmpBatchLeftData.batchData.correlationEFF
    );
    const flowsCorrelationLeftSide = [];
    flowsCorrelationLeft.map((data) => {
      if (data) {
        flowsCorrelationLeftSide.push(Number(data));
      }
    });
    const nonZeroValLeft = flowsCorrelationLeftSide.filter(value => value !== 0 && value !== '');
    const flowsTotalAvgCorrLeft =
      nonZeroValLeft.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const flowsTotalAvgCorrelationLeft =
      functionForNullCheck("calc", flowsTotalAvgCorrLeft, nonZeroValLeft);
    const flowsCorrelationRight = commonFunction(
      "right",
      tmpBatchLeftData.batchData.correlationCFF,
      tmpBatchLeftData.batchData.correlationEFF
    );
    const flowsCorrelationRightSide = [];
    flowsCorrelationRight.map((data) => {
      if (data) {
        flowsCorrelationRightSide.push(Number(data));
      }
    });
    const nonZeroValRight = flowsCorrelationRightSide.filter(value => value !== 0 && value !== '');
    const flowsTotalAvgCorrRight =
      nonZeroValRight.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const flowsTotalAvgCorrelationRight =
      functionForNullCheck("calc", flowsTotalAvgCorrRight, nonZeroValRight);

    //Resulting Air Flows  //Dexa (Excess Air Flow)(Nm3/h)
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpGasLeftSide2.length === 0) {
        consumpGasLeftSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpGasLeftSide2.length === 0) {
        consumpGasLeftSide2 = [0];
      }
    }
    const flowsDexaLeft = consumpGasLeftSide2.map((num, index) => {
      let dexaLeft = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
      const sum = num + dexaLeft;
      return roundUp(
        sum * flowsCorrelationLeftSide[index] - flowsDANTotalLeft[index],
        2
      );
    });
    console.log(flowsDexaLeft, "flowsDexaLeft");
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpGasRightSide2.length === 0) {
        consumpGasRightSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpGasRightSide2.length === 0) {
        consumpGasRightSide2 = [0];
      }
    }
    const flowsDexaRight = consumpGasRightSide2.map((num, index) => {
      let dexaRight = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
      const sum = num + dexaRight;
      return roundUp(
        sum * flowsCorrelationRightSide[index] - flowsDANTotalRight[index],
        2
      );
    });
    const flowsCorrelationTotalLeft = flowsDexaLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    const flowsCorrelationTotalRight = flowsDexaRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //DAP (Parasitic Air Flow) (Nm3/h)
    const tempConcentrationData = get(fumesComposition);
    //Left
    const o2BottomLeft = commonFunction(
      "left",
      tempConcentrationData.compositionCFF.o2Bottom,
      tempConcentrationData.compositionEFF.o2Bottom
    );
    const o2BottomArrayLeft = [];
    o2BottomLeft.map((data) => {
      if (data) {
        o2BottomArrayLeft.push(Number(data));
      }
    });
    const o2TopLeft = commonFunction(
      "left",
      tempConcentrationData.compositionCFF.o2Top,
      tempConcentrationData.compositionEFF.o2Top
    );
    const o2TopArrayLeft = [];
    o2TopLeft.map((data) => {
      if (data) {
        o2TopArrayLeft.push(Number(data));
      }
    });
    console.log(flowsDFNTotalLeft, "flowsDFNTotalLeft");
    const o2BottomCalLeft = o2BottomArrayLeft.map(
      (val, i) => val / 100 - o2TopLeft[i]
    );
    console.log(o2BottomCalLeft, "o2BottomCalLeft");
    const sumArraysLeft = (...arrays) => {
      return arrays.reduce((acc, current) => {
        return acc.map((value, index) => roundUp(value + current[index], 2));
      });
    };
    const arraySumLeft = sumArraysLeft(
      flowsDFNTotalLeft,
      flowsDGCTotalLeft,
      flowsDexaLeft
    );
    const flowsDAPParasiticLeft = o2BottomArrayLeft.map(
      (val, i) =>
        roundUp(
          ((val / 100 - o2TopArrayLeft[i] / 100) / (0.21 - val / 100)) *
          arraySumLeft[i]
        ),
      2
    );

    //Right
    const o2BottomRight = commonFunction(
      "right",
      tempConcentrationData.compositionCFF.o2Bottom,
      tempConcentrationData.compositionEFF.o2Bottom
    );
    const o2BottomArrayRight = [];
    o2BottomRight.map((data) => {
      if (data) {
        o2BottomArrayRight.push(Number(data));
      }
    });
    const o2TopRight = commonFunction(
      "right",
      tempConcentrationData.compositionCFF.o2Top,
      tempConcentrationData.compositionEFF.o2Top
    );
    const o2TopArrayRight = [];
    o2TopRight.map((data) => {
      if (data) {
        o2TopArrayRight.push(Number(data));
      }
    });
    const o2BottomCalRight = o2BottomArrayRight.map(
      (val, i) => val / 100 - o2TopRight[i]
    );
    console.log(o2BottomCalRight, "o2BottomCalRight")
    const sumArraysRight = (...arrays) => {
      return arrays.reduce((acc, current) => {
        return acc.map((value, index) => roundUp(value + current[index], 2));
      });
    };
    const arraySumRight = sumArraysRight(
      flowsDFNTotalRight,
      flowsDGCTotalRight,
      flowsDexaRight
    );
    const flowsDAPParasiticRight = o2BottomArrayRight.map(
      (val, i) =>
        roundUp(
          ((val / 100 - o2TopArrayRight[i] / 100) / (0.21 - val / 100)) *
          arraySumRight[i]
        ),
      2
    );

    //Total Left
    const filteredArrayDAPLeft = flowsDAPParasiticLeft.filter(value => value !== 0 && value !== '');
    const flowsDAPParasiticTotLeft = filteredArrayDAPLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsDAPParasiticTotalLeft =
      functionForNullCheck("calc", flowsDAPParasiticTotLeft, filteredArrayDAPLeft);
    //Total Right
    const filteredArrayDAPRight = flowsDAPParasiticRight.filter(value => value !== 0 && value !== '');
    const flowsDAPParasiticTotRight = filteredArrayDAPRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsDAPParasiticTotalRight =
      functionForNullCheck("calc", flowsDAPParasiticTotRight, filteredArrayDAPRight);

    //DFT(Total Fumes Flow)  //DFN+DGC+DEXA (Nm3/h)
    const sumOfArraysLeft = (...arrays) => {
      return arrays.reduce((acc, current) => {
        return acc.map((value, index) => roundUp(value + current[index], 2));
      });
    };
    const flowsDFTDfnDgcDexaLeft = sumOfArraysLeft(
      flowsDFNTotalLeft,
      flowsDGCTotalLeft,
      flowsDexaLeft
    );

    const sumOfArraysRight = (...arrays) => {
      return arrays.reduce((acc, current) => {
        return acc.map((value, index) => roundUp(value + current[index], 2));
      });
    };
    const flowsDFTDfnDgcDexaRight = sumOfArraysRight(
      flowsDFNTotalRight,
      flowsDGCTotalRight,
      flowsDexaRight
    );

    const flowsTotalDFTDfnDgcDexaLeft = flowsDFTDfnDgcDexaLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    const flowsTotalDFTDfnDgcDexaRight = flowsDFTDfnDgcDexaRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Previous+DAP (Nm3/h)
    const flowsDFTPreviDAPLeft = flowsDAPParasiticLeft
      .map((val, i) => val + flowsDFTDfnDgcDexaLeft[i])
      .filter((val) => typeof val !== "undefined" && !isNaN(Number(val)));
    const flowsDFTPreviousDAPLeft = flowsDFTPreviDAPLeft.map(value => roundUp(value, 2));

    const flowsDFTPreviDAPRight = flowsDAPParasiticRight.map(
      (val, i) => val + flowsDFTDfnDgcDexaRight[i])
      .filter((val) => typeof val !== "undefined" && !isNaN(Number(val)));
    const flowsDFTPreviousDAPRight = flowsDFTPreviDAPRight.map(value => roundUp(value, 2));

    const flowsDFTPreviousDAPTotalLeft = flowsDFTPreviousDAPLeft.reduce(
      (acc, cur) => roundUp(acc + cur, 2),
      0
    );

    const flowsDFTPreviousDAPTotalRight = flowsDFTPreviousDAPRight.reduce(
      (acc, cur) => roundUp(acc + cur, 2),
      0
    );

    //DAT (Total Air Flow)  //DAN + DEXA (Nm3/h)
    const flowsDATDanDexaLeft = flowsDANTotalLeft.map(function (
      element,
      index
    ) {
      return roundUp(element + flowsDexaLeft[index], 2);
    });
    console.log(flowsDATDanDexaLeft, "flowsDATDanDexaLeft");

    const flowsDATDanDexaRight = flowsDANTotalRight.map(function (
      element,
      index
    ) {
      return roundUp(element + flowsDexaRight[index], 2);
    });

    let flowsTotalDATDanDexaLeft = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      flowsTotalDATDanDexaLeft = flowsDATDanDexaLeft.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );
    } else {
      let sumDATDanDexaLeft = flowsDATDanDexaLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumDATDanDexaRight = flowsDATDanDexaRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      flowsTotalDATDanDexaLeft = roundUp(
        (sumDATDanDexaLeft + sumDATDanDexaRight) / 2,
        2
      );
    }
    console.log(flowsTotalDATDanDexaLeft, "flowsTotalDATDanDexaLeft");

    let flowsTotalDATDanDexaRight = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      flowsTotalDATDanDexaRight = flowsDATDanDexaLeft.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );
    } else {
      let sumDATDanDexaLeft = flowsDATDanDexaLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumDATDanDexaRight = flowsDATDanDexaRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      flowsTotalDATDanDexaRight = roundUp(
        (sumDATDanDexaLeft + sumDATDanDexaRight) / 2,
        2
      );
    }

    //Measured at top CO2
    const co2ConLeft = commonFunction(
      "left",
      tempConcentrationData.compositionCFF.co2,
      tempConcentrationData.compositionEFF.co2
    );
    const co2ConArrayLeft = [];
    co2ConLeft.map((data) => {
      if (data) {
        co2ConArrayLeft.push(Number(data));
      }
    });
    console.log(co2ConArrayLeft, "co2ConArrayLeft");
    const h2oConLeft = commonFunction(
      "left",
      tempConcentrationData.compositionCFF.h2o,
      tempConcentrationData.compositionEFF.h2o
    );
    const h2oConArrayLeft = [];
    h2oConLeft.map((data) => {
      if (data) {
        h2oConArrayLeft.push(Number(data));
      }
    });
    console.log(h2oConArrayLeft, "h2oConArrayLeft");
    const flowsMeasuredTopCO2Left = co2ConLeft.map((val, index) => {
      if (customerDet.furnaceType === "Cross Fired") {
        return roundUp((val / (100 - h2oConLeft[index])) * 100, 2);
      } else {
        return roundUp((val / 100) * 100, 2);
      }
    });
    const co2ConRight = commonFunction(
      "right",
      tempConcentrationData.compositionCFF.co2,
      tempConcentrationData.compositionEFF.co2
    );
    const co2ConArrayRight = [];
    co2ConRight.map((data) => {
      if (data) {
        co2ConArrayRight.push(Number(data));
      }
    });
    console.log(co2ConArrayRight, "co2ConArrayRight");
    const h2oConRight = commonFunction(
      "right",
      tempConcentrationData.compositionCFF.h2o,
      tempConcentrationData.compositionEFF.h2o
    );
    const h2oConArrayRight = [];
    h2oConRight.map((data) => {
      if (data) {
        h2oConArrayRight.push(Number(data));
      }
    });
    console.log(h2oConArrayRight, "h2oConArrayRight");
    const flowsMeasuredTopCO2Right = co2ConRight.map((val, index) => {
      if (customerDet.furnaceType === "Cross Fired") {
        return roundUp((val / (100 - h2oConRight[index])) * 100, 2);
      } else {
        return roundUp((val / 100) * 100, 2);
      }
    });

    const nonZeroValuesLeft = flowsMeasuredTopCO2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalMeasuredTopCO2Left = nonZeroValuesLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgMeasuredTopCO2Left =
      functionForNullCheck("calc", flowsTotalMeasuredTopCO2Left, nonZeroValuesLeft);

    const nonZeroValuesRight = flowsMeasuredTopCO2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalMeasuredTopCO2Right = nonZeroValuesRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgMeasuredTopCO2Right =
      functionForNullCheck("calc", flowsTotalMeasuredTopCO2Right, nonZeroValuesRight);

    //Calculated Fumes  //O2
    const flowsCalFumesO2Left = flowsDexaLeft.map(
      (value, index) => {
        const o2Left = ((0.21 * value) / flowsDFTDfnDgcDexaLeft[index]) * 100;
        return isNaN(o2Left) ? 0 : roundUp(o2Left, 2);
      }
    );
    const flowsCalFumesO2Right = flowsDexaRight.map(
      (value, index) => {
        const o2Right = ((0.21 * value) / flowsDFTDfnDgcDexaRight[index]) * 100;
        return isNaN(o2Right) ? 0 : roundUp(o2Right, 2);
      }
    );

    const nonZeroO2Left = flowsCalFumesO2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesO2Left = nonZeroO2Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesO2Left = functionForNullCheck("roundup",
      flowsTotalCalFumesO2Left, nonZeroO2Left,
      2
    );

    const nonZeroO2Right = flowsCalFumesO2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesO2Right = nonZeroO2Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesO2Right = functionForNullCheck("roundup",
      flowsTotalCalFumesO2Right, nonZeroO2Right,
      2
    );

    //CO2
    const flowsCalculaFumesCO2Left = flowsDFTDfnDgcDexaLeft.map(
      (value, index) => {
        if (customerDet.furnaceType === "Cross Fired") {
          let consumpGasLefter = (typeof consumpGasLeftSide2[index] !== 'undefined') ? consumpGasLeftSide2[index] : 0;
          let consumpFuelLefter = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
          const gasCO2EmissionsLeft =
            consumpGasLefter * totalCombustionFumesCO2;
          const fuelCO2EmissionsLeft =
            consumpFuelLefter * combustionFumesCO2;
          const dgcCountLeft = 1;
          const flowsCalFumesCO2Left = roundUp(
            (((gasCO2EmissionsLeft + fuelCO2EmissionsLeft + dgcCountLeft) / value) * 100),
            2
          );
          return parseFloat(flowsCalFumesCO2Left);
        } else {
          let consumpGasLefter = (typeof consumpGasLeftSide2[index] !== 'undefined') ? consumpGasLeftSide2[index] : 0;
          let consumpFuelLefter = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
          const gasCO2EmissionsLeft =
            consumpGasLefter * totalCombustionFumesCO2;
          const fuelCO2EmissionsLeft =
            consumpFuelLefter * combustionFumesCO2;
          const dgcCountLeft = flowsDGCDecarbonationNM3Left[index];
          const flowsCalFumesCO2Left = roundUp(
            (((gasCO2EmissionsLeft + fuelCO2EmissionsLeft + dgcCountLeft) / value) * 100),
            2
          );
          return parseFloat(flowsCalFumesCO2Left);
        }
      }
    );
    const flowsCalculFumesCO2Left = flowsCalculaFumesCO2Left.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });
    console.log(flowsCalculFumesCO2Left, "flowsCalculFumesCO2Left")

    const flowsCalculaFumesCO2Right = flowsDFTDfnDgcDexaRight.map(
      (value, index) => {
        let consumpGasRighter = (typeof consumpGasRightSide2[index] !== 'undefined') ? consumpGasRightSide2[index] : 0;
        let consumpFuelRighter = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
        if (customerDet.furnaceType === "Cross Fired") {
          const gasCO2EmissionsRight =
            consumpGasRighter * totalCombustionFumesCO2;
          const fuelCO2EmissionsRight =
            consumpFuelRighter * combustionFumesCO2;
          const dgcCountRight = 1;
          const flowsCalFumesCO2Right = roundUp(
            (((gasCO2EmissionsRight + fuelCO2EmissionsRight + dgcCountRight) /
              value) *
              100),
            2
          );
          return parseFloat(flowsCalFumesCO2Right);
        } else {
          let consumpGasRighter = (typeof consumpGasRightSide2[index] !== 'undefined') ? consumpGasRightSide2[index] : 0;
          let consumpFuelRighter = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
          const gasCO2EmissionsRight =
            consumpGasRighter * totalCombustionFumesCO2;
          const fuelCO2EmissionsRight =
            consumpFuelRighter * combustionFumesCO2;
          const dgcCountRight = flowsDGCDecarbonationNM3Right[index];
          const flowsCalFumesCO2Right = roundUp(
            (((gasCO2EmissionsRight + fuelCO2EmissionsRight + dgcCountRight) /
              value) *
              100),
            2
          );
          return parseFloat(flowsCalFumesCO2Right);
        }
      }
    );
    const flowsCalculFumesCO2Right = flowsCalculaFumesCO2Right.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });

    const nonZeroCO2Left = flowsCalculFumesCO2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesCO2Left = nonZeroCO2Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesCO2Left = functionForNullCheck("roundup",
      flowsTotalCalFumesCO2Left, nonZeroCO2Left,
      2
    );

    const nonZeroCO2Right = flowsCalculFumesCO2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesCO2Right = nonZeroCO2Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesCO2Right = functionForNullCheck("roundup",
      flowsTotalCalFumesCO2Right, nonZeroCO2Right,
      2
    );

    //H2O
    const flowsCalculaFumesH2OLeft = flowsDFTDfnDgcDexaLeft.map(
      (value, index) => {
        if (customerDet.furnaceType === "Cross Fired") {
          let consumpGasLefters = (typeof consumpGasLeftSide2[index] !== 'undefined') ? consumpGasLeftSide2[index] : 0;
          let consumpFuelLefters = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
          const gasH2OEmissionsLeft =
            consumpGasLefters * totalCombustionFumesH2O;
          const fuelH2OEmissionsLeft =
            consumpFuelLefters * sumCombustionFumesH2O;
          const dgcH2OCountLeft = 1;
          const flowsCalFumesH2OLeft = roundUp((((gasH2OEmissionsLeft + fuelH2OEmissionsLeft + dgcH2OCountLeft) / value) * 100), 2);
          return parseFloat(flowsCalFumesH2OLeft);
        } else {
          let consumpGasLefters = (typeof consumpGasLeftSide2[index] !== 'undefined') ? consumpGasLeftSide2[index] : 0;
          let consumpFuelLefters = (typeof consumpFuelLeftSide2[index] !== 'undefined') ? consumpFuelLeftSide2[index] : 0;
          const gasH2OEmissionsLeft =
            consumpGasLefters * totalCombustionFumesH2O;
          const fuelH2OEmissionsLeft =
            consumpFuelLefters * sumCombustionFumesH2O;
          const dgcH2OCountLeft = flowsDGCDehydrationNM3Left[index];
          const flowsCalFumesH2OLeft = roundUp((((gasH2OEmissionsLeft + fuelH2OEmissionsLeft + dgcH2OCountLeft) / value) * 100), 2);
          return parseFloat(flowsCalFumesH2OLeft);
        }
      }
    );
    const flowsCalculFumesH2OLeft = flowsCalculaFumesH2OLeft.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });
    console.log(flowsCalculFumesH2OLeft, "flowsCalculFumesH2OLeft");

    const flowsCalculaFumesH2ORight = flowsDFTDfnDgcDexaRight.map(
      (value, index) => {
        if (customerDet.furnaceType === "Cross Fired") {
          let consumpGasRighters = (typeof consumpGasRightSide2[index] !== 'undefined') ? consumpGasRightSide2[index] : 0;
          let consumpFuelRighters = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
          const gasH2OEmissionsRight =
            consumpGasRighters * totalCombustionFumesH2O;
          const fuelH2OEmissionsRight =
            consumpFuelRighters * sumCombustionFumesH2O;
          const dgcH2OCountRight = 1;
          const flowsCalFumesH2ORight = roundUp(((gasH2OEmissionsRight + fuelH2OEmissionsRight + dgcH2OCountRight) / value) * 100, 2);
          return parseFloat(flowsCalFumesH2ORight);
        } else {
          let consumpGasRighters = (typeof consumpGasRightSide2[index] !== 'undefined') ? consumpGasRightSide2[index] : 0;
          let consumpFuelRighters = (typeof consumpFuelRightSide2[index] !== 'undefined') ? consumpFuelRightSide2[index] : 0;
          const gasH2OEmissionsRight =
            consumpGasRighters * totalCombustionFumesH2O;
          const fuelH2OEmissionsRight =
            consumpFuelRighters * sumCombustionFumesH2O;
          const dgcH2OCountRight = flowsDGCDehydrationNM3Right[index];
          const flowsCalFumesH2ORight = roundUp(((gasH2OEmissionsRight + fuelH2OEmissionsRight + dgcH2OCountRight) / value) * 100, 2);
          return parseFloat(flowsCalFumesH2ORight);
        }
      }
    );
    const flowsCalculFumesH2ORight = flowsCalculaFumesH2ORight.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });

    const nonZeroH2OLeft = flowsCalculFumesH2OLeft.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesH2OLeft = nonZeroH2OLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesH2OLeft = functionForNullCheck("roundup",
      flowsTotalCalFumesH2OLeft, nonZeroH2OLeft,
      2
    );

    const nonZeroH2ORight = flowsCalculFumesH2ORight.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesH2ORight = nonZeroH2ORight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesH2ORight = functionForNullCheck("roundup",
      flowsTotalCalFumesH2ORight, nonZeroH2ORight,
      2
    );

    //N2
    const flowsCalFumesN2Left = (
      flowsCalFumesO2Left,
      flowsCalculFumesCO2Left,
      flowsCalculFumesH2OLeft
    ) => {
      const combinedArrayLeft = flowsCalFumesO2Left.map((num, index) => {
        const comArrayLeft = roundUp(((1 - ((num / 100) + (flowsCalculFumesCO2Left[index] / 100) + (flowsCalculFumesH2OLeft[index] / 100))) * 100), 2);
        return comArrayLeft;
      });
      console.log(combinedArrayLeft, "combinedArrayLeft");
      return combinedArrayLeft;
    };
    const flowsCalCulaFumesN2Left = flowsCalFumesN2Left(
      flowsCalFumesO2Left,
      flowsCalculFumesCO2Left,
      flowsCalculFumesH2OLeft
    );
    const flowsCalCulFumesN2Left = flowsCalCulaFumesN2Left.filter(value => value !== null && !isNaN(value));
    console.log(flowsCalCulFumesN2Left, "flowsCalCulFumesN2Left");
    const flowsCalFumesN2Right = (
      flowsCalFumesO2Right,
      flowsCalculFumesCO2Right,
      flowsCalculFumesH2ORight
    ) => {
      const combinedArrayRight = flowsCalFumesO2Right.map((num, index) => {
        const comArrayRight = roundUp(((1 - ((num / 100) + (flowsCalculFumesCO2Right[index] / 100) + (flowsCalculFumesH2ORight[index] / 100))) * 100), 2);
        return comArrayRight;
      });
      return combinedArrayRight;
    };
    const flowsCalCulaFumesN2Right = flowsCalFumesN2Right(
      flowsCalFumesO2Right,
      flowsCalculFumesCO2Right,
      flowsCalculFumesH2ORight
    );
    const flowsCalCulFumesN2Right = flowsCalCulaFumesN2Right.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });

    const nonZeroN2Left = flowsCalCulFumesN2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesN2Left = nonZeroN2Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesN2Left = functionForNullCheck("roundup",
      flowsTotalCalFumesN2Left, nonZeroN2Left,
      2
    );

    const nonZeroN2Right = flowsCalCulFumesN2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesN2Right = nonZeroN2Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesN2Right = functionForNullCheck("roundup",
      flowsTotalCalFumesN2Right, nonZeroN2Right,
      2
    );

    //Verification
    const flowsCalVerifyLeft = (
      flowsCalFumesO2Left,
      flowsCalculFumesCO2Left,
      flowsCalculFumesH2OLeft,
      flowsCalCulFumesN2Left
    ) => {
      const combinedArrayLeft = flowsCalFumesO2Left.map(
        (num, index) =>
          num +
          flowsCalculFumesCO2Left[index] +
          flowsCalculFumesH2OLeft[index] +
          flowsCalCulFumesN2Left[index]
      );

      const sumVerifyValuesLeft = [];
      for (let i = 0; i < combinedArrayLeft.length; i++) {
        sumVerifyValuesLeft.push(combinedArrayLeft[i]);
      }

      return sumVerifyValuesLeft;
    };
    const flowsCalculaVerifyLeft = flowsCalVerifyLeft(
      flowsCalFumesO2Left,
      flowsCalculFumesCO2Left,
      flowsCalculFumesH2OLeft,
      flowsCalCulFumesN2Left
    );
    const flowsCalculVerifyLeft = flowsCalculaVerifyLeft.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });

    const flowsCalVerifyRight = (
      flowsCalFumesO2Right,
      flowsCalculFumesCO2Right,
      flowsCalculFumesH2ORight,
      flowsCalCulFumesN2Right
    ) => {
      const combinedArrayRight = flowsCalFumesO2Right.map(
        (num, index) =>
          num +
          flowsCalculFumesCO2Right[index] +
          flowsCalculFumesH2ORight[index] +
          flowsCalCulFumesN2Right[index]
      );

      const sumVerifyValuesRight = [];
      for (let i = 0; i < combinedArrayRight.length; i++) {
        sumVerifyValuesRight.push(combinedArrayRight[i]);
      }

      return sumVerifyValuesRight;
    };
    const flowsCalculaVerifyRight = flowsCalVerifyRight(
      flowsCalFumesO2Right,
      flowsCalculFumesCO2Right,
      flowsCalculFumesH2ORight,
      flowsCalCulFumesN2Right
    );
    const flowsCalculVerifyRight = flowsCalculaVerifyRight.map(value => {
      if (value === '' || isNaN(value)) {
        return 0;
      }
      return value;
    });

    const nonZeroVerifyLeft = flowsCalculVerifyLeft.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesVerifyLeft = nonZeroVerifyLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesVerifyLeft = functionForNullCheck("roundup",
      flowsTotalCalFumesVerifyLeft, nonZeroVerifyLeft,
      2
    );

    const nonZeroVerifyRight = flowsCalculVerifyRight.filter(value => value !== 0 && value !== '');
    const flowsTotalCalFumesVerifyRight = nonZeroVerifyRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalFumesVerifyRight = functionForNullCheck("roundup",
      flowsTotalCalFumesVerifyRight, nonZeroVerifyRight,
      2
    );

    //Calculated at top  //O2
    const flowsCalTopO2Left = flowsCalFumesO2Left.map((num, index) => {
      const o2CalLeft = roundUp(((num / 100) / (1 - (flowsCalculFumesH2OLeft[index] / 100))) * 100, 2)
      return (isNaN(o2CalLeft) || o2CalLeft === '') ? 0 : o2CalLeft;
    }
    );
    const flowsCalTopO2Right = flowsCalFumesO2Right.map((num, index) => {
      const o2CalRight = roundUp(((num / 100) / (1 - (flowsCalculFumesH2ORight[index] / 100))) * 100, 2)
      return (isNaN(o2CalRight) || o2CalRight === '') ? 0 : o2CalRight;
    }
    );

    const nonZeroTopO2Left = flowsCalTopO2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalCalTopO2Left = nonZeroTopO2Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalTopO2Left = functionForNullCheck("roundup",
      flowsTotalCalTopO2Left, nonZeroTopO2Left,
      2
    );

    const nonZeroTopO2Right = flowsCalTopO2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalCalTopO2Right = nonZeroTopO2Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalTopO2Right = functionForNullCheck("roundup",
      flowsTotalCalTopO2Right, nonZeroTopO2Right,
      2
    );

    //CO2
    const flowsCalTopCO2Left = flowsCalculFumesCO2Left.map((num, index) => {
      const co2Left = roundUp(((num / 100) / (1 - (flowsCalculFumesH2OLeft[index] / 100))) * 100, 2)
      return (isNaN(co2Left === '') || co2Left === '') ? 0 : co2Left;
    }
    );
    const flowsCalTopCO2Right = flowsCalculFumesCO2Right.map((num, index) => {
      const co2Right = roundUp(((num / 100) / (1 - (flowsCalculFumesH2ORight[index] / 100))) * 100, 2)
      return (isNaN(co2Right === '') || co2Right === '') ? 0 : co2Right;
    }
    );

    const nonZeroTopCO2Left = flowsCalTopCO2Left.filter(value => value !== 0 && value !== '');
    const flowsTotalCalTopCO2Left = nonZeroTopCO2Left.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalTopCO2Left = functionForNullCheck("roundup",
      flowsTotalCalTopCO2Left, nonZeroTopCO2Left,
      2
    );

    const nonZeroTopCO2Right = flowsCalTopCO2Right.filter(value => value !== 0 && value !== '');
    const flowsTotalCalTopCO2Right = nonZeroTopCO2Right.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const flowsAvgCalTopCO2Right = functionForNullCheck("roundup",
      flowsTotalCalTopCO2Right, nonZeroTopCO2Right,
      2
    );

    //Efficiencies  //DFT(Nm3/h)
    const effDFTNm3Left =
      flowsDFTPreviousDAPLeft.length === ""
        ? flowsDFTDfnDgcDexaLeft.map((item) => item)
        : flowsDFTPreviousDAPLeft.map((item) => item);
    const effDFTNm3Right =
      flowsDFTPreviousDAPRight !== null && flowsDFTPreviousDAPRight.length === 0
        ? flowsDFTDfnDgcDexaRight.map((item) => item)
        : flowsDFTPreviousDAPRight.map((item) => item);

    let effTotalDFTNm3Left = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalDFTNm3Left = effDFTNm3Left.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );
    } else {
      let sumeffDFTNm3Left = effDFTNm3Left.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffDFTNm3Right = effDFTNm3Right.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalDFTNm3Left = roundUp(
        (sumeffDFTNm3Left + sumeffDFTNm3Right) / 2,
        2
      );
    }
    console.log(effTotalDFTNm3Left, "effTotalDFTNm3Left");

    let effTotalDFTNm3Right = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalDFTNm3Right = effDFTNm3Left.reduce(
        (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
        0
      );
    } else {
      let sumeffDFTNm3Left = effDFTNm3Left.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffDFTNm3Right = effDFTNm3Right.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalDFTNm3Right = roundUp(
        (sumeffDFTNm3Left + sumeffDFTNm3Right) / 2,
        2
      );
    }

    //Fumes/Air Ratio
    const effFumesAirRatioLeft = effDFTNm3Left.map((value, index) => {
      const fumesAirLeft = roundUp(value / flowsDATDanDexaLeft[index], 2)
      return (isNaN(fumesAirLeft) || fumesAirLeft === '') ? 0 : fumesAirLeft;
    });
    const effFumesAirRatioRight = effDFTNm3Right.map((value, index) => {
      const fumesAirRight = roundUp(value / flowsDATDanDexaRight[index], 2)
      return (isNaN(fumesAirRight) || fumesAirRight === '') ? 0 : fumesAirRight;
    });

    let effTotalFumesAirRatioLeft = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalFumesAirRatioLeft = [
        effTotalDFTNm3Left,
        flowsTotalDATDanDexaLeft,
      ].reduce((acc, curr) => roundUp(acc / curr, 2));
    } else {
      let sumeffFumesAirRatioLeft = effFumesAirRatioLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffFumesAirRatioRight = effFumesAirRatioRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalFumesAirRatioLeft = roundUp(
        (sumeffFumesAirRatioLeft + sumeffFumesAirRatioRight) / 2,
        2
      );
    }
    console.log(effTotalFumesAirRatioLeft, "effTotalFumesAirRatioLeft");

    let effTotalFumesAirRatioRight = [];
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalFumesAirRatioRight = [
        effTotalDFTNm3Left,
        flowsTotalDATDanDexaLeft,
      ].reduce((acc, curr) => roundUp(acc / curr, 2));
    } else {
      let sumeffFumesAirRatioLeft = effFumesAirRatioLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffFumesAirRatioRight = effFumesAirRatioRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalFumesAirRatioRight = roundUp(
        (sumeffFumesAirRatioLeft + sumeffFumesAirRatioRight) / 2,
        2
      );
    }

    //Air Speed(m/s)
    const tempRegenData = get(furnaceDetails);
    const flowsRegenDepth = commonFunction(
      "singleValue",
      tempRegenData.regeneratorDesign.chamberDimensionsCFF.depth,
      tempRegenData.regeneratorDesign.chamberDimensionsEFF.depth
    );
    const flowsRegeneratorDepth = [];
    flowsRegenDepth.map((data) => {
      if (data) {
        flowsRegeneratorDepth.push(Number(data));
      }
    });
    const flowsRegenWidth = commonFunction(
      "singleValue",
      tempRegenData.regeneratorDesign.chamberDimensionsCFF.width,
      tempRegenData.regeneratorDesign.chamberDimensionsEFF.width
    );
    const flowsRegeneratorWidth = [];
    flowsRegenWidth.map((data) => {
      if (data) {
        flowsRegeneratorWidth.push(Number(data));
      }
    });
    const flowsRegenThickness = commonFunction(
      "singleValue",
      tempRegenData.regeneratorDesign.packingPropertiesCFF.thickness,
      tempRegenData.regeneratorDesign.packingPropertiesEFF.thickness
    );
    const flowsRegeneratorThickness = [];
    flowsRegenThickness.map((data) => {
      if (data) {
        flowsRegeneratorThickness.push(Number(data));
      }
    });
    const flowsRegenDepthChannels = commonFunction(
      "singleValue",
      tempRegenData.regeneratorDesign.noOfChannelsCFF.depthChannels,
      tempRegenData.regeneratorDesign.noOfChannelsEFF.depthChannels
    );
    const flowsRegeneratorDepthChannels = [];
    flowsRegenDepthChannels.map((data) => {
      if (data) {
        flowsRegeneratorDepthChannels.push(Number(data));
      }
    });
    const flowsRegenWidthChannels = commonFunction(
      "singleValue",
      tempRegenData.regeneratorDesign.noOfChannelsCFF.widthChannels,
      tempRegenData.regeneratorDesign.noOfChannelsEFF.widthChannels
    );
    const flowsRegeneratorWidthChannels = [];
    flowsRegenWidthChannels.map((data) => {
      if (data) {
        flowsRegeneratorWidthChannels.push(Number(data));
      }
    });

    //Flows Geometry
    const numberOfFluesA = flowsRegeneratorWidthChannels;
    const numberOfFluesB = flowsRegeneratorDepthChannels;
    const availableAreaA = flowsRegeneratorWidth
      .reduce((acc, width, index) => {
        const flueAvailableAreaA = numberOfFluesA[index] + 1;
        const thickness = flowsRegeneratorThickness[index];
        const areaA = (width - flueAvailableAreaA * thickness);
        acc.push(areaA);
        return acc;
      }, [])
      .map((area) => roundUp(area, 4));
    const availableAreaB = flowsRegeneratorDepth
      .reduce((acc, width, index) => {
        const flueAvailableAreaB = numberOfFluesB[index] + 1;
        const thickness = flowsRegeneratorThickness[index];
        const areaB = (width - flueAvailableAreaB * thickness);
        acc.push(areaB);
        return acc;
      }, [])
      .map((area) => roundUp(area, 4));

    const effAirSpeedLeft = flowsDATDanDexaLeft.map((flow) => {
      const resultAirLeft = flow / (availableAreaA[0] * availableAreaB[0] * 3600);
      return resultAirLeft;
    });
    console.log(effAirSpeedLeft, "effAirSpeedLeft");
    const effAirSpeedRight = flowsDATDanDexaRight.map((flow) => {
      const resultAirRight = flow / (availableAreaA[0] * availableAreaB[0] * 3600);
      return resultAirRight;
    });
    let effTotalAirSpeedLeft;
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalAirSpeedLeft = flowsTotalDATDanDexaLeft / (availableAreaA[0] * availableAreaB[0] * 3600);
    } else {
      let sumeffAirSpeedLeft = effAirSpeedLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffAirSpeedRight = effAirSpeedRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalAirSpeedLeft = roundUp(
        (sumeffAirSpeedLeft + sumeffAirSpeedRight) / 2,
        2
      );
    }
    let effTotalAirSpeedRight;
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalAirSpeedRight = flowsTotalDATDanDexaRight / (availableAreaA[0] * availableAreaB[0] * 3600);
    } else {
      let sumeffAirSpeedLeft = effAirSpeedLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffAirSpeedRight = effAirSpeedRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalAirSpeedRight = roundUp(
        (sumeffAirSpeedLeft + sumeffAirSpeedRight) / 2,
        2
      );
    }

    //Fumes Speed(m/s)
    const effFumesSpeedLeft = effDFTNm3Left.map((flow) => {
      const resultFumesLeft = flow / (availableAreaA[0] * availableAreaB[0] * 3600);
      return resultFumesLeft;
    });
    const effFumesSpeedRight = effDFTNm3Right.map((flow) => {
      const resultFumesRight = flow / (availableAreaA[0] * availableAreaB[0] * 3600);
      return resultFumesRight;
    });
    let effTotalFumesSpeedLeft;
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalFumesSpeedLeft = flowsTotalDATDanDexaLeft / (availableAreaA[0] * availableAreaB[0] * 3600);
    } else {
      let sumeffFumesSpeedLeft = effFumesSpeedLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffFumesSpeedRight = effFumesSpeedRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalFumesSpeedLeft = roundUp(
        (sumeffFumesSpeedLeft + sumeffFumesSpeedRight) / 2,
        2
      );
    }
    let effTotalFumesSpeedRight;
    if (custoDetails.furnaceType === "Cross Fired") {
      effTotalFumesSpeedRight = flowsTotalDATDanDexaRight / (availableAreaA[0] * availableAreaB[0] * 3600);
    } else {
      let sumeffFumesSpeedLeft = effFumesSpeedLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let sumeffFumesSpeedRight = effFumesSpeedRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      effTotalFumesSpeedRight = roundUp(
        (sumeffFumesSpeedLeft + sumeffFumesSpeedRight) / 2,
        2
      );
    }

    //Fumes Composition
    //Calculated Fumes //CO2 //N2 //H2O //O2
    const effCalFumesCO2Left = flowsCalculFumesCO2Left.map(
      (value) => roundUp((value / 100) * 100),
      2
    );
    const effCalFumesCO2Right = flowsCalculFumesCO2Right.map(
      (value) => roundUp((value / 100) * 100),
      2
    );
    const effCalFumesN2Left = flowsCalCulFumesN2Left.map(
      (value) => roundUp((value / 100) * 100),
      2
    );

    const effCalFumesN2Right = flowsCalCulFumesN2Right.map(
      (value) => roundUp((value / 100) * 100),
      2
    );
    const effCalFumesH2OLeft = flowsCalculFumesH2OLeft.map(
      (value) => roundUp((value / 100) * 100),
      2
    );

    const effCalFumesH2ORight = flowsCalculFumesH2ORight.map(
      (value) => roundUp((value / 100) * 100),
      2
    );
    const effCalFumesO2Left = flowsCalFumesO2Left.map(
      (value) => roundUp((value / 100) * 100),
      2
    );

    const effCalFumesO2Right = flowsCalFumesO2Right.map(
      (value) => roundUp((value / 100) * 100),
      2
    );

    //Calculated Fumes Total
    const totalFumesCompoPercentVolLeft = effCalFumesCO2Left.map(
      (num, index) => {
        if (
          num === 0 &&
          effCalFumesN2Left[index] === 0 &&
          effCalFumesH2OLeft[index] === 0 &&
          effCalFumesO2Left[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            num +
            effCalFumesN2Left[index] +
            effCalFumesH2OLeft[index] +
            effCalFumesO2Left[index],
            2
          );
        }
      }
    );
    const totalFumesCompoPercentVolRight = effCalFumesCO2Right.map(
      (num, index) => {
        if (
          num === 0 &&
          effCalFumesN2Right[index] === 0 &&
          effCalFumesH2ORight[index] === 0 &&
          effCalFumesO2Right[index] === 0
        ) {
          return 0;
        } else {
          return roundUp(
            num +
            effCalFumesN2Right[index] +
            effCalFumesH2ORight[index] +
            effCalFumesO2Right[index],
            2
          );
        }
      }
    );

    //Calculated Fumes //ρ(kg/m3)
    const effCalFumesCompRLeft = effCalFumesCO2Left.map((num, index) => {
      if (
        num === 0 &&
        effCalFumesN2Left[index] === 0 &&
        effCalFumesH2OLeft[index] === 0 &&
        effCalFumesO2Left[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            effCalFumesN2Left[index] * 28 +
            effCalFumesH2OLeft[index] * 18 +
            effCalFumesO2Left[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });
    const effCalFumesCompRRight = effCalFumesCO2Right.map((num, index) => {
      if (
        num === 0 &&
        effCalFumesN2Right[index] === 0 &&
        effCalFumesH2ORight[index] === 0 &&
        effCalFumesO2Right[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            effCalFumesN2Right[index] * 28 +
            effCalFumesH2ORight[index] * 18 +
            effCalFumesO2Right[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });

    //Calculated Fumes //M(g/mol)
    const effCalFumesCompMLeft = effCalFumesCO2Left.map((num, index) => {
      if (
        num === 0 &&
        effCalFumesN2Left[index] === 0 &&
        effCalFumesH2OLeft[index] === 0 &&
        effCalFumesO2Left[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            effCalFumesN2Left[index] * 28 +
            effCalFumesH2OLeft[index] * 18 +
            effCalFumesO2Left[index] * 32) /
          100,
          3
        );
      }
    });
    const effCalFumesCompMRight = effCalFumesCO2Right.map((num, index) => {
      if (
        num === 0 &&
        effCalFumesN2Right[index] === 0 &&
        effCalFumesH2ORight[index] === 0 &&
        effCalFumesO2Right[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            effCalFumesN2Right[index] * 28 +
            effCalFumesH2ORight[index] * 18 +
            effCalFumesO2Right[index] * 32) /
          100,
          3
        );
      }
    });

    //Calculated Fumes Mass(%)
    const totalCalCO2PercentageMassLeft = effCalFumesCO2Left.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 44) / effCalFumesCompMLeft[index]) * 100,
            3
          );
        }
      }
    );
    console.log(effCalFumesCO2Left, "effCalFumesCO2Left");

    const totalCalCO2PercentageMassRight = effCalFumesCO2Right.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 44) / effCalFumesCompMRight[index]) * 100,
            3
          );
        }
      }
    );
    console.log(effCalFumesCO2Right, "effCalFumesCO2Right");

    const totalCalN2PercentageMassLeft = effCalFumesN2Left.map((num, index) => {
      if (num === 0 && effCalFumesCompMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 28) / effCalFumesCompMLeft[index]) * 100,
          3
        );
      }
    });

    const totalCalN2PercentageMassRight = effCalFumesN2Right.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 28) / effCalFumesCompMRight[index]) * 100,
            3
          );
        }
      }
    );

    const totalCalH2OPercentageMassLeft = effCalFumesH2OLeft.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMLeft[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 18) / effCalFumesCompMLeft[index]) * 100,
            3
          );
        }
      }
    );

    const totalCalH2OPercentageMassRight = effCalFumesH2ORight.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 18) / effCalFumesCompMRight[index]) * 100,
            3
          );
        }
      }
    );

    const totalCalO2PercentageMassLeft = effCalFumesO2Left.map((num, index) => {
      if (num === 0 && effCalFumesCompMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 32) / effCalFumesCompMLeft[index]) * 100,
          3
        );
      }
    });

    const totalCalO2PercentageMassRight = effCalFumesO2Right.map(
      (num, index) => {
        if (num === 0 && effCalFumesCompMRight[index] === 0) {
          return 0;
        } else {
          return roundUp(
            (((num / 100) * 32) / effCalFumesCompMRight[index]) * 100,
            3
          );
        }
      }
    );

    //Dusts Analysis  //Generates per hour(Gr/h)
    const tempDustsAnalysis = get(dustsAnalysis);
    const dustsGenAnalysisSIO2 = commonFunction(
      "singleValue",
      tempDustsAnalysis.carryoversCFF.sio2,
      tempDustsAnalysis.carryoversEFF.sio2
    );
    const dustsGenAnalysisCAO = commonFunction(
      "singleValue",
      tempDustsAnalysis.carryoversCFF.cao,
      tempDustsAnalysis.carryoversEFF.cao
    );
    const dustsGenAnalysisMGO = commonFunction(
      "singleValue",
      tempDustsAnalysis.carryoversCFF.mgo,
      tempDustsAnalysis.carryoversEFF.mgo
    );
    const dustsGenAnalysisAL2O3 = commonFunction(
      "singleValue",
      tempDustsAnalysis.carryoversCFF.al2o3,
      tempDustsAnalysis.carryoversEFF.al2o3
    );
    const dustsGenCarryOversSIO2 = [];
    dustsGenAnalysisSIO2.map((data) => {
      if (data) {
        dustsGenCarryOversSIO2.push(Number(data));
      }
    });
    const dustsGenCarryOversCAO = [];
    dustsGenAnalysisCAO.map((data) => {
      if (data) {
        dustsGenCarryOversCAO.push(Number(data));
      }
    });
    const dustsGenCarryOversMGO = [];
    dustsGenAnalysisMGO.map((data) => {
      if (data) {
        dustsGenCarryOversMGO.push(Number(data));
      }
    });
    const dustsGenCarryOversAL2O3 = [];
    dustsGenAnalysisAL2O3.map((data) => {
      if (data) {
        dustsGenCarryOversAL2O3.push(Number(data));
      }
    });
    const dustsGenAnalysisNA2O = commonFunction(
      "singleValue",
      tempDustsAnalysis.alkalineCompoundsCFF.na2o,
      tempDustsAnalysis.alkalineCompoundsEFF.na2o
    );
    const dustsGenAnalysisK2O = commonFunction(
      "singleValue",
      tempDustsAnalysis.alkalineCompoundsCFF.k2o,
      tempDustsAnalysis.alkalineCompoundsEFF.k2o
    );
    const dustsGenAlkalineNA2O = [];
    dustsGenAnalysisNA2O.map((data) => {
      if (data) {
        dustsGenAlkalineNA2O.push(Number(data));
      }
    });
    const dustsGenAlkalineK2O = [];
    dustsGenAnalysisK2O.map((data) => {
      if (data) {
        dustsGenAlkalineK2O.push(Number(data));
      }
    });
    const dustsGenAnalysisP2O5 = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.p2o5,
      tempDustsAnalysis.othersEFF.p2o5
    );
    const dustsGenAnalysisCR2O3 = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.cr2o3,
      tempDustsAnalysis.othersEFF.cr2o3
    );
    const dustsGenAnalysisFE2O3 = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.fe2o3,
      tempDustsAnalysis.othersEFF.fe2o3
    );
    const dustsGenAnalysisPBO = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.pbo,
      tempDustsAnalysis.othersEFF.pbo
    );
    const dustsGenAnalysisV2O5 = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.v2o5,
      tempDustsAnalysis.othersEFF.v2o5
    );
    const dustsGenAnalysisCL = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.cl,
      tempDustsAnalysis.othersEFF.cl
    );
    const dustsGenAnalysisNO3 = commonFunction(
      "singleValue",
      tempDustsAnalysis.othersCFF.no3,
      tempDustsAnalysis.othersEFF.no3
    );
    const dustsGenOthersP2O5 = [];
    dustsGenAnalysisP2O5.map((data) => {
      if (data) {
        dustsGenOthersP2O5.push(Number(data));
      }
    });
    const dustsGenOthersCR2O3 = [];
    dustsGenAnalysisCR2O3.map((data) => {
      if (data) {
        dustsGenOthersCR2O3.push(Number(data));
      }
    });
    const dustsGenOthersFE2O3 = [];
    dustsGenAnalysisFE2O3.map((data) => {
      if (data) {
        dustsGenOthersFE2O3.push(Number(data));
      }
    });
    const dustsGenOthersPBO = [];
    dustsGenAnalysisPBO.map((data) => {
      if (data) {
        dustsGenOthersPBO.push(Number(data));
      }
    });
    const dustsGenOthersV2O5 = [];
    dustsGenAnalysisV2O5.map((data) => {
      if (data) {
        dustsGenOthersV2O5.push(Number(data));
      }
    });
    const dustsGenOthersCL = [];
    dustsGenAnalysisCL.map((data) => {
      if (data) {
        dustsGenOthersCL.push(Number(data));
      }
    });
    const dustsGenOthersNO3 = [];
    dustsGenAnalysisNO3.map((data) => {
      if (data) {
        dustsGenOthersNO3.push(Number(data));
      }
    });

    const dustsGeneCarryoversSIO2 = dustsGenCarryOversSIO2.map(
      (value, index) => {
        let sumAvgSIO2 = [];
        let calSIO2 = [];
        if (customerDet.chamberType === "Both Side") {
          if (customerDet.furnaceType === "Cross Fired") {
            sumAvgSIO2 = (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
              2;
            calSIO2 = roundUp((value * (sumAvgSIO2 / 1000)), 2);
            return calSIO2;
          } else {
            sumAvgSIO2 =
              flowsDFTDfnDgcDexaLeft[index];
            calSIO2 = roundUp((value * (sumAvgSIO2 / 1000)), 2);
            return calSIO2;
          }
        } else {
          return roundUp((value * (flowsDFTDfnDgcDexaLeft[index]) / 1000), 2);
        }
      }
    );

    const dustsGeneCarryoversCAO = dustsGenCarryOversCAO.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgCAO =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calCAO = roundUp((value * sumAvgCAO) / 1000, 2);
          return calCAO;
        } else {
          let sumAvgCAO =
            flowsDFTDfnDgcDexaLeft[index];
          let calCAO = roundUp((value * sumAvgCAO) / 1000, 2);
          return calCAO;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneCarryoversMGO = dustsGenCarryOversMGO.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgMGO =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calMGO = roundUp((value * sumAvgMGO) / 1000, 2);
          return calMGO;
        } else {
          let sumAvgMGO =
            flowsDFTDfnDgcDexaLeft[index];
          let calMGO = roundUp((value * sumAvgMGO) / 1000, 2);
          return calMGO;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneCarryoversAL2O3 = dustsGenCarryOversAL2O3.map(
      (value, index) => {
        if (customerDet.chamberType === "Both Side") {
          if (customerDet.furnaceType === "Cross Fired") {
            let sumAvgAL2O3 =
              (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
              2;
            let calAL2O3 = roundUp((value * sumAvgAL2O3) / 1000, 2);
            return calAL2O3;
          } else {
            let sumAvgAL2O3 =
              flowsDFTDfnDgcDexaLeft[index];
            let calAL2O3 = roundUp((value * sumAvgAL2O3) / 1000, 2);
            return calAL2O3;
          }
        } else {
          return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
        }
      }
    );

    const dustsGeneAlkalineNA2O = dustsGenAlkalineNA2O.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgNA2O =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calNA2O = roundUp((value * sumAvgNA2O) / 1000, 2);
          return calNA2O;
        } else {
          let sumAvgNA2O =
            flowsDFTDfnDgcDexaLeft[index];
          let calNA2O = roundUp((value * sumAvgNA2O) / 1000, 2);
          return calNA2O;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneAlkalineK2O = dustsGenAlkalineK2O.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgK2O =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calK2O = roundUp((value * sumAvgK2O) / 1000, 2);
          return calK2O;
        } else {
          let sumAvgK2O =
            flowsDFTDfnDgcDexaLeft[index];
          let calK2O = roundUp((value * sumAvgK2O) / 1000, 2);
          return calK2O;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneAlkalineSO4 = dustsGeneAlkalineNA2O.map((value, index) => {
      let calSO4 = [];
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          calSO4 = roundUp((((value / 0.3239) - value) + ((dustsGeneAlkalineK2O[index] / 0.4489) - dustsGeneAlkalineK2O[index])), 2);
          return calSO4;
        } else {
          calSO4 = roundUp((((value / 0.3239) - value) + ((dustsGeneAlkalineK2O[index] / 0.4489) - dustsGeneAlkalineK2O[index])), 2);
          return calSO4;
        }
      } else {
        return roundUp((((value / 0.3239) - value) + ((dustsGeneAlkalineK2O[index] / 0.4489) - dustsGeneAlkalineK2O[index])), 2);
      }
    });

    const dustsGeneOthersP2O5 = dustsGenOthersP2O5.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgP2O5 =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calP2O5 = roundUp((value * sumAvgP2O5) / 1000, 2);
          return calP2O5;
        } else {
          let sumAvgP2O5 =
            flowsDFTDfnDgcDexaLeft[index];
          let calP2O5 = roundUp((value * sumAvgP2O5) / 1000, 2);
          return calP2O5;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersCR2O3 = dustsGenOthersCR2O3.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgCR2O3 =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calCR2O3 = roundUp((value * sumAvgCR2O3) / 1000, 2);
          return calCR2O3;
        } else {
          let sumAvgCR2O3 =
            flowsDFTDfnDgcDexaLeft[index];
          let calCR2O3 = roundUp((value * sumAvgCR2O3) / 1000, 2);
          return calCR2O3;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersFE2O3 = dustsGenOthersFE2O3.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgFE2O3 =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calFE2O3 = roundUp((value * sumAvgFE2O3) / 1000, 2);
          return calFE2O3;
        } else {
          let sumAvgFE2O3 =
            flowsDFTDfnDgcDexaLeft[index];
          let calFE2O3 = roundUp((value * sumAvgFE2O3) / 1000, 2);
          return calFE2O3;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersPBO = dustsGenOthersPBO.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgPBO =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calPBO = roundUp((value * sumAvgPBO) / 1000, 2);
          return calPBO;
        } else {
          let sumAvgPBO =
            flowsDFTDfnDgcDexaLeft[index];
          let calPBO = roundUp((value * sumAvgPBO) / 1000, 2);
          return calPBO;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersV2O5 = dustsGenOthersV2O5.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgV2O5 =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calV2O5 = roundUp((value * sumAvgV2O5) / 1000, 2);
          return calV2O5;
        } else {
          let sumAvgV2O5 =
            flowsDFTDfnDgcDexaLeft[index];
          let calV2O5 = roundUp((value * sumAvgV2O5) / 1000, 2);
          return calV2O5;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersCL = dustsGenOthersCL.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgCL =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calCL = roundUp((value * sumAvgCL) / 1000, 2);
          return calCL;
        } else {
          let sumAvgCL =
            flowsDFTDfnDgcDexaLeft[index];
          let calCL = roundUp((value * sumAvgCL) / 1000, 2);
          return calCL;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const dustsGeneOthersNO3 = dustsGenOthersNO3.map((value, index) => {
      if (customerDet.chamberType === "Both Side") {
        if (customerDet.furnaceType === "Cross Fired") {
          let sumAvgNO3 =
            (flowsDFTDfnDgcDexaLeft[index] + flowsDFTDfnDgcDexaRight[index]) /
            2;
          let calNO3 = roundUp((value * sumAvgNO3) / 1000, 2);
          return calNO3;
        } else {
          let sumAvgNO3 =
            flowsDFTDfnDgcDexaLeft[index];
          let calNO3 = roundUp((value * sumAvgNO3) / 1000, 2);
          return calNO3;
        }
      } else {
        return roundUp((value * flowsDFTDfnDgcDexaLeft[index]) / 1000, 2);
      }
    });

    const sumDustsAnalysisArrays = dustsGeneCarryoversSIO2.map(
      (val, index) =>
        roundUp(
          val +
          dustsGeneCarryoversCAO[index] +
          dustsGeneCarryoversMGO[index] +
          dustsGeneCarryoversAL2O3[index] +
          dustsGeneAlkalineNA2O[index] +
          dustsGeneAlkalineK2O[index] +
          dustsGeneAlkalineSO4[index] +
          dustsGeneOthersP2O5[index] +
          dustsGeneOthersCR2O3[index] +
          dustsGeneOthersFE2O3[index] +
          dustsGeneOthersPBO[index] +
          dustsGeneOthersV2O5[index] +
          dustsGeneOthersCL[index] +
          dustsGeneOthersNO3[index]
        ),
      2
    );

    //Generates per year(t/y)
    const dustsYearCarryoversSIO2 = dustsGeneCarryoversSIO2.map(
      (value) => {
        let dustSio2 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustSio2 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustSio2;
        } else {
          dustSio2 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustSio2;
        }
      }
    );
    const dustsYearCarryoversCAO = dustsGeneCarryoversCAO.map(
      (value) => {
        let dustCao = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustCao = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustCao;
        } else {
          dustCao = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustCao;
        }
      }
    );
    const dustsYearCarryoversMGO = dustsGeneCarryoversMGO.map(
      (value) => {
        let dustMgo = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustMgo = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustMgo;
        } else {
          dustMgo = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustMgo;
        }
      }
    );
    const dustsYearCarryoversAL2O3 = dustsGeneCarryoversAL2O3.map(
      (value) => {
        let dustAl2o3 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustAl2o3 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustAl2o3;
        } else {
          dustAl2o3 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustAl2o3;
        }
      }
    );
    const dustsYearAlkalineNA2O = dustsGeneAlkalineNA2O.map(
      (value) => {
        let dustNa2o = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustNa2o = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustNa2o;
        } else {
          dustNa2o = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustNa2o;
        }
      }
    );
    const dustsYearAlkalineK2O = dustsGeneAlkalineK2O.map(
      (value) => {
        let dustK2o = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustK2o = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustK2o;
        } else {
          dustK2o = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustK2o;
        }
      }
    );
    const dustsYearAlkalineSO4 = dustsGeneAlkalineSO4.map(
      (value) => {
        let dustSo4 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustSo4 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustSo4;
        } else {
          dustSo4 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustSo4;
        }
      }
    );
    const dustsYearOthersP2O5 = dustsGeneOthersP2O5.map(
      (value) => {
        let dustP2o5 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustP2o5 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustP2o5;
        } else {
          dustP2o5 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustP2o5;
        }
      }
    );
    const dustsYearOthersCR2O3 = dustsGeneOthersCR2O3.map(
      (value) => {
        let dustCr2o3 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustCr2o3 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustCr2o3;
        } else {
          dustCr2o3 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustCr2o3;
        }
      }
    );
    const dustsYearOthersFE2O3 = dustsGeneOthersFE2O3.map(
      (value) => {
        let dustFe2o3 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustFe2o3 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustFe2o3;
        } else {
          dustFe2o3 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustFe2o3;
        }
      }
    );
    const dustsYearOthersPBO = dustsGeneOthersPBO.map(
      (value) => {
        let dustPbo = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustPbo = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustPbo;
        } else {
          dustPbo = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustPbo;
        }
      }
    );
    const dustsYearOthersV2O5 = dustsGeneOthersV2O5.map(
      (value) => {
        let dustV2o5 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustV2o5 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustV2o5;
        } else {
          dustV2o5 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustV2o5;
        }
      }
    );
    const dustsYearOthersCL = dustsGeneOthersCL.map(
      (value) => {
        let dustCl = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustCl = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustCl;
        } else {
          dustCl = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustCl;
        }
      }
    );
    const dustsYearOthersNO3 = dustsGeneOthersNO3.map(
      (value) => {
        let dustNo3 = [];
        if (customerDet.furnaceType === "Cross Fired") {
          dustNo3 = roundUp(((value * 24 * 365) / 1000000),
            3);
          return dustNo3;
        } else {
          dustNo3 = roundUp((((value * 24 * 365) / 1000000) / 2),
            3);
          return dustNo3;
        }
      }
    );

    const dustsAnalysisYearArrays = sumDustsAnalysisArrays.map(
      (value) => roundUp(((value * 24 * 365) / 1000000 / 2), 2)
    );

    //Average
    const filteredArraySio2 = dustsYearCarryoversSIO2.filter(value => value !== 0 && value !== '');
    const effSumSIO2 = filteredArraySio2.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageCarryoversSIO2 =
      functionForNullCheck("calc", effSumSIO2, filteredArraySio2);
    const filteredArrayCao = dustsYearCarryoversCAO.filter(value => value !== 0 && value !== '');
    const effSumCAO = filteredArrayCao.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageCarryoversCAO = functionForNullCheck("calc", effSumCAO, filteredArrayCao);
    const filteredArrayMgo = dustsYearCarryoversMGO.filter(value => value !== 0 && value !== '');
    const effSumMGO = filteredArrayMgo.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageCarryoversMGO = functionForNullCheck("calc", effSumMGO, filteredArrayMgo);
    const filteredArrayAl2o3 = dustsYearCarryoversAL2O3.filter(value => value !== 0 && value !== '');
    const effSumAL2O3 = filteredArrayAl2o3.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageCarryoversAL2O3 =
      functionForNullCheck("calc", effSumAL2O3, filteredArrayAl2o3);
    const filteredArrayNa2o = dustsYearAlkalineNA2O.filter(value => value !== 0 && value !== '');
    const effSumNA2O = filteredArrayNa2o.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageAlkalineNA2O = functionForNullCheck("calc", effSumNA2O, filteredArrayNa2o);
    const filteredArrayK2o = dustsYearAlkalineK2O.filter(value => value !== 0 && value !== '');
    const effSumK2O = filteredArrayK2o.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageAlkalineK2O = functionForNullCheck("calc", effSumK2O, filteredArrayK2o);
    const filteredArraySo4 = dustsYearAlkalineSO4.filter(value => value !== 0 && value !== '');
    const effSumSO4 = filteredArraySo4.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageAlkalineSO4 = functionForNullCheck("calc", effSumSO4, filteredArraySo4);
    const filteredArrayP2o5 = dustsYearOthersP2O5.filter(value => value !== 0 && value !== '');
    const effSumP2O5 = filteredArrayP2o5.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersP2O5 = functionForNullCheck("calc", effSumP2O5, filteredArrayP2o5);
    const filteredArrayCr2o3 = dustsYearOthersCR2O3.filter(value => value !== 0 && value !== '');
    const effSumCR2O3 = filteredArrayCr2o3.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersCR2O3 = functionForNullCheck("calc", effSumCR2O3, filteredArrayCr2o3);
    const filteredArrayFe2o3 = dustsYearOthersFE2O3.filter(value => value !== 0 && value !== '');
    const effSumFE2O3 = filteredArrayFe2o3.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersFE2O3 = functionForNullCheck("calc", effSumFE2O3, filteredArrayFe2o3);
    const filteredArrayPbo = dustsYearOthersPBO.filter(value => value !== 0 && value !== '');
    const effSumPBO = filteredArrayPbo.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersPBO = functionForNullCheck("calc", effSumPBO, filteredArrayPbo);
    const filteredArrayV2o5 = dustsYearOthersV2O5.filter(value => value !== 0 && value !== '');
    const effSumV2O5 = filteredArrayV2o5.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersV2O5 = functionForNullCheck("calc", effSumV2O5, filteredArrayV2o5);
    const filteredArrayCl = dustsYearOthersCL.filter(value => value !== 0 && value !== '');
    const effSumCL = filteredArrayCl.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersCL = functionForNullCheck("calc", effSumCL, filteredArrayCl);
    const filteredArrayNo3 = dustsYearOthersNO3.filter(value => value !== 0 && value !== '');
    const effSumNO3 = filteredArrayNo3.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 3),
      0
    );
    const effAverageOthersNO3 = functionForNullCheck("calc", effSumNO3, filteredArrayNo3);

    //Average Total
    const filteredArrayAverage = dustsAnalysisYearArrays.filter(value => value !== 0 && value !== '');
    const effOverallAverage = filteredArrayAverage.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );
    const effOverallAverageTotal =
      functionForNullCheck("roundup", effOverallAverage, filteredArrayAverage, 2);

    //Efficiencies Total(Total)
    const effTotalSum = roundUp((effSumSIO2 + effSumCAO + effSumMGO + effSumAL2O3 + effSumNA2O + effSumK2O + effSumSO4 + effSumP2O5 + effSumCR2O3 + effSumFE2O3 + effSumPBO + effSumV2O5 + effSumCL + effSumNO3), 2);

    //Carryovers Si+Ca+Al+Mg
    const sumAllDustsYearCarryovers = [
      ...dustsYearCarryoversSIO2,
      ...dustsYearCarryoversCAO,
      ...dustsYearCarryoversMGO,
      ...dustsYearCarryoversAL2O3,
    ];
    const effCarryoversSum = sumAllDustsYearCarryovers.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Alkaline Compounds  Na+K+SO
    const sumAllDustsYearAlkaline = [
      ...dustsYearAlkalineNA2O,
      ...dustsYearAlkalineK2O,
      ...dustsYearAlkalineSO4,
    ];
    const effAlkalineCompundsSum = sumAllDustsYearAlkaline.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Others
    const sumAllDustsYearOthers = [
      ...dustsYearOthersP2O5,
      ...dustsYearOthersCR2O3,
      ...dustsYearOthersFE2O3,
      ...dustsYearOthersPBO,
      ...dustsYearOthersV2O5,
      ...dustsYearOthersCL,
      ...dustsYearOthersNO3,
    ];
    const effOthersSum = sumAllDustsYearOthers.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2),
      0
    );

    //Efficiencies Overall
    const effOverallSum = roundUp(
      effCarryoversSum + effAlkalineCompundsSum + effOthersSum,
      2
    );

    //Efficiencies Correlation Measured Fumes Correlation (Right side of Excel sheet)
    const tmpConcentrationData = get(fumesComposition);
    //Left Side
    const tmpCorrelation = get(fumesElemCorrelation);
    const o2ConcenLeft = commonFunction(
      "left",
      tmpConcentrationData.compositionCFF.o2,
      tmpConcentrationData.compositionEFF.o2
    );
    const co2ConcenLeft = commonFunction(
      "left",
      tmpConcentrationData.compositionCFF.co2,
      tmpConcentrationData.compositionEFF.co2
    );
    const h2oConcenLeft = commonFunction(
      "left",
      tmpConcentrationData.compositionCFF.h2o,
      tmpConcentrationData.compositionEFF.h2o
    );
    const o2ConcenLeftSide = [];
    o2ConcenLeft.map((data) => {
      if (data) {
        o2ConcenLeftSide.push(Number(data));
      }
    });
    const co2ConcenLeftSide = [];
    co2ConcenLeft.map((data) => {
      if (data) {
        co2ConcenLeftSide.push(Number(data));
      }
    });
    const h2oConcenLeftSide = [];
    h2oConcenLeft.map((data) => {
      if (data) {
        h2oConcenLeftSide.push(Number(data));
      }
    });
    const sumN2ConcenLeft = co2ConcenLeftSide.map((num, index) => {
      if (
        num === 0 &&
        h2oConcenLeftSide[index] === 0 &&
        o2ConcenLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          100 - num - h2oConcenLeftSide[index] - o2ConcenLeftSide[index],
          2
        );
      }
    });

    const calFumesComposRLeft = co2ConcenLeftSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcenLeft[index] === 0 &&
        h2oConcenLeftSide[index] === 0 &&
        o2ConcenLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcenLeft[index] * 28 +
            h2oConcenLeftSide[index] * 18 +
            o2ConcenLeftSide[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });

    const calFumesComposMLeft = co2ConcenLeftSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcenLeft[index] === 0 &&
        h2oConcenLeftSide[index] === 0 &&
        o2ConcenLeftSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcenLeft[index] * 28 +
            h2oConcenLeftSide[index] * 18 +
            o2ConcenLeftSide[index] * 32) /
          100,
          2
        );
      }
    });

    const totalCO2PercentMassLeft = co2ConcenLeftSide.map((num, index) => {
      if (num === 0 && calFumesComposMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 44) / calFumesComposMLeft[index]) * 100,
          1
        );
      }
    });

    const totalH2OPercentMassLeft = h2oConcenLeftSide.map((num, index) => {
      if (num === 0 && calFumesComposMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 18) / calFumesComposMLeft[index]) * 100,
          1
        );
      }
    });

    const totalN2PercentMassLeft = sumN2ConcenLeft.map((num, index) => {
      if (num === 0 && calFumesComposMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 28) / calFumesComposMLeft[index]) * 100,
          1
        );
      }
    });

    const totalO2PercentMassLeft = o2ConcenLeftSide.map((num, index) => {
      if (num === 0 && calFumesComposMLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 32) / calFumesComposMLeft[index]) * 100,
          1
        );
      }
    });
    //Right Side
    const o2ConcenRight = commonFunction(
      "right",
      tmpConcentrationData.compositionCFF.o2,
      tmpConcentrationData.compositionEFF.o2
    );
    const co2ConcenRight = commonFunction(
      "right",
      tmpConcentrationData.compositionCFF.co2,
      tmpConcentrationData.compositionEFF.co2
    );
    const h2oConcenRight = commonFunction(
      "right",
      tmpConcentrationData.compositionCFF.h2o,
      tmpConcentrationData.compositionEFF.h2o
    );
    const o2ConcenRightSide = [];
    o2ConcenRight.map((data) => {
      if (data) {
        o2ConcenRightSide.push(Number(data));
      }
    });
    const co2ConcenRightSide = [];
    co2ConcenRight.map((data) => {
      if (data) {
        co2ConcenRightSide.push(Number(data));
      }
    });
    const h2oConcenRightSide = [];
    h2oConcenRight.map((data) => {
      if (data) {
        h2oConcenRightSide.push(Number(data));
      }
    });
    const sumN2ConcenRight = co2ConcenRightSide.map((num, index) => {
      if (
        num === 0 &&
        h2oConcenRightSide[index] === 0 &&
        o2ConcenRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          100 - num - h2oConcenRightSide[index] - o2ConcenRightSide[index],
          2
        );
      }
    });

    const calFumesComposRRight = co2ConcenRightSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcenRight[index] === 0 &&
        h2oConcenRightSide[index] === 0 &&
        o2ConcenRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcenRight[index] * 28 +
            h2oConcenRightSide[index] * 18 +
            o2ConcenRightSide[index] * 32) /
          22.4 /
          100,
          3
        );
      }
    });

    const calFumesCompMRight = co2ConcenRightSide.map((num, index) => {
      if (
        num === 0 &&
        sumN2ConcenRight[index] === 0 &&
        h2oConcenRightSide[index] === 0 &&
        o2ConcenRightSide[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (num * 44 +
            sumN2ConcenRight[index] * 28 +
            h2oConcenRightSide[index] * 18 +
            o2ConcenRightSide[index] * 32) /
          100,
          2
        );
      }
    });

    const totalCO2PercentMassRight = co2ConcenRightSide.map((num, index) => {
      if (num === 0 && calFumesCompMRight[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 44) / calFumesCompMRight[index]) * 100,
          1
        );
      }
    });

    const totalH2OPercentMassRight = h2oConcenRightSide.map((num, index) => {
      if (num === 0 && calFumesCompMRight[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 18) / calFumesCompMRight[index]) * 100,
          1
        );
      }
    });

    const totalN2PercentMassRight = sumN2ConcenRight.map((num, index) => {
      if (num === 0 && calFumesCompMRight[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 28) / calFumesCompMRight[index]) * 100,
          1
        );
      }
    });

    const totalO2PercentMassRight = o2ConcenRightSide.map((num, index) => {
      if (num === 0 && calFumesCompMRight[index] === 0) {
        return 0;
      } else {
        return roundUp(
          (((num / 100) * 32) / calFumesCompMRight[index]) * 100,
          1
        );
      }
    });
    const corrALeft = totalCO2PercentMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.A +
          roundUp(totalN2PercentMassLeft[index] / 100, 3) *
          tmpCorrelation.n2.A +
          (totalH2OPercentMassLeft[index] / 100) * tmpCorrelation.h2o.A +
          (totalO2PercentMassLeft[index] / 100) * tmpCorrelation.o2.A,
          3
        );
      }
    });
    const corrARight = totalCO2PercentMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.A +
          roundUp(totalN2PercentMassRight[index] / 100, 3) *
          tmpCorrelation.n2.A +
          (totalH2OPercentMassRight[index] / 100) * tmpCorrelation.h2o.A +
          (totalO2PercentMassRight[index] / 100) * tmpCorrelation.o2.A,
          3
        );
      }
    });
    const corrBLeft = totalCO2PercentMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.B +
          roundUp(totalN2PercentMassLeft[index] / 100, 3) *
          tmpCorrelation.n2.B +
          (totalH2OPercentMassLeft[index] / 100) * tmpCorrelation.h2o.B +
          (totalO2PercentMassLeft[index] / 100) * tmpCorrelation.o2.B,
          3
        );
      }
    });
    const corrBRight = totalCO2PercentMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.B +
          roundUp(totalN2PercentMassRight[index] / 100, 3) *
          tmpCorrelation.n2.B +
          (totalH2OPercentMassRight[index] / 100) * tmpCorrelation.h2o.B +
          (totalO2PercentMassRight[index] / 100) * tmpCorrelation.o2.B,
          3
        );
      }
    });
    const corrCLeft = totalCO2PercentMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * Number(tmpCorrelation.co2.C) +
          roundUp(totalN2PercentMassLeft[index] / 100, 3) *
          Number(tmpCorrelation.n2.C) +
          (totalH2OPercentMassLeft[index] / 100) *
          Number(tmpCorrelation.h2o.C) +
          (totalO2PercentMassLeft[index] / 100) * Number(tmpCorrelation.o2.C),
          7
        );
      }
    });
    const corrCRight = totalCO2PercentMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * Number(tmpCorrelation.co2.C) +
          roundUp(totalN2PercentMassRight[index] / 100, 3) *
          Number(tmpCorrelation.n2.C) +
          (totalH2OPercentMassRight[index] / 100) *
          Number(tmpCorrelation.h2o.C) +
          (totalO2PercentMassRight[index] / 100) *
          Number(tmpCorrelation.o2.C),
          7
        );
      }
    });
    const corrDLeft = totalCO2PercentMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          (val / 100) * Number(tmpCorrelation.co2.D) +
          roundUp(totalN2PercentMassLeft[index] / 100, 3) *
          Number(tmpCorrelation.n2.D) +
          (totalH2OPercentMassLeft[index] / 100) *
          Number(tmpCorrelation.h2o.D) +
          (totalO2PercentMassLeft[index] / 100) * Number(tmpCorrelation.o2.D)
        );
      }
    });
    const corrDRight = totalCO2PercentMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          (val / 100) * Number(tmpCorrelation.co2.D) +
          roundUp(totalN2PercentMassRight[index] / 100, 3) *
          Number(tmpCorrelation.n2.D) +
          (totalH2OPercentMassRight[index] / 100) *
          Number(tmpCorrelation.h2o.D) +
          (totalO2PercentMassRight[index] / 100) * Number(tmpCorrelation.o2.D)
        );
      }
    });

    //Efficiencies Correlation Calculated Fumes Correlation (Right side of Excel sheet)
    const corrA1Left = totalCalCO2PercentageMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.A +
          roundUp(totalCalN2PercentageMassLeft[index] / 100, 3) *
          tmpCorrelation.n2.A +
          (totalCalH2OPercentageMassLeft[index] / 100) *
          tmpCorrelation.h2o.A +
          (totalCalO2PercentageMassLeft[index] / 100) * tmpCorrelation.o2.A,
          2
        );
      }
    });
    console.log(corrA1Left, "corrA1LeftcorrA1Left")
    const corrA1Right = totalCalCO2PercentageMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.A +
          roundUp(totalCalN2PercentageMassRight[index] / 100, 3) *
          tmpCorrelation.n2.A +
          (totalCalH2OPercentageMassRight[index] / 100) *
          tmpCorrelation.h2o.A +
          (totalCalO2PercentageMassRight[index] / 100) * tmpCorrelation.o2.A,
          2
        );
      }
    });
    console.log(corrA1Right, "corrA1RightcorrA1Right")
    const corrB1Left = totalCalCO2PercentageMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.B +
          roundUp(totalCalN2PercentageMassLeft[index] / 100, 3) *
          tmpCorrelation.n2.B +
          (totalCalH2OPercentageMassLeft[index] / 100) *
          tmpCorrelation.h2o.B +
          (totalCalO2PercentageMassLeft[index] / 100) * tmpCorrelation.o2.B,
          2
        );
      }
    });
    const corrB1Right = totalCalCO2PercentageMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * tmpCorrelation.co2.B +
          roundUp(totalCalN2PercentageMassRight[index] / 100, 3) *
          tmpCorrelation.n2.B +
          (totalCalH2OPercentageMassRight[index] / 100) *
          tmpCorrelation.h2o.B +
          (totalCalO2PercentageMassRight[index] / 100) * tmpCorrelation.o2.B,
          2
        );
      }
    });
    const corrC1Left = totalCalCO2PercentageMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * Number(tmpCorrelation.co2.C) +
          roundUp(totalCalN2PercentageMassLeft[index] / 100, 3) *
          Number(tmpCorrelation.n2.C) +
          (totalCalH2OPercentageMassLeft[index] / 100) *
          Number(tmpCorrelation.h2o.C) +
          (totalCalO2PercentageMassLeft[index] / 100) *
          Number(tmpCorrelation.o2.C),
          7
        );
      }
    });
    const corrC1Right = totalCalCO2PercentageMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val / 100) * Number(tmpCorrelation.co2.C) +
          roundUp(totalCalN2PercentageMassRight[index] / 100, 3) *
          Number(tmpCorrelation.n2.C) +
          (totalCalH2OPercentageMassRight[index] / 100) *
          Number(tmpCorrelation.h2o.C) +
          (totalCalO2PercentageMassRight[index] / 100) *
          Number(tmpCorrelation.o2.C),
          7
        );
      }
    });
    const corrD1Left = totalCalCO2PercentageMassLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          (val / 100) * Number(tmpCorrelation.co2.D) +
          roundUp(totalCalN2PercentageMassLeft[index] / 100, 3) *
          Number(tmpCorrelation.n2.D) +
          (totalCalH2OPercentageMassLeft[index] / 100) *
          Number(tmpCorrelation.h2o.D) +
          (totalCalO2PercentageMassLeft[index] / 100) *
          Number(tmpCorrelation.o2.D)
        );
      }
    });
    const corrD1Right = totalCalCO2PercentageMassRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          (val / 100) * Number(tmpCorrelation.co2.D) +
          roundUp(totalCalN2PercentageMassRight[index] / 100, 3) *
          Number(tmpCorrelation.n2.D) +
          (totalCalH2OPercentageMassRight[index] / 100) *
          Number(tmpCorrelation.h2o.D) +
          (totalCalO2PercentageMassRight[index] / 100) *
          Number(tmpCorrelation.o2.D)
        );
      }
    });

    //Efficiencies Measured Fumes Correlation (Left side of Excel sheet)
    const tempAirFumesTemp = get(temparatures);
    const power = Math.pow;
    //Cp Air (J/kg.°K) at TSA  //Top Preheated Air left data
    const effTopPreheatedAirLeft =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tempAirFumesTemp.airTemperatureCFF.top,
          tempAirFumesTemp.airTemperatureEFF.top
        )
        : commonFunction(
          "left",
          tempAirFumesTemp.airTemperatureCFF.top,
          tempAirFumesTemp.airTemperatureEFF.top
        );
    //Top Preheated Air right data
    const effTopPreheatedAirRight =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tempAirFumesTemp.airTemperatureCFF.top,
          tempAirFumesTemp.airTemperatureEFF.top
        )
        : commonFunction(
          "right",
          tempAirFumesTemp.airTemperatureCFF.top,
          tempAirFumesTemp.airTemperatureEFF.top
        );
    let topPreheatedAirLeftSide = [];
    effTopPreheatedAirLeft.map((data) => {
      if (data) {
        topPreheatedAirLeftSide.push(Number(data));
      }
    });
    let topPreheatedAirRightSide = [];
    effTopPreheatedAirRight.map((data) => {
      if (data) {
        topPreheatedAirRightSide.push(Number(data));
      }
    });
    const cpAirTSALeft = topPreheatedAirLeftSide.map((val) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          Number(tmpCorrelation.airCorrelation.A) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.B) * (val + 273.15),
            5
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.C) * power(val + 273.15, 2),
            12
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.D) * power(val + 273.15, 3),
            14
          ),
          2
        );
      }
    });
    const cpAirTSARight = topPreheatedAirRightSide.map((val) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          Number(tmpCorrelation.airCorrelation.A) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.B) * (val + 273.15),
            5
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.C) * power(val + 273.15, 2),
            12
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.D) * power(val + 273.15, 3),
            14
          ),
          2
        );
      }
    });
    //Left Side
    let topPreheatedAirLeftAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyPreHeatLeft = topPreheatedAirLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topPreheatedAirLeftSum4 = nonEmptyPreHeatLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirLeftAverage1 = functionForNullCheck("roundup",
        topPreheatedAirLeftSum4, nonEmptyPreHeatLeft,
        2
      );
    } else {
      let topPreheatedAirLeftSum5 = topPreheatedAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topPreheatedAirRightSum4 = topPreheatedAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirLeftAverage1 = roundUp(
        (topPreheatedAirLeftSum5 + topPreheatedAirRightSum4) / 2,
        2
      );
    }
    //Right Side
    let topPreheatedAirRightAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyPreHeatRight = topPreheatedAirRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topPreheatedAirRightSum5 = nonEmptyPreHeatRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirRightAverage1 = functionForNullCheck("roundup",
        topPreheatedAirRightSum5, nonEmptyPreHeatRight,
        2
      );
    } else {
      let topPreheatedAirLeftSum6 = topPreheatedAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topPreheatedAirRightSum6 = topPreheatedAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topPreheatedAirRightAverage1 = roundUp(
        (topPreheatedAirLeftSum6 + topPreheatedAirRightSum6) / 2,
        2
      );
    }
    const totalCPAirTSALeft = roundUp(
      Number(tmpCorrelation.airCorrelation.A) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.B) *
        (topPreheatedAirLeftAverage1 + 273.15),
        5
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.C) *
        power((topPreheatedAirLeftAverage1 + 273.15), 2),
        12
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.D) *
        power((topPreheatedAirLeftAverage1 + 273.15), 3),
        14
      ),
      1
    );
    const totalCPAirTSARight = roundUp(
      Number(tmpCorrelation.airCorrelation.A) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.B) *
        (topPreheatedAirRightAverage1 + 273.15),
        5
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.C) *
        power((topPreheatedAirRightAverage1 + 273.15), 2),
        12
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.D) *
        power((topPreheatedAirRightAverage1 + 273.15), 3),
        14
      ),
      1
    );

    //Cp Air (KJ/kg.°K) at TEA  //Bottom cold air left data
    const effBottomColdAirLeft =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tempAirFumesTemp.airTemperatureCFF.bottom,
          tempAirFumesTemp.airTemperatureEFF.bottom
        )
        : commonFunction(
          "left",
          tempAirFumesTemp.airTemperatureCFF.bottom,
          tempAirFumesTemp.airTemperatureEFF.bottom
        );
    //Bottom cold air right data
    const effBottomColdAirRight =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tempAirFumesTemp.airTemperatureCFF.bottom,
          tempAirFumesTemp.airTemperatureEFF.bottom
        )
        : commonFunction(
          "right",
          tempAirFumesTemp.airTemperatureCFF.bottom,
          tempAirFumesTemp.airTemperatureEFF.bottom
        );
    let bottomColdAirLeftSide = [];
    effBottomColdAirLeft.map((data) => {
      if (data) {
        bottomColdAirLeftSide.push(Number(data));
      }
    });
    let bottomColdAirRightSide = [];
    effBottomColdAirRight.map((data) => {
      if (data) {
        bottomColdAirRightSide.push(Number(data));
      }
    });
    const cpAirTEALeft = bottomColdAirLeftSide.map((val) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          Number(tmpCorrelation.airCorrelation.A) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.B) * (val + 273.15),
            5
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.C) * power(val + 273.15, 2),
            12
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.D) * power(val + 273.15, 3),
            14
          ),
          2
        );
      }
    });
    const cpAirTEARight = bottomColdAirRightSide.map((val) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          Number(tmpCorrelation.airCorrelation.A) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.B) * (val + 273.15),
            5
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.C) * power(val + 273.15, 2),
            12
          ) +
          roundUp(
            Number(tmpCorrelation.airCorrelation.D) * power(val + 273.15, 3),
            14
          ),
          2
        );
      }
    });
    //Left Side
    let bottomColdAirLeftAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyBotColdAirLeft = bottomColdAirLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdAirLeftSum4 = nonEmptyBotColdAirLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirLeftAverage1 = functionForNullCheck("roundup",
        bottomColdAirLeftSum4, nonEmptyBotColdAirLeft,
        2
      );
    } else {
      let bottomColdAirLeftSum5 = bottomColdAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdAirRightSum4 = bottomColdAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirLeftAverage1 = roundUp(
        (bottomColdAirLeftSum5 + bottomColdAirRightSum4) / 2,
        2
      );
    }
    //Right Side
    let bottomColdAirRightAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyBotColdAirRight = bottomColdAirRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdAirRightSum5 = nonEmptyBotColdAirRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirRightAverage1 = functionForNullCheck("roundup",
        bottomColdAirRightSum5, nonEmptyBotColdAirRight,
        2
      );
    } else {
      let bottomColdAirLeftSum6 = bottomColdAirLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdAirRightSum6 = bottomColdAirRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdAirRightAverage1 = roundUp(
        (bottomColdAirLeftSum6 + bottomColdAirRightSum6) / 2,
        2
      );
    }
    const totalCPAirTEALeft = roundUp(
      Number(tmpCorrelation.airCorrelation.A) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.B) *
        (bottomColdAirLeftAverage1 + 273.15),
        5
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.C) *
        power(bottomColdAirLeftAverage1 + 273.15, 2),
        12
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.D) *
        power(bottomColdAirLeftAverage1 + 273.15, 3),
        14
      ),
      1
    );
    const totalCPAirTEARight = roundUp(
      Number(tmpCorrelation.airCorrelation.A) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.B) *
        (bottomColdAirRightAverage1 + 273.15),
        5
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.C) *
        power(bottomColdAirRightAverage1 + 273.15, 2),
        12
      ) +
      roundUp(
        Number(tmpCorrelation.airCorrelation.D) *
        power(bottomColdAirRightAverage1 + 273.15, 3),
        14
      ),
      1
    );

    //Cp Fumes (kJ/kg.°K) at TEF(Measured Fumes Correlation)  //Top hot fumes left data
    const effTopHotFumesLeft =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tempAirFumesTemp.fumesTemperatureCFF.top,
          tempAirFumesTemp.fumesTemperatureEFF.top
        )
        : commonFunction(
          "left",
          tempAirFumesTemp.fumesTemperatureCFF.top,
          tempAirFumesTemp.fumesTemperatureEFF.top
        );
    //Top hot fumes right data
    const effTopHotFumesRight =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tempAirFumesTemp.fumesTemperatureCFF.top,
          tempAirFumesTemp.fumesTemperatureEFF.top
        )
        : commonFunction(
          "right",
          tempAirFumesTemp.fumesTemperatureCFF.top,
          tempAirFumesTemp.fumesTemperatureEFF.top
        );
    let topHotFumesLeftSide = [];
    effTopHotFumesLeft.map((data) => {
      if (data) {
        topHotFumesLeftSide.push(Number(data));
      }
    });
    let topHotFumesRightSide = [];
    effTopHotFumesRight.map((data) => {
      if (data) {
        topHotFumesRightSide.push(Number(data));
      }
    });
    const cpFumesTEFLeft = topHotFumesLeftSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrALeft[index] +
          roundUp(corrBLeft[index] * (val + 273.15), 5) +
          roundUp(corrCLeft[index] * power(val + 273.15, 2), 12) +
          roundUp(corrDLeft[index] * power(val + 273.15, 3), 14),
          2
        );
      }
    });
    const cpFumesTEFRight = topHotFumesRightSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrARight[index] +
          roundUp(corrBRight[index] * (val + 273.15), 5) +
          roundUp(corrCRight[index] * power(val + 273.15, 2), 12) +
          roundUp(corrDRight[index] * power(val + 273.15, 3), 14),
          2
        );
      }
    });
    //Left Side
    let topHotFumesLeftAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyHotFumesLeft = topHotFumesLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topHotFumesLeftSum4 = nonEmptyHotFumesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesLeftAverage1 = functionForNullCheck("roundup",
        topHotFumesLeftSum4, nonEmptyHotFumesLeft,
        2
      );
    } else {
      let topHotFumesRightSum4 = topHotFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topHotFumesLeftSum5 = topHotFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesLeftAverage1 = roundUp(
        (topHotFumesRightSum4 + topHotFumesLeftSum5) / 2,
        2
      );
    }
    //Right Side
    let topHotFumesRightAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyHotFumesRight = topHotFumesRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let topHotFumesRightSum5 = nonEmptyHotFumesRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesRightAverage1 = functionForNullCheck("roundup",
        topHotFumesRightSum5, nonEmptyHotFumesRight,
        2
      );
    } else {
      let topHotFumesLeftSum6 = topHotFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let topHotFumesRightSum6 = topHotFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      topHotFumesRightAverage1 = roundUp(
        (topHotFumesLeftSum6 + topHotFumesRightSum6) / 2,
        2
      );
    }

    //Bottom cold fumes left data
    const bottomColdFumesLeft =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "left",
          tempAirFumesTemp.fumesTemperatureCFF.bottom,
          tempAirFumesTemp.fumesTemperatureEFF.bottom
        )
        : commonFunction(
          "left",
          tempAirFumesTemp.fumesTemperatureCFF.bottom,
          tempAirFumesTemp.fumesTemperatureEFF.bottom
        );
    //Bottom cold fumes right data
    const bottomColdFumesRight =
      customerDet.furnaceType === "Cross Fired"
        ? commonFunction(
          "right",
          tempAirFumesTemp.fumesTemperatureCFF.bottom,
          tempAirFumesTemp.fumesTemperatureEFF.bottom
        )
        : commonFunction(
          "right",
          tempAirFumesTemp.fumesTemperatureCFF.bottom,
          tempAirFumesTemp.fumesTemperatureEFF.bottom
        );
    let bottomColdFumesLeftSide = [];
    bottomColdFumesLeft.map((data) => {
      if (data) {
        bottomColdFumesLeftSide.push(Number(data));
      }
    });
    let bottomColdFumesRightSide = [];
    bottomColdFumesRight.map((data) => {
      if (data) {
        bottomColdFumesRightSide.push(Number(data));
      }
    });
    //Left Side
    let bottomColdFumesLeftAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyBotColdFumesLeft = bottomColdFumesLeftSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdFumesLeftSum4 = nonEmptyBotColdFumesLeft.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesLeftAverage1 = functionForNullCheck("roundup",
        bottomColdFumesLeftSum4, nonEmptyBotColdFumesLeft,
        2
      );
    } else {
      let bottomColdFumesLeftSum5 = bottomColdFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdFumesRightSum4 = bottomColdFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesLeftAverage1 = roundUp(
        (bottomColdFumesLeftSum5 + bottomColdFumesRightSum4) / 2,
        2
      );
    }
    //Right Side
    let bottomColdFumesRightAverage1 = [];
    if (customerDet.furnaceType === "Cross Fired") {
      let nonEmptyBotColdFumesRight = bottomColdFumesRightSide
        .map(Number)   // Convert string values to numbers
        .filter(val => val !== 0 && val !== '');
      let bottomColdFumesRightSum5 = nonEmptyBotColdFumesRight.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesRightAverage1 = functionForNullCheck("roundup",
        bottomColdFumesRightSum5, nonEmptyBotColdFumesRight,
        2
      );
    } else {
      let bottomColdFumesLeftSum6 = bottomColdFumesLeftSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      let bottomColdFumesRightSum6 = bottomColdFumesRightSide.reduce(
        (acc, cur) => acc + cur,
        0
      );
      bottomColdFumesRightAverage1 = roundUp(
        (bottomColdFumesLeftSum6 + bottomColdFumesRightSum6) / 2,
        2
      );
    }

    const filterEffCorrASumLeft = corrALeft.filter(value => value !== 0 && value !== '');
    const effCorrASumLeft = filterEffCorrASumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrAAvgLeft = functionForNullCheck("calc", effCorrASumLeft, filterEffCorrASumLeft);
    const filterEffCorrBSumLeft = corrBLeft.filter(value => value !== 0 && value !== '');
    const effCorrBSumLeft = filterEffCorrBSumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrBAvgLeft = functionForNullCheck("calc", effCorrBSumLeft, filterEffCorrBSumLeft);
    const filterEffCorrCSumLeft = corrCLeft.filter(value => value !== 0 && value !== '');
    const effCorrCSumLeft = filterEffCorrCSumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrCAvgLeft = functionForNullCheck("calc", effCorrCSumLeft, filterEffCorrCSumLeft);
    const filterEffCorrDSumLeft = corrDLeft.filter(value => value !== 0 && value !== '');
    const effCorrDSumLeft = filterEffCorrDSumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrDAvgLeft = functionForNullCheck("calc", effCorrDSumLeft, filterEffCorrDSumLeft);
    const totalCPFumesTEFLeft = roundUp(
      effCorrAAvgLeft +
      effCorrBAvgLeft * (topHotFumesLeftAverage1 + 273.15) +
      effCorrCAvgLeft * power(topHotFumesLeftAverage1 + 273.15, 2) +
      effCorrDAvgLeft * power(topHotFumesLeftAverage1 + 273.15, 3),
      3
    );
    const filterEffCorrASumRight = corrARight.filter(value => value !== 0 && value !== '');
    const effCorrASumRight = filterEffCorrASumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrAAvgRight = functionForNullCheck("calc", effCorrASumRight, filterEffCorrASumRight);
    const filterEffCorrBSumRight = corrBRight.filter(value => value !== 0 && value !== '');
    const effCorrBSumRight = filterEffCorrBSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrBAvgRight = functionForNullCheck("calc", effCorrBSumRight, filterEffCorrBSumRight);
    const filterEffCorrCSumRight = corrCRight.filter(value => value !== 0 && value !== '');
    const effCorrCSumRight = filterEffCorrCSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrCAvgRight = functionForNullCheck("calc", effCorrCSumRight, filterEffCorrCSumRight);
    const filterEffCorrDSumRight = corrDRight.filter(value => value !== 0 && value !== '');
    const effCorrDSumRight = filterEffCorrDSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrDAvgRight = functionForNullCheck("calc", effCorrDSumRight, filterEffCorrDSumRight);
    const totalCPFumesTEFRight = roundUp(
      effCorrAAvgRight +
      effCorrBAvgRight * (topHotFumesRightAverage1 + 273.15) +
      effCorrCAvgRight * power(topHotFumesRightAverage1 + 273.15, 2) +
      effCorrDAvgRight * power(topHotFumesRightAverage1 + 273.15, 3),
      3
    );

    //Cp Fumes (kJ/kg.°K) at TEA(Measured Fumes Correlation)
    const cpFumesTEALeft = bottomColdAirLeftSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrALeft[index] +
          roundUp(corrBLeft[index] * (val + 273.15), 5) +
          roundUp(corrCLeft[index] * power(val + 273.15, 2), 12) +
          roundUp(corrDLeft[index] * power(val + 273.15, 3), 14),
          2
        );
      }
    });
    const cpFumesTEARight = bottomColdAirRightSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrARight[index] +
          roundUp(corrBRight[index] * (val + 273.15), 5) +
          roundUp(corrCRight[index] * power(val + 273.15, 2), 12) +
          roundUp(corrDRight[index] * power(val + 273.15, 3), 14),
          2
        );
      }
    });
    const totalCPFumesTEALeft = roundUp(
      effCorrAAvgLeft +
      effCorrBAvgLeft * (bottomColdAirLeftAverage1 + 273.15) +
      effCorrCAvgLeft * power(bottomColdAirLeftAverage1 + 273.15, 2) +
      effCorrDAvgLeft * power(bottomColdAirLeftAverage1 + 273.15, 3),
      3
    );
    const totalCPFumesTEARight = roundUp(
      effCorrAAvgRight +
      effCorrBAvgRight * (bottomColdAirRightAverage1 + 273.15) +
      effCorrCAvgRight * power(bottomColdAirRightAverage1 + 273.15, 2) +
      effCorrDAvgRight * power(bottomColdAirRightAverage1 + 273.15, 3),
      3
    );

    //Gained Air Energy (Wh)
    const gainedAirEnergyLeft = flowsDATDanDexaLeft.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topPreheatedAirLeftSide[index] +
            273.15 -
            (bottomColdAirLeftSide[index] + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 2) -
            power(bottomColdAirLeftSide[index] + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 3) -
            power(bottomColdAirLeftSide[index] + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 4) -
            power(bottomColdAirLeftSide[index] + 273.15, 4))),
        2
      );
    });
    const gainedAirEnergyRight = flowsDATDanDexaRight.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topPreheatedAirRightSide[index] +
            273.15 -
            (bottomColdAirRightSide[index] + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topPreheatedAirRightSide[index] + 273.15, 2) -
            power(bottomColdAirRightSide[index] + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topPreheatedAirRightSide[index] + 273.15, 3) -
            power(bottomColdAirRightSide[index] + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topPreheatedAirRightSide[index] + 273.15, 4) -
            power(bottomColdAirRightSide[index] + 273.15, 4))),
        2
      );
    });

    //Total Left of Gained Air Energy (Wh)
    const totalGainedAirEnergyLeft = roundUp(
      flowsTotalDATDanDexaLeft *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topPreheatedAirLeftAverage1 +
          273.15 -
          (bottomColdAirLeftAverage1 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 2) -
          power(bottomColdAirLeftAverage1 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 3) -
          power(bottomColdAirLeftAverage1 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 4) -
          power(bottomColdAirLeftAverage1 + 273.15, 4))),
      2
    );

    //Total Right of Gained Air Energy (Wh)
    const totalGainedAirEnergyRight = roundUp(
      flowsTotalDATDanDexaRight *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topPreheatedAirRightAverage1 +
          273.15 -
          (bottomColdAirRightAverage1 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topPreheatedAirRightAverage1 + 273.15, 2) -
          power(bottomColdAirRightAverage1 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topPreheatedAirRightAverage1 + 273.15, 3) -
          power(bottomColdAirRightAverage1 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topPreheatedAirRightAverage1 + 273.15, 4) -
          power(bottomColdAirRightAverage1 + 273.15, 4))),
      2
    );

    //Available Air Energy (Wh)
    const availableAirEnLeft = flowsDATDanDexaLeft.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topHotFumesLeftSide[index] +
            273.15 -
            (bottomColdAirLeftSide[index] + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topHotFumesLeftSide[index] + 273.15, 2) -
            power(bottomColdAirLeftSide[index] + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topHotFumesLeftSide[index] + 273.15, 3) -
            power(bottomColdAirLeftSide[index] + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topHotFumesLeftSide[index] + 273.15, 4) -
            power(bottomColdAirLeftSide[index] + 273.15, 4))),
        2
      );
    });
    const availableAirEnRight = flowsDATDanDexaRight.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topHotFumesRightSide[index] +
            273.15 -
            (bottomColdAirRightSide[index] + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topHotFumesRightSide[index] + 273.15, 2) -
            power(bottomColdAirRightSide[index] + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topHotFumesRightSide[index] + 273.15, 3) -
            power(bottomColdAirRightSide[index] + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topHotFumesRightSide[index] + 273.15, 4) -
            power(bottomColdAirRightSide[index] + 273.15, 4))),
        2
      );
    });

    //Total Left of Available Air Energy (Wh)
    const totalAvailableAirEnergyLeft = roundUp(
      flowsTotalDATDanDexaLeft *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topHotFumesLeftAverage1 +
          273.15 -
          (bottomColdAirLeftAverage1 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topHotFumesLeftAverage1 + 273.15, 2) -
          power(bottomColdAirLeftAverage1 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topHotFumesLeftAverage1 + 273.15, 3) -
          power(bottomColdAirLeftAverage1 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topHotFumesLeftAverage1 + 273.15, 4) -
          power(bottomColdAirLeftAverage1 + 273.15, 4))),
      2
    );

    //Total Right of Available Air Energy (Wh)
    const totalAvailableAirEnergyRight = roundUp(
      flowsTotalDATDanDexaRight *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topHotFumesRightAverage1 +
          273.15 -
          (bottomColdAirRightAverage1 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topHotFumesRightAverage1 + 273.15, 2) -
          power(bottomColdAirRightAverage1 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topHotFumesRightAverage1 + 273.15, 3) -
          power(bottomColdAirRightAverage1 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topHotFumesRightAverage1 + 273.15, 4) -
          power(bottomColdAirRightAverage1 + 273.15, 4))),
      2
    );

    //Furnace Air Energy (Wh)
    const furnaceAirEnLeft = flowsDATDanDexaLeft.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topPreheatedAirLeftSide[index] + 273.15 - (25 + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 2) -
            power(25 + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 3) -
            power(25 + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topPreheatedAirLeftSide[index] + 273.15, 4) -
            power(25 + 273.15, 4))),
        2
      );
    });
    const furnaceAirEnRight = flowsDATDanDexaRight.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topPreheatedAirRightSide[index] + 273.15 - (25 + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topPreheatedAirRightSide[index] + 273.15, 2) -
            power(25 + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topPreheatedAirRightSide[index] + 273.15, 3) -
            power(25 + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topPreheatedAirRightSide[index] + 273.15, 4) -
            power(25 + 273.15, 4))),
        2
      );
    });

    //Total Left of Furnace Air Energy (Wh)
    const totalFurnaceAirEnergyLeft = roundUp(
      flowsTotalDATDanDexaLeft *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topPreheatedAirLeftAverage1 + 273.15 - (25 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 2) -
          power(25 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 3) -
          power(25 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topPreheatedAirLeftAverage1 + 273.15, 4) -
          power(25 + 273.15, 4))),
      2
    );

    //Total Right of Furnace Air Energy (Wh)
    const totalFurnaceAirEnergyRight = roundUp(
      flowsTotalDATDanDexaRight *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topPreheatedAirRightAverage1 + 273.15 - (25 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topPreheatedAirRightAverage1 + 273.15, 2) -
          power(25 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topPreheatedAirRightAverage1 + 273.15, 3) -
          power(25 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topPreheatedAirRightAverage1 + 273.15, 4) -
          power(25 + 273.15, 4))),
      2
    );

    //Max Furnace Air Energy (Wh)
    const maxFurnaceAirEnLeft = flowsDATDanDexaLeft.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topHotFumesLeftSide[index] + 273.15 - (25 + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topHotFumesLeftSide[index] + 273.15, 2) -
            power(25 + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topHotFumesLeftSide[index] + 273.15, 3) -
            power(25 + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topHotFumesLeftSide[index] + 273.15, 4) -
            power(25 + 273.15, 4))),
        2
      );
    });
    const maxFurnaceAirEnRight = flowsDATDanDexaRight.map((val, index) => {
      return roundUp(
        val *
        1.29 *
        (Number(tmpCorrelation.airCorrelation.A) *
          (topHotFumesRightSide[index] + 273.15 - (25 + 273.15)) +
          Number(tmpCorrelation.airCorrelation.B / 2) *
          (power(topHotFumesRightSide[index] + 273.15, 2) -
            power(25 + 273.15, 2)) +
          Number(tmpCorrelation.airCorrelation.C / 3) *
          (power(topHotFumesRightSide[index] + 273.15, 3) -
            power(25 + 273.15, 3)) +
          Number(tmpCorrelation.airCorrelation.D / 4) *
          (power(topHotFumesRightSide[index] + 273.15, 4) -
            power(25 + 273.15, 4))),
        2
      );
    });

    //Total Left of Max Furnace Air Energy (Wh)
    const totalMaxFurnaceAirEnergyLeft = roundUp(
      flowsTotalDATDanDexaLeft *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topHotFumesLeftAverage1 + 273.15 - (25 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topHotFumesLeftAverage1 + 273.15, 2) -
          power(25 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topHotFumesLeftAverage1 + 273.15, 3) -
          power(25 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topHotFumesLeftAverage1 + 273.15, 4) -
          power(25 + 273.15, 4))),
      2
    );

    //Total Right of Max Furnace Air Energy (Wh)
    const totalMaxFurnaceAirEnergyRight = roundUp(
      flowsTotalDATDanDexaRight *
      1.29 *
      (Number(tmpCorrelation.airCorrelation.A) *
        (topHotFumesRightAverage1 + 273.15 - (25 + 273.15)) +
        Number(tmpCorrelation.airCorrelation.B / 2) *
        (power(topHotFumesRightAverage1 + 273.15, 2) -
          power(25 + 273.15, 2)) +
        Number(tmpCorrelation.airCorrelation.C / 3) *
        (power(topHotFumesRightAverage1 + 273.15, 3) -
          power(25 + 273.15, 3)) +
        Number(tmpCorrelation.airCorrelation.D / 4) *
        (power(topHotFumesRightAverage1 + 273.15, 4) -
          power(25 + 273.15, 4))),
      2
    );

    //Gaz&Fuel Energy (Wh)
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpFuelLeftSide2.length === 0) {
        consumpFuelLeftSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpFuelLeftSide2.length === 0) {
        consumpFuelLeftSide2 = [0];
      }
    }
    const gazFuelEnerLeft = consumpFuelLeftSide2.map((val, index) => {
      let fuelLeftSide = (typeof val !== 'undefined') ? val : 0;
      return (
        sumCombustionHeatLHV * 3600000 * fuelLeftSide +
        totalCombustionHeatLHV * 3600000 * consumpGasLeftSide2[index]
      );
    });
    const gazFuelEnergyLeft = gazFuelEnerLeft.filter(value => !isNaN(value));
    console.log(gazFuelEnergyLeft, "gazFuelEnergyLeft");
    if (custoDetails.furnaceType === "Cross Fired") {
      if (consumpFuelRightSide2.length === 0) {
        consumpFuelRightSide2 = [0, 0, 0, 0, 0, 0, 0, 0];
      }
    } else {
      if (consumpFuelRightSide2.length === 0) {
        consumpFuelRightSide2 = [0];
      }
    }
    const gazFuelEnerRight = consumpFuelRightSide2.map((val, index) => {
      return (
        sumCombustionHeatLHV * 3600000 * val +
        totalCombustionHeatLHV * 3600000 * consumpGasRightSide2[index]
      );
    });
    const gazFuelEnergyRight = gazFuelEnerRight.filter(value => !isNaN(value));

    //Total Left Gaz&Fuel Energy (Wh)
    const tempConsumption = get(fuelInfo);
    const consumptionFuelLeft = commonFunction(
      "left",
      tempConsumption.oil.consumptionCFF,
      tempConsumption.oil.consumptionEFF
    );
    let consumpFuelLeftSide3 = [];
    consumptionFuelLeft.map((data) => {
      if (data) {
        consumpFuelLeftSide3.push(Number(data));
      }
    });
    let sumConsumpFuelLeftSide2 = getSumVal(consumpFuelLeftSide3);
    const consumptionGasLeft = commonFunction(
      "left",
      tempConsumption.gaz.consumptionCFF,
      tempConsumption.gaz.consumptionEFF
    );
    let consumpGasLeftSide3 = [];
    consumptionGasLeft.map((data) => {
      if (data) {
        consumpGasLeftSide3.push(Number(data));
      }
    });
    const sumConsumpGasLeftSide = getSumVal(consumpGasLeftSide3);
    const totalGazFuelEnergyLeft =
      sumCombustionHeatLHV * 3600000 * sumConsumpFuelLeftSide2 +
      totalCombustionHeatLHV * 3600000 * sumConsumpGasLeftSide;

    //Total Right Gaz&Fuel Energy (Wh)
    const consumptionFuelRight = commonFunction(
      "right",
      tempConsumption.oil.consumptionCFF,
      tempConsumption.oil.consumptionEFF
    );
    let consumpFuelRightSide3 = [];
    consumptionFuelRight.map((data) => {
      if (data) {
        consumpFuelRightSide3.push(Number(data));
      }
    });
    let sumConsumpFuelLeftSide3 = getSumVal(consumpFuelRightSide3);
    const consumptionGasRight = commonFunction(
      "right",
      tempConsumption.gaz.consumptionCFF,
      tempConsumption.gaz.consumptionEFF
    );
    let consumpGasRightSide3 = [];
    consumptionGasRight.map((data) => {
      if (data) {
        consumpGasRightSide3.push(Number(data));
      }
    });
    const sumConsumpGasRightSide = getSumVal(consumpGasRightSide3);
    const totalGazFuelEnergyRight =
      sumCombustionHeatLHV * 3600000 * sumConsumpFuelLeftSide3 +
      totalCombustionHeatLHV * 3600000 * sumConsumpGasRightSide;

    //Fumes Energy (Wh)(Measured Fumes Correlation)
    const fumesEnergyLeft = effDFTNm3Left.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          val *
          calFumesComposRLeft[index] *
          (corrALeft[index] *
            (topHotFumesLeftSide[index] +
              273.15 -
              (bottomColdFumesLeftSide[index] + 273.15)) +
            (corrBLeft[index] / 2) *
            (power(topHotFumesLeftSide[index] + 273.15, 2) -
              power(bottomColdFumesLeftSide[index] + 273.15, 2)) +
            (corrCLeft[index] / 3) *
            (power(topHotFumesLeftSide[index] + 273.15, 3) -
              power(bottomColdFumesLeftSide[index] + 273.15, 3)) +
            (corrDLeft[index] / 4) *
            (power(topHotFumesLeftSide[index] + 273.15, 4) -
              power(bottomColdFumesLeftSide[index] + 273.15, 4))),
          2
        );
      }
    });
    const fumesEnergyRight = effDFTNm3Right.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          val *
          calFumesComposRRight[index] *
          (corrARight[index] *
            (topHotFumesRightSide[index] +
              273.15 -
              (bottomColdFumesRightSide[index] + 273.15)) +
            (corrBRight[index] / 2) *
            (power(topHotFumesRightSide[index] + 273.15, 2) -
              power(bottomColdFumesRightSide[index] + 273.15, 2)) +
            (corrCRight[index] / 3) *
            (power(topHotFumesRightSide[index] + 273.15, 3) -
              power(bottomColdFumesRightSide[index] + 273.15, 3)) +
            (corrDRight[index] / 4) *
            (power(topHotFumesRightSide[index] + 273.15, 4) -
              power(bottomColdFumesRightSide[index] + 273.15, 4))),
          2
        );
      }
    });

    //Total Left Fumes Energy (Wh)
    const filterEffRkgm3SumLeft = calFumesComposRLeft.filter(value => value !== 0 && value !== '');
    const effRkgm3SumLeft = filterEffRkgm3SumLeft.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const effRkgm3TotalAvgLeft = functionForNullCheck("calc", effRkgm3SumLeft, filterEffRkgm3SumLeft);
    const totalFumesEnergyLeft = roundUp(
      effTotalDFTNm3Left *
      effRkgm3TotalAvgLeft *
      effCorrAAvgLeft *
      (topHotFumesLeftAverage1 +
        273.15 -
        (bottomColdFumesLeftAverage1 + 273.15)) +
      (effCorrBAvgLeft / 2) *
      (power(topHotFumesLeftAverage1 + 273.15, 2) -
        power(bottomColdFumesLeftAverage1 + 273.15, 2)) +
      (effCorrCAvgLeft / 3) *
      (power(topHotFumesLeftAverage1 + 273.15, 3) -
        power(bottomColdFumesLeftAverage1 + 273.15, 3)) +
      (effCorrDAvgLeft / 4) * (topHotFumesLeftAverage1 + 273.15, 4) -
      power(bottomColdFumesLeftAverage1 + 273.15, 4),
      2
    );

    //Total Right Fumes Energy (Wh)
    const filterEffRkgm3SumRight = calFumesComposRRight.filter(value => value !== 0 && value !== '');
    const effRkgm3SumRight = filterEffRkgm3SumRight.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const effRkgm3TotalAvgRight =
      functionForNullCheck("calc", effRkgm3SumRight, filterEffRkgm3SumRight);
    const totalFumesEnergyRight = roundUp(
      effTotalDFTNm3Right *
      effRkgm3TotalAvgRight *
      effCorrAAvgRight *
      (topHotFumesRightAverage1 +
        273.15 -
        (bottomColdFumesRightAverage1 + 273.15)) +
      (effCorrBAvgRight / 2) *
      (power(topHotFumesRightAverage1 + 273.15, 2) -
        power(bottomColdFumesRightAverage1 + 273.15, 2)) +
      (effCorrCAvgRight / 3) *
      (power(topHotFumesRightAverage1 + 273.15, 3) -
        power(bottomColdFumesRightAverage1 + 273.15, 3)) +
      (effCorrDAvgRight / 4) * (topHotFumesRightAverage1 + 273.15, 4) -
      power(bottomColdFumesRightAverage1 + 273.15, 4),
      2
    );

    //Furnace Fumes Energy (Wh)(Measured Fumes Correlation)
    const furnaceFumesEnLeft = effDFTNm3Left.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          val *
          calFumesComposRLeft[index] *
          (corrALeft[index] *
            (topHotFumesLeftSide[index] + 273.15 - (25 + 273.15)) +
            (corrBLeft[index] / 2) *
            (power(topHotFumesLeftSide[index] + 273.15, 2) -
              power(25 + 273.15, 2)) +
            (corrCLeft[index] / 3) *
            (power(topHotFumesLeftSide[index] + 273.15, 3) -
              power(25 + 273.15, 3)) +
            (corrDLeft[index] / 4) *
            (power(topHotFumesLeftSide[index] + 273.15, 4) -
              power(25 + 273.15, 4))),
          2
        );
      }
    });
    const furnaceFumesEnRight = effDFTNm3Right.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          val *
          calFumesComposRRight[index] *
          (corrARight[index] *
            (topHotFumesRightSide[index] + 273.15 - (25 + 273.15)) +
            (corrBRight[index] / 2) *
            (power(topHotFumesRightSide[index] + 273.15, 2) -
              power(25 + 273.15, 2)) +
            (corrCRight[index] / 3) *
            (power(topHotFumesRightSide[index] + 273.15, 3) -
              power(25 + 273.15, 3)) +
            (corrDRight[index] / 4) *
            (power(topHotFumesRightSide[index] + 273.15, 4) -
              power(25 + 273.15, 4))),
          2
        );
      }
    });

    //Total Left Furnace Fumes Energy (Wh)
    const totalFurnaceFumesEnLeft = roundUp(
      effTotalDFTNm3Left *
      effRkgm3TotalAvgLeft *
      effCorrAAvgLeft *
      (topHotFumesLeftAverage1 + 273.15 - (25 + 273.15)) +
      (effCorrBAvgLeft / 2) *
      (power(topHotFumesLeftAverage1 + 273.15, 2) - power(25 + 273.15, 2)) +
      (effCorrCAvgLeft / 3) *
      (power(topHotFumesLeftAverage1 + 273.15, 3) - power(25 + 273.15, 3)) +
      (effCorrDAvgLeft / 4) * (topHotFumesLeftAverage1 + 273.15, 4) -
      power(25 + 273.15, 4),
      2
    );

    //Total Right Furnace Fumes Energy (Wh)
    const totalFurnaceFumesEnRight = roundUp(
      effTotalDFTNm3Right *
      effRkgm3TotalAvgRight *
      effCorrAAvgRight *
      (topHotFumesRightAverage1 + 273.15 - (25 + 273.15)) +
      (effCorrBAvgRight / 2) *
      (power(topHotFumesRightAverage1 + 273.15, 2) - power(25 + 273.15, 2)) +
      (effCorrCAvgRight / 3) *
      (power(topHotFumesRightAverage1 + 273.15, 3) - power(25 + 273.15, 3)) +
      (effCorrDAvgRight / 4) * (topHotFumesRightAverage1 + 273.15, 4) -
      power(25 + 273.15, 4),
      2
    );

    //Energy Loss (Wh)(Measured Fumes Correlation)
    const energyLossLeft = fumesEnergyLeft.map((val, index) => {
      return roundUp(val - gainedAirEnergyLeft[index], 2);
    });
    const energyLossRight = fumesEnergyRight.map((val, index) => {
      return roundUp(val - gainedAirEnergyRight[index], 2);
    });

    //Total Left Energy Loss (Wh)
    const totalEnergyLossLeft = totalFumesEnergyLeft - totalGainedAirEnergyLeft;

    //Total Right Energy Loss (Wh)
    const totalEnergyLossRight =
      totalFumesEnergyRight - totalGainedAirEnergyRight;

    //Energy Loss%(Measured Fumes Correlation)
    const energyLossPercentLeft = gainedAirEnergyLeft.map((val, index) => {
      const energyLossLeft = roundUp(((1 - val / fumesEnergyLeft[index]) * 100), 2);
      return (isNaN(energyLossLeft) || energyLossLeft === '') ? 0 : energyLossLeft;
    });
    const energyLossPercentRight = gainedAirEnergyRight.map((val, index) => {
      const energyLossRight = roundUp(((1 - val / fumesEnergyRight[index]) * 100), 2);
      return (isNaN(energyLossRight) || energyLossRight === '') ? 0 : energyLossRight;
    });

    //Total Left Energy Loss%
    const totalEnergyLossPercentLeft = roundUp(
      ((1 - (totalGainedAirEnergyLeft / totalFumesEnergyLeft)) * 100), 3);

    //Total Right Energy Loss%
    const totalEnergyLossPercentRight = roundUp(
      ((1 - (totalGainedAirEnergyRight / totalFumesEnergyRight)) * 100), 3);

    //Chamber Efficiency%(Measured Fumes Correlation)
    const chamberEfficiLeft = gainedAirEnergyLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              calFumesComposRLeft[index] *
              (corrALeft[index] *
                (topHotFumesLeftSide[index] +
                  273.15 -
                  (bottomColdAirLeftSide[index] + 273.15)) +
                (corrBLeft[index] / 2) *
                (power(topHotFumesLeftSide[index] + 273.15, 2) -
                  power(bottomColdAirLeftSide[index] + 273.15, 2)) +
                (corrCLeft[index] / 3) *
                (power(topHotFumesLeftSide[index] + 273.15, 3) -
                  power(bottomColdAirLeftSide[index] + 273.15, 3)) +
                (corrDLeft[index] / 4) *
                (power(topHotFumesLeftSide[index] + 273.15, 4) -
                  power(bottomColdAirLeftSide[index] + 273.15, 4)))) * 100),
          2
        );
      }
    });
    const chamberEfficiRight = gainedAirEnergyRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              calFumesComposRRight[index] *
              (corrARight[index] *
                (topHotFumesRightSide[index] +
                  273.15 -
                  (bottomColdAirRightSide[index] + 273.15)) +
                (corrBRight[index] / 2) *
                (power(topHotFumesRightSide[index] + 273.15, 2) -
                  power(bottomColdAirRightSide[index] + 273.15, 2)) +
                (corrCRight[index] / 3) *
                (power(topHotFumesRightSide[index] + 273.15, 3) -
                  power(bottomColdAirRightSide[index] + 273.15, 3)) +
                (corrDRight[index] / 4) *
                (power(topHotFumesRightSide[index] + 273.15, 4) -
                  power(bottomColdAirRightSide[index] + 273.15, 4)))) * 100),
          2
        );
      }
    });

    //Total Left Chamber Efficiency%
    const totalChamberEfficiLeft = roundUp(
      (totalGainedAirEnergyLeft /
        (effTotalDFTNm3Left * effRkgm3TotalAvgLeft *
          (((effCorrAAvgLeft) * ((topHotFumesLeftAverage1 + 273.15) - (bottomColdAirLeftAverage1 + 273.15))) +
            (effCorrBAvgLeft / 2) *
            (power(topHotFumesLeftAverage1 + 273.15, 2) - power(bottomColdAirLeftAverage1 + 273.15, 2)) +
            (effCorrCAvgLeft / 3) *
            (power(topHotFumesLeftAverage1 + 273.15, 3) - power(bottomColdAirLeftAverage1 + 273.15, 3)) +
            (effCorrDAvgLeft / 4) *
            (power(topHotFumesLeftAverage1 + 273.15, 4) - power(bottomColdAirLeftAverage1 + 273.15, 4)))) * 100), 2
    );

    //Total Right Chamber Efficiency%
    const totalChamberEfficiRight = roundUp(
      (totalGainedAirEnergyRight /
        (effTotalDFTNm3Right * effRkgm3TotalAvgRight *
          (((effCorrAAvgRight) * ((topHotFumesRightAverage1 + 273.15) - (bottomColdAirRightAverage1 + 273.15))) +
            (effCorrBAvgRight / 2) *
            (power(topHotFumesRightAverage1 + 273.15, 2) - power(bottomColdAirRightAverage1 + 273.15, 2)) +
            (effCorrCAvgLeft / 3) *
            (power(topHotFumesRightAverage1 + 273.15, 3) - power(bottomColdAirRightAverage1 + 273.15, 3)) +
            (effCorrDAvgLeft / 4) *
            (power(topHotFumesRightAverage1 + 273.15, 4) - power(bottomColdAirRightAverage1 + 273.15, 4)))) * 100), 2
    );

    //Max Chamber Efficiency%(Measured Fumes Correlation)
    const maxChamberEffLeft = availableAirEnLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              calFumesComposRLeft[index] *
              (corrALeft[index] *
                (topHotFumesLeftSide[index] +
                  273.15 -
                  (bottomColdAirLeftSide[index] + 273.15)) +
                (corrBLeft[index] / 2) *
                (power(topHotFumesLeftSide[index] + 273.15, 2) -
                  power(bottomColdAirLeftSide[index] + 273.15, 2)) +
                (corrCLeft[index] / 3) *
                (power(topHotFumesLeftSide[index] + 273.15, 3) -
                  power(bottomColdAirLeftSide[index] + 273.15, 3)) +
                (corrDLeft[index] / 4) *
                (power(topHotFumesLeftSide[index] + 273.15, 4) -
                  power(bottomColdAirLeftSide[index] + 273.15, 4)))) * 100),
          2
        );
      }
    });
    const maxChamberEffRight = availableAirEnRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              calFumesComposRRight[index] *
              (corrARight[index] *
                (topHotFumesRightSide[index] +
                  273.15 -
                  (bottomColdAirRightSide[index] + 273.15)) +
                (corrBLeft[index] / 2) *
                (power(topHotFumesRightSide[index] + 273.15, 2) -
                  power(bottomColdAirRightSide[index] + 273.15, 2)) +
                (corrCLeft[index] / 3) *
                (power(topHotFumesRightSide[index] + 273.15, 3) -
                  power(bottomColdAirRightSide[index] + 273.15, 3)) +
                (corrDLeft[index] / 4) *
                (power(topHotFumesRightSide[index] + 273.15, 4) -
                  power(bottomColdAirRightSide[index] + 273.15, 4)))) * 100),
          2
        );
      }
    });

    //Total Left Max Chamber Efficiency%
    const totalMaxChamberEfficiLeft = roundUp(
      (totalAvailableAirEnergyLeft /
        (effTotalDFTNm3Left * effRkgm3TotalAvgLeft *
          (((effCorrAAvgLeft) * ((topHotFumesLeftAverage1 + 273.15) - (bottomColdAirLeftAverage1 + 273.15))) +
            (effCorrBAvgLeft / 2) *
            (power(topHotFumesLeftAverage1 + 273.15, 2) - power(bottomColdAirLeftAverage1 + 273.15, 2)) +
            (effCorrCAvgLeft / 3) *
            (power(topHotFumesLeftAverage1 + 273.15, 3) - power(bottomColdAirLeftAverage1 + 273.15, 3)) +
            (effCorrDAvgLeft / 4) *
            (power(topHotFumesLeftAverage1 + 273.15, 4) - power(bottomColdAirLeftAverage1 + 273.15, 4)))) * 100), 2
    );

    //Total Right Max Chamber Efficiency%
    const totalMaxChamberEfficiRight = roundUp(
      (totalAvailableAirEnergyRight /
        (effTotalDFTNm3Right * effRkgm3TotalAvgRight *
          (((effCorrAAvgRight) * ((topHotFumesRightAverage1 + 273.15) - (bottomColdAirRightAverage1 + 273.15))) +
            (effCorrBAvgRight / 2) *
            (power(topHotFumesRightAverage1 + 273.15, 2) - power(bottomColdAirRightAverage1 + 273.15, 2)) +
            (effCorrCAvgRight / 3) *
            (power(topHotFumesRightAverage1 + 273.15, 3) - power(bottomColdAirRightAverage1 + 273.15, 3)) +
            (effCorrDAvgRight / 4) *
            (power(topHotFumesRightAverage1 + 273.15, 4) - power(bottomColdAirRightAverage1 + 273.15, 4)))) * 100), 2
    );

    //Bay Efficiency%(Measured Fumes Correlation)
    const bayEfficiLeft = furnaceAirEnLeft.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyLeft[index] === 0 &&
        furnaceFumesEnLeft[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(((
          (val + gazFuelEnergyLeft[index] - furnaceFumesEnLeft[index]) /
          gazFuelEnergyLeft[index]
        ) * 100), 2);
      }
    });
    const bayEfficiRight = furnaceAirEnRight.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyRight[index] === 0 &&
        furnaceFumesEnRight[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(((
          (val + gazFuelEnergyRight[index] - furnaceFumesEnRight[index]) /
          gazFuelEnergyRight[index]
        ) * 100), 2);
      }
    });

    //Total Left Bay Efficiency%
    const totalBayEfficiLeft = roundUp(
      (((totalFurnaceAirEnergyLeft +
        totalGazFuelEnergyLeft -
        totalFurnaceFumesEnLeft) /
        totalGazFuelEnergyLeft) * 100),
      2
    );

    //Total Right Bay Efficiency%
    const totalBayEfficiRight = roundUp(
      (((totalFurnaceAirEnergyRight +
        totalGazFuelEnergyRight -
        totalFurnaceFumesEnRight) /
        totalGazFuelEnergyRight) * 100),
      2
    );

    //Max Furnace Efficiency%(Measured Fumes Correlation)
    const maxFurnaceEfficiLeft = maxFurnaceAirEnLeft.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyLeft[index] === 0 &&
        furnaceFumesEnLeft[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(((
          (val + gazFuelEnergyLeft[index] - furnaceFumesEnLeft[index]) /
          gazFuelEnergyLeft[index]
        ) * 100), 2);
      }
    });
    const maxFurnaceEfficiRight = maxFurnaceAirEnRight.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyRight[index] === 0 &&
        furnaceFumesEnRight[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(((
          (val + gazFuelEnergyRight[index] - furnaceFumesEnRight[index]) /
          gazFuelEnergyRight[index]
        ) * 100), 2);
      }
    });

    //Total Left Max Furnace Efficiency%
    const totalMaxFurnaceEfficiLeft = roundUp(
      (((totalMaxFurnaceAirEnergyLeft +
        totalGazFuelEnergyLeft -
        totalFurnaceFumesEnLeft) /
        totalGazFuelEnergyLeft) * 100),
      2
    );

    //Total Right Max Furnace Efficiency%
    const totalMaxFurnaceEfficiRight = roundUp(
      (((totalMaxFurnaceAirEnergyRight +
        totalGazFuelEnergyRight -
        totalFurnaceFumesEnRight) /
        totalGazFuelEnergyRight) * 100),
      2
    );

    //EBM Energy Efficiency Definition %(Measured Fumes Correlation)
    const ebmEnergyEffDefLeft = gainedAirEnergyLeft.map((val, index) => {
      if (val === 0 && furnaceFumesEnLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(((val / furnaceFumesEnLeft[index]) * 100), 2);
      }
    });
    const ebmEnergyEffDefRight = gainedAirEnergyRight.map((val, index) => {
      if (val === 0 && furnaceFumesEnRight[index] === 0) {
        return 0;
      } else {
        return roundUp(((val / furnaceFumesEnRight[index]) * 100), 2);
      }
    });

    //Total Left EBM Energy Efficiency Definition %
    const totalEBMEnergyEffDefLeft = roundUp(
      ((totalGainedAirEnergyLeft / totalFurnaceFumesEnLeft) * 100),
      2
    );

    //Total Right EBM Energy Efficiency Definition %
    const totalEBMEnergyEffDefRight = roundUp(
      ((totalGainedAirEnergyRight / totalFurnaceFumesEnRight) * 100),
      2
    );

    //Cp Fumes (kJ/kg.°K) at TEF(Calculated Fumes Correlation)
    const calcCPFumesTEFLeft = topHotFumesLeftSide.map((val, index) => {
      return roundUp(
        corrA1Left[index] +
        corrB1Left[index] * (val + 273.15) +
        corrC1Left[index] * power(val + 273.15, 2) +
        corrD1Left[index] * power(val + 273.15, 3),
        2
      );
    });
    console.log(calcCPFumesTEFLeft, "calcCPFumesTEFLeft");
    const calcCPFumesTEFRight = topHotFumesRightSide.map((val, index) => {
      return roundUp(
        corrA1Right[index] +
        corrB1Right[index] * (val + 273.15) +
        corrC1Right[index] * power(val + 273.15, 2) +
        corrD1Right[index] * power(val + 273.15, 3),
        2
      );
    });
    const filtEffCorrASumLeft = corrA1Left.filter(value => value !== 0 && value !== '');
    const effCorrA1SumLeft = filtEffCorrASumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrA1AvgLeft = functionForNullCheck("calc", effCorrA1SumLeft, filtEffCorrASumLeft);
    const filtEffCorrB1SumLeft = corrB1Left.filter(value => value !== 0 && value !== '');
    const effCorrB1SumLeft = filtEffCorrB1SumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrB1AvgLeft = functionForNullCheck("calc", effCorrB1SumLeft, filtEffCorrB1SumLeft);
    const filtEffCorrC1SumLeft = corrC1Left.filter(value => value !== 0 && value !== '');
    const effCorrC1SumLeft = filtEffCorrC1SumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrC1AvgLeft = functionForNullCheck("calc", effCorrC1SumLeft, filtEffCorrC1SumLeft);
    const filtEffCorrD1SumLeft = corrD1Left.filter(value => value !== 0 && value !== '');
    const effCorrD1SumLeft = filtEffCorrD1SumLeft.reduce((acc, cur) => acc + cur, 0);
    const effCorrD1AvgLeft = functionForNullCheck("calc", effCorrD1SumLeft, filtEffCorrD1SumLeft);
    const filterZeroValLeft = calcCPFumesTEFLeft.filter(value => value !== 0 && value !== '');
    const totCalcCPFumesTEFLeft = filterZeroValLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const totalCalcCPFumesTEFLeft =
      functionForNullCheck("calc", totCalcCPFumesTEFLeft, filterZeroValLeft);
    const filtEffCorrASumRight = corrA1Right.filter(value => value !== 0 && value !== '');
    const effCorrA1SumRight = filtEffCorrASumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrA1AvgRight = functionForNullCheck("calc", effCorrA1SumRight, filtEffCorrASumRight);
    const filtEffCorrBSumRight = corrB1Right.filter(value => value !== 0 && value !== '');
    const effCorrB1SumRight = filtEffCorrBSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrB1AvgRight = functionForNullCheck("calc", effCorrB1SumRight, filtEffCorrBSumRight);
    const filtEffCorrCSumRight = corrC1Right.filter(value => value !== 0 && value !== '');
    const effCorrC1SumRight = filtEffCorrCSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrC1AvgRight = functionForNullCheck("calc", effCorrC1SumRight, filtEffCorrCSumRight);
    const filtEffCorrDSumRight = corrD1Right.filter(value => value !== 0 && value !== '');
    const effCorrD1SumRight = filtEffCorrDSumRight.reduce((acc, cur) => acc + cur, 0);
    const effCorrD1AvgRight = functionForNullCheck("calc", effCorrD1SumRight, filtEffCorrDSumRight);
    const filterZeroValRight = calcCPFumesTEFRight.filter(value => value !== 0 && value !== '');
    const totCalcCPFumesTEFRight = filterZeroValRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const totalCalcCPFumesTEFRight =
      functionForNullCheck("calc", totCalcCPFumesTEFRight, filterZeroValRight);

    //Cp Fumes (kJ/kg.°K) at TEA(Calculated Fumes Correlation)
    const calcCPFumesTEALeft = bottomColdAirLeftSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrA1Left[index] +
          corrB1Left[index] * (val + 273.15) +
          corrC1Left[index] * power(val + 273.15, 2) +
          corrD1Left[index] * power(val + 273.15, 3),
          2
        );
      }
    });
    const calcCPFumesTEARight = bottomColdAirRightSide.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          corrA1Right[index] +
          corrB1Right[index] * (val + 273.15) +
          corrC1Right[index] * power(val + 273.15, 2) +
          corrD1Right[index] * power(val + 273.15, 3),
          2
        );
      }
    });
    const filtZeroValLeft = calcCPFumesTEALeft.filter(value => value !== 0 && value !== '');
    const totCalcCPFumesTEALeft = filtZeroValLeft.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const totalCalcCPFumesTEALeft =
      functionForNullCheck("calc", totCalcCPFumesTEALeft, filtZeroValLeft);
    const filtZeroValRight = calcCPFumesTEARight.filter(value => value !== 0 && value !== '');
    const totCalcCPFumesTEARight = filtZeroValRight.reduce(
      (accumulator, currentValue) => roundUp(accumulator + currentValue, 2), 0);
    const totalCalcCPFumesTEARight =
      functionForNullCheck("calc", totCalcCPFumesTEARight, filtZeroValRight);

    //Fumes Energy(Wh)(Calculated Fumes Correlation)
    const calcFumesEnergyLeft = effDFTNm3Left.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          val *
          effCalFumesCompRLeft[index] *
          (corrA1Left[index] *
            (topHotFumesLeftSide[index] +
              273.15 -
              (bottomColdFumesLeftSide[index] + 273.15)) +
            (corrB1Left[index] / 2) *
            (power(topHotFumesLeftSide[index] + 273.15, 2) -
              power(bottomColdFumesLeftSide[index] + 273.15, 2)) +
            (corrC1Left[index] / 3) *
            (power(topHotFumesLeftSide[index] + 273.15, 3) -
              power(bottomColdFumesLeftSide[index] + 273.15, 3)) +
            (corrD1Left[index] / 4) *
            (power(topHotFumesLeftSide[index] + 273.15, 4) -
              power(bottomColdFumesLeftSide[index] + 273.15, 4)))
        );
      }
    });
    const calcFumesEnergyRight = effDFTNm3Right.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          val *
          effCalFumesCompRRight[index] *
          (corrA1Right[index] *
            (topHotFumesRightSide[index] +
              273.15 -
              (bottomColdFumesRightSide[index] + 273.15)) +
            (corrB1Right[index] / 2) *
            (power(topHotFumesRightSide[index] + 273.15, 2) -
              power(bottomColdFumesRightSide[index] + 273.15, 2)) +
            (corrC1Right[index] / 3) *
            (power(topHotFumesRightSide[index] + 273.15, 3) -
              power(bottomColdFumesRightSide[index] + 273.15, 3)) +
            (corrD1Right[index] / 4) *
            (power(topHotFumesRightSide[index] + 273.15, 4) -
              power(bottomColdFumesRightSide[index] + 273.15, 4)))
        );
      }
    });
    const filZeroValLeft = effCalFumesCompRLeft.filter(value => value !== 0 && value !== '');
    const effCalFumesCompRLeftSum = filZeroValLeft.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const effCalFumesCompRLeftAvg =
      functionForNullCheck("calc", effCalFumesCompRLeftSum, filZeroValLeft);
    const totalCalcFumesEnergyLeft = roundUp(
      effTotalDFTNm3Left *
      effCalFumesCompRLeftAvg *
      (effCorrA1AvgLeft *
        (topHotFumesLeftAverage1 + 273.15) -
        (bottomColdFumesLeftAverage1 + 273.15) +
        (effCorrB1AvgLeft / 2) *
        (power(topHotFumesLeftAverage1 + 273.15, 2) -
          power(bottomColdFumesLeftAverage1 + 273.15, 2)) +
        (effCorrC1AvgLeft / 3) *
        (power(topHotFumesLeftAverage1 + 273.15, 3) -
          power(bottomColdFumesLeftAverage1 + 273.15, 3)) +
        (effCorrD1AvgLeft / 4) *
        (power(topHotFumesLeftAverage1 + 273.15, 4) -
          power(bottomColdFumesLeftAverage1 + 273.15, 4))),
      3
    );
    const filZeroValRight = effCalFumesCompRRight.filter(value => value !== 0 && value !== '');
    const effCalFumesCompRRightSum = filZeroValRight.reduce(
      (acc, cur) => acc + cur,
      0
    );
    const effCalFumesCompRRightAvg =
      functionForNullCheck("calc", effCalFumesCompRRightSum, filZeroValRight);
    const totalCalcFumesEnergyRight = roundUp(
      effTotalDFTNm3Right *
      effCalFumesCompRRightAvg *
      (effCorrA1AvgRight *
        (topHotFumesRightAverage1 + 273.15) -
        (bottomColdFumesRightAverage1 + 273.15) +
        (effCorrB1AvgRight / 2) *
        (power(topHotFumesRightAverage1 + 273.15, 2) -
          power(bottomColdFumesRightAverage1 + 273.15, 2)) +
        (effCorrC1AvgRight / 3) *
        (power(topHotFumesRightAverage1 + 273.15, 3) -
          power(bottomColdFumesRightAverage1 + 273.15, 3)) +
        (effCorrD1AvgRight / 4) *
        (power(topHotFumesRightAverage1 + 273.15, 4) -
          power(bottomColdFumesRightAverage1 + 273.15, 4))),
      3
    );

    //Furnace Fumes Energy (Wh)(Calculated Fumes Correlation)
    const calcFurnaceFumesEnLeft = effDFTNm3Left.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          val *
          effCalFumesCompRLeft[index] *
          (corrA1Left[index] *
            (topHotFumesLeftSide[index] + 273.15 - (25 + 273.15)) +
            (corrB1Left[index] / 2) *
            (power(topHotFumesLeftSide[index] + 273.15, 2) -
              power(25 + 273.15, 2)) +
            (corrC1Left[index] / 3) *
            (power(topHotFumesLeftSide[index] + 273.15, 3) -
              power(25 + 273.15, 3)) +
            (corrD1Left[index] / 4) *
            (power(topHotFumesLeftSide[index] + 273.15, 4) -
              power(25 + 273.15, 4)))
        );
      }
    });
    const calcFurnaceFumesEnRight = effDFTNm3Right.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return (
          val *
          effCalFumesCompRRight[index] *
          (corrA1Right[index] *
            (topHotFumesRightSide[index] + 273.15 - (25 + 273.15)) +
            (corrB1Right[index] / 2) *
            (power(topHotFumesRightSide[index] + 273.15, 2) -
              power(25 + 273.15, 2)) +
            (corrC1Right[index] / 3) *
            (power(topHotFumesRightSide[index] + 273.15, 3) -
              power(25 + 273.15, 3)) +
            (corrD1Right[index] / 4) *
            (power(topHotFumesRightSide[index] + 273.15, 4) -
              power(25 + 273.15, 4)))
        );
      }
    });
    const totalCalcFurnaceFumesEnLeft = roundUp(
      effTotalDFTNm3Left *
      effCalFumesCompRLeftAvg *
      (effCorrA1AvgLeft *
        (topHotFumesLeftAverage1 + 273.15) -
        (25 + 273.15) +
        (effCorrB1AvgLeft / 2) *
        (power(topHotFumesLeftAverage1 + 273.15, 2) - power(25 + 273.15, 2)) +
        (effCorrC1AvgLeft / 3) *
        (power(topHotFumesLeftAverage1 + 273.15, 3) - power(25 + 273.15, 3)) +
        (effCorrD1AvgLeft / 4) *
        (power(topHotFumesLeftAverage1 + 273.15, 4) - power(25 + 273.15, 4))),
      3
    );
    const totalCalcFurnaceFumesEnRight = roundUp(
      effTotalDFTNm3Right *
      effCalFumesCompRRightAvg *
      (effCorrA1AvgRight *
        (topHotFumesRightAverage1 + 273.15) -
        (25 + 273.15) +
        (effCorrB1AvgRight / 2) *
        (power(topHotFumesRightAverage1 + 273.15, 2) - power(25 + 273.15, 2)) +
        (effCorrC1AvgRight / 3) *
        (power(topHotFumesRightAverage1 + 273.15, 3) - power(25 + 273.15, 3)) +
        (effCorrD1AvgRight / 4) *
        (power(topHotFumesRightAverage1 + 273.15, 4) - power(25 + 273.15, 4))),
      3
    );

    //Energy Loss(Wh)(Calculated Fumes Correlation)
    const calcEnergyLossWhLeft = calcFumesEnergyLeft.map((val, index) => {
      if (val === 0 && gainedAirEnergyLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(val - gainedAirEnergyLeft[index], 2);
      }
    });
    const calcEnergyLossWhRight = calcFumesEnergyRight.map((val, index) => {
      if (val === 0 && gainedAirEnergyRight[index] === 0) {
        return 0;
      } else {
        return roundUp(val - gainedAirEnergyRight[index], 2);
      }
    });
    const totalCalcEnergyLossWhLeft = roundUp(
      totalCalcFumesEnergyLeft - totalGainedAirEnergyLeft,
      2
    );
    const totalCalcEnergyLossWhRight = roundUp(
      totalCalcFumesEnergyRight - totalGainedAirEnergyRight,
      2
    );

    //Energy Loss%(Calculated Fumes Correlation)
    const calcEnergyLossPercentLeft = calcEnergyLossWhLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(((1 - gainedAirEnergyLeft[index] / calcFumesEnergyLeft[index]) * 100), 2);
      }
    });
    const calcEnergyLossPercentRight = calcEnergyLossWhRight.map(
      (val, index) => {
        if (val === 0) {
          return 0;
        } else {
          return roundUp(((1 - gainedAirEnergyRight[index] / calcFumesEnergyRight[index]) * 100), 2);
        }
      }
    );
    const totalCalcEnergyLossPercentLeft = roundUp(
      ((1 - totalGainedAirEnergyLeft / totalCalcFumesEnergyLeft) * 100),
      2
    );
    const totalCalcEnergyLossPercentRight = roundUp(
      ((1 - totalGainedAirEnergyRight / totalCalcFumesEnergyRight) * 100),
      2
    );

    //Chamber Efficiency%(Calculated Fumes Correlation)
    const calcChamberEfficLeft = gainedAirEnergyLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              effCalFumesCompRLeft[index] *
              (corrA1Left[index] *
                (topHotFumesLeftSide[index] +
                  273.15 -
                  (bottomColdAirLeftSide[index] + 273.15)) +
                (corrB1Left[index] / 2) *
                (power(topHotFumesLeftSide[index] + 273.15, 2) -
                  power(bottomColdAirLeftSide[index] + 273.15, 2)) +
                (corrC1Left[index] / 3) *
                (power(topHotFumesLeftSide[index] + 273.15, 3) -
                  power(bottomColdAirLeftSide[index] + 273.15, 3)) +
                (corrD1Left[index] / 4) *
                (power(topHotFumesLeftSide[index] + 273.15, 4) -
                  power(bottomColdAirLeftSide[index] + 273.15, 4)))) * 100),
          12
        );
      }
    });
    const calcChamberEfficiLeft = calcChamberEfficLeft.map((value) => {
      return roundUp(value, 2);
    });
    const calcChamberEfficRight = gainedAirEnergyRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Right[index] *
              effCalFumesCompRRight[index] *
              (corrA1Right[index] *
                (topHotFumesRightSide[index] +
                  273.15 -
                  (bottomColdAirRightSide[index] + 273.15)) +
                (corrB1Right[index] / 2) *
                (power(topHotFumesRightSide[index] + 273.15, 2) -
                  power(bottomColdAirRightSide[index] + 273.15, 2)) +
                (corrC1Right[index] / 3) *
                (power(topHotFumesRightSide[index] + 273.15, 3) -
                  power(bottomColdAirRightSide[index] + 273.15, 3)) +
                (corrD1Right[index] / 4) *
                (power(topHotFumesRightSide[index] + 273.15, 4) -
                  power(bottomColdAirRightSide[index] + 273.15, 4)))) * 100),
          12
        );
      }
    });
    const calcChamberEfficiRight = calcChamberEfficRight.map((value) => {
      return roundUp(value, 2);
    });
    const totalCalcChamberEfficiLeft = roundUp(
      (totalGainedAirEnergyLeft /
        (effTotalDFTNm3Left * effRkgm3TotalAvgLeft *
          (((effCorrA1AvgLeft) * ((topHotFumesLeftAverage1 + 273.15) - (bottomColdAirLeftAverage1 + 273.15))) +
            (effCorrB1AvgLeft / 2) *
            (power(topHotFumesLeftAverage1 + 273.15, 2) - power(bottomColdAirLeftAverage1 + 273.15, 2)) +
            (effCorrC1AvgLeft / 3) *
            (power(topHotFumesLeftAverage1 + 273.15, 3) - power(bottomColdAirLeftAverage1 + 273.15, 3)) +
            (effCorrD1AvgLeft / 4) *
            (power(topHotFumesLeftAverage1 + 273.15, 4) - power(bottomColdAirLeftAverage1 + 273.15, 4)))) * 100), 2
    );
    const totalCalcChamberEfficiRight = roundUp(
      (totalGainedAirEnergyRight /
        (effTotalDFTNm3Right * effRkgm3TotalAvgRight *
          (((effCorrA1AvgRight) * ((topHotFumesRightAverage1 + 273.15) - (bottomColdAirRightAverage1 + 273.15))) +
            (effCorrB1AvgRight / 2) *
            (power(topHotFumesRightAverage1 + 273.15, 2) - power(bottomColdAirRightAverage1 + 273.15, 2)) +
            (effCorrC1AvgLeft / 3) *
            (power(topHotFumesRightAverage1 + 273.15, 3) - power(bottomColdAirRightAverage1 + 273.15, 3)) +
            (effCorrD1AvgLeft / 4) *
            (power(topHotFumesRightAverage1 + 273.15, 4) - power(bottomColdAirRightAverage1 + 273.15, 4)))) * 100), 2
    );

    //Max Chamber Efficiency%(Calculated Fumes Correlation)
    const calcMaxChamberEfficLeft = availableAirEnLeft.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Left[index] *
              effCalFumesCompRLeft[index] *
              (corrA1Left[index] *
                (topHotFumesLeftSide[index] +
                  273.15 -
                  (bottomColdAirLeftSide[index] + 273.15)) +
                (corrB1Left[index] / 2) *
                (power(topHotFumesLeftSide[index] + 273.15, 2) -
                  power(bottomColdAirLeftSide[index] + 273.15, 2)) +
                (corrC1Left[index] / 3) *
                (power(topHotFumesLeftSide[index] + 273.15, 3) -
                  power(bottomColdAirLeftSide[index] + 273.15, 3)) +
                (corrD1Left[index] / 4) *
                (power(topHotFumesLeftSide[index] + 273.15, 4) -
                  power(bottomColdAirLeftSide[index] + 273.15, 4)))) * 100),
          12
        );
      }
    });
    const calcMaxChamberEfficiLeft = calcMaxChamberEfficLeft.map((value) => {
      return roundUp(value, 2);
    });
    const calcMaxChamberEfficRight = availableAirEnRight.map((val, index) => {
      if (val === 0) {
        return 0;
      } else {
        return roundUp(
          (val /
            (effDFTNm3Right[index] *
              effCalFumesCompRRight[index] *
              (corrA1Right[index] *
                (topHotFumesRightSide[index] +
                  273.15 -
                  (bottomColdAirRightSide[index] + 273.15)) +
                (corrB1Right[index] / 2) *
                (power(topHotFumesRightSide[index] + 273.15, 2) -
                  power(bottomColdAirRightSide[index] + 273.15, 2)) +
                (corrC1Right[index] / 3) *
                (power(topHotFumesRightSide[index] + 273.15, 3) -
                  power(bottomColdAirRightSide[index] + 273.15, 3)) +
                (corrD1Right[index] / 4) *
                (power(topHotFumesRightSide[index] + 273.15, 4) -
                  power(bottomColdAirRightSide[index] + 273.15, 4)))) * 100),
          12
        );
      }
    });
    const calcMaxChamberEfficiRight = calcMaxChamberEfficRight.map((value) => {
      return roundUp(value, 2);
    });
    const totalMaxCalcChamberEfficiLeft = roundUp(
      (totalAvailableAirEnergyLeft /
        (effTotalDFTNm3Left * effRkgm3TotalAvgLeft *
          (((effCorrA1AvgLeft) * ((topHotFumesLeftAverage1 + 273.15) - (bottomColdAirLeftAverage1 + 273.15))) +
            (effCorrB1AvgLeft / 2) *
            (power(topHotFumesLeftAverage1 + 273.15, 2) - power(bottomColdAirLeftAverage1 + 273.15, 2)) +
            (effCorrC1AvgLeft / 3) *
            (power(topHotFumesLeftAverage1 + 273.15, 3) - power(bottomColdAirLeftAverage1 + 273.15, 3)) +
            (effCorrD1AvgLeft / 4) *
            (power(topHotFumesLeftAverage1 + 273.15, 4) - power(bottomColdAirLeftAverage1 + 273.15, 4)))) * 100), 2
    );
    const totalMaxCalcChamberEfficiRight = roundUp(
      (totalAvailableAirEnergyRight /
        (effTotalDFTNm3Right * effRkgm3TotalAvgRight *
          (((effCorrA1AvgRight) * ((topHotFumesRightAverage1 + 273.15) - (bottomColdAirRightAverage1 + 273.15))) +
            (effCorrB1AvgRight / 2) *
            (power(topHotFumesRightAverage1 + 273.15, 2) - power(bottomColdAirRightAverage1 + 273.15, 2)) +
            (effCorrC1AvgRight / 3) *
            (power(topHotFumesRightAverage1 + 273.15, 3) - power(bottomColdAirRightAverage1 + 273.15, 3)) +
            (effCorrD1AvgRight / 4) *
            (power(topHotFumesRightAverage1 + 273.15, 4) - power(bottomColdAirRightAverage1 + 273.15, 4)))) * 100), 2
    );

    //Bay Efficiency%(Calculated Fumes Correlation)
    const calcBayEfficiLeft = furnaceAirEnLeft.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyLeft[index] === 0 &&
        calcFurnaceFumesEnLeft[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (((val + gazFuelEnergyLeft[index] - calcFurnaceFumesEnLeft[index]) /
            gazFuelEnergyLeft[index]) * 100),
          2
        );
      }
    });
    const calcBayEfficiRight = furnaceAirEnRight.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyRight[index] === 0 &&
        calcFurnaceFumesEnRight[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (((val + gazFuelEnergyRight[index] - calcFurnaceFumesEnRight[index]) /
            gazFuelEnergyLeft[index]) * 100),
          2
        );
      }
    });
    const totalCalcBayEfficiLeft = roundUp(
      (((totalFurnaceAirEnergyLeft +
        totalGazFuelEnergyLeft -
        totalCalcFurnaceFumesEnLeft) / totalGazFuelEnergyLeft) * 100),
      2
    );
    const totalCalcBayEfficiRight = roundUp(
      (((totalFurnaceAirEnergyRight +
        totalGazFuelEnergyRight -
        totalCalcFurnaceFumesEnRight) / totalGazFuelEnergyRight) * 100),
      2
    );

    //Max Furnace Efficiency%(Calculated Fumes Correlation)
    const calcMaxFurnaceEfficiLeft = maxFurnaceAirEnLeft.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyLeft[index] === 0 &&
        calcFurnaceFumesEnLeft[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (((val + gazFuelEnergyLeft[index] - calcFurnaceFumesEnLeft[index]) /
            gazFuelEnergyLeft[index]) * 100),
          2
        );
      }
    });
    const calcMaxFurnaceEfficiRight = maxFurnaceAirEnRight.map((val, index) => {
      if (
        val === 0 &&
        gazFuelEnergyRight[index] === 0 &&
        calcFurnaceFumesEnRight[index] === 0
      ) {
        return 0;
      } else {
        return roundUp(
          (((val + gazFuelEnergyRight[index] - calcFurnaceFumesEnRight[index]) /
            gazFuelEnergyRight[index]) * 100),
          2
        );
      }
    });
    const totalCalcMaxFurnaceEfficiLeft = roundUp(
      (((totalMaxFurnaceAirEnergyLeft +
        totalGazFuelEnergyLeft -
        totalCalcFurnaceFumesEnLeft) /
        totalGazFuelEnergyLeft) * 100),
      2
    );
    const totalCalcMaxFurnaceEfficiRight = roundUp(
      (((totalMaxFurnaceAirEnergyRight +
        totalGazFuelEnergyRight -
        totalCalcFurnaceFumesEnRight) /
        totalGazFuelEnergyRight) * 100),
      2
    );

    //EBM Energy Efficiency Definition %(Calculated Fumes Correlation)
    const calcEBMEnergyEffLeft = gainedAirEnergyLeft.map((val, index) => {
      if (val === 0 && calcFurnaceFumesEnLeft[index] === 0) {
        return 0;
      } else {
        return roundUp(((val / calcFurnaceFumesEnLeft[index]) * 100), 12);
      }
    });
    const calcEBMEnergyEffDefLeft = calcEBMEnergyEffLeft.map((value) => {
      return roundUp(value, 2);
    });
    const calcEBMEnergyEffRight = gainedAirEnergyRight.map((val, index) => {
      if (val === 0 && calcFurnaceFumesEnRight[index] === 0) {
        return 0;
      } else {
        return roundUp(((val / calcFurnaceFumesEnLeft[index]) * 100), 12);
      }
    });
    const calcEBMEnergyEffDefRight = calcEBMEnergyEffRight.map((value) => {
      return roundUp(value, 2);
    });
    const totalCalcEBMEnergyEffDefLeft = roundUp(
      ((totalGainedAirEnergyLeft / totalCalcFurnaceFumesEnLeft) * 100),
      2
    );
    const totalCalcEBMEnergyEffDefRight = roundUp(
      ((totalGainedAirEnergyRight / totalCalcFurnaceFumesEnRight) * 100),
      2
    );

    return {
      calcStaticExcelDetails: {
        //Gaz Data
        totalDensity: totalDensity,
        totalAirCombustible: totalAirCombustible,
        totalAirCombustibleEFF: totalAirCombustibleEFF,
        totalCombustionFumesCO2: totalCombustionFumesCO2,
        totalCombustionFumesH2O: totalCombustionFumesH2O,
        totalCombustionHeatLHV: totalCombustionHeatLHV,
        totalCombustionHeatHHV: totalCombustionHeatHHV,
        sumGazSmokeIndexDry: sumGazSmokeIndexDry,
        sumGazSmokeIndexWet: sumGazSmokeIndexWet,
        //Fuel Data
        sumFuelAirMolesOxygen: sumFuelAirMolesOxygen,
        sumFuelAirMolesNitrogen: sumFuelAirMolesNitrogen,
        sumFuelAirMoles: sumFuelAirMoles,
        // Air/combustible ratio
        airPouvoir: airPouvoir,
        combustionFumesCO2: combustionFumesCO2,
        sumCombustionFumesH2O: sumCombustionFumesH2O,
        sumCombustionFumesN2: sumCombustionFumesN2,
        combustionFumesSO2: combustionFumesSO2,
        fuelSmokeIndexDry: fuelSmokeIndexDry,
        fuelSmokeIndexWet: fuelSmokeIndexWet,
        sumCombustionHeatHHV: sumCombustionHeatHHV,
        sumCombustionHeatLHV: sumCombustionHeatLHV,
        //Flows - Fuel - Gaz & others
        // DFN (Neutral Fumes Flow)
        flowsDFNGazLeft: flowsDFNGazLeft,
        flowsDFNGazRight: flowsDFNGazRight,
        totalFlowsDFNGazLeft: totalFlowsDFNGazLeft,
        totalFlowsDFNGazRight: totalFlowsDFNGazRight,
        flowsDFNFuelLeft: flowsDFNFuelLeft,
        flowsDFNFuelRight: flowsDFNFuelRight,
        totalFlowsDFNFuelLeft: totalFlowsDFNFuelLeft,
        totalFlowsDFNFuelRight: totalFlowsDFNFuelRight,
        flowsDFNTotalLeft: flowsDFNTotalLeft,
        flowsDFNTotalRight: flowsDFNTotalRight,
        flowsTotalDFNTotalLeft: flowsTotalDFNTotalLeft,
        flowsTotalDFNTotalRight: flowsTotalDFNTotalRight,
        //DAN (Neutral Air Flow)
        flowsDANGazLeft: flowsDANGazLeft,
        flowsDANGazRight: flowsDANGazRight,
        flowsDANTotalGazLeft: flowsDANTotalGazLeft,
        flowsDANTotalGazRight: flowsDANTotalGazRight,
        flowsDANFuelLeft: flowsDANFuelLeft,
        flowsDANFuelRight: flowsDANFuelRight,
        flowsDANTotalFuelLeft: flowsDANTotalFuelLeft,
        flowsDANTotalFuelRight: flowsDANTotalFuelRight,
        flowsDANTotalLeft: flowsDANTotalLeft,
        flowsDANTotalRight: flowsDANTotalRight,
        flowsTotalDANTotalLeft: flowsTotalDANTotalLeft,
        flowsTotalDANTotalRight: flowsTotalDANTotalRight,
        //DGC (Batch Gaz Flow)
        //Dehydration
        flowsDGCDehydrationkGLeft: flowsDGCDehydrationkGLeft,
        flowsDGCDehydrationkGRight: flowsDGCDehydrationkGRight,
        totalFlowsDGCDehydrationkGLeft: totalFlowsDGCDehydrationkGLeft,
        totalFlowsDGCDehydrationkGRight: totalFlowsDGCDehydrationkGRight,
        flowsDGCDehydrationNM3Left: flowsDGCDehydrationNM3Left,
        flowsDGCDehydrationNM3Right: flowsDGCDehydrationNM3Right,
        totalFlowsDGCDehydrationNM3Left: totalFlowsDGCDehydrationNM3Left,
        totalFlowsDGCDehydrationNM3Right: totalFlowsDGCDehydrationNM3Right,
        //Decarbonation
        flowsDGCDecarbonationKGLeft: flowsDGCDecarbonationKGLeft,
        flowsDGCDecarbonationKGRight: flowsDGCDecarbonationKGRight,
        totalFlowsDGCDecarbonationKGLeft: totalFlowsDGCDecarbonationKGLeft,
        totalFlowsDGCDecarbonationKGRight: totalFlowsDGCDecarbonationKGRight,
        flowsDGCDecarbonationNM3Left: flowsDGCDecarbonationNM3Left,
        flowsDGCDecarbonationNM3Right: flowsDGCDecarbonationNM3Right,
        totalFlowsDGCDecarbonationNM3Left: totalFlowsDGCDecarbonationNM3Left,
        totalFlowsDGCDecarbonationNM3Right: totalFlowsDGCDecarbonationNM3Right,
        flowsDGCTotalLeft: flowsDGCTotalLeft,
        flowsDGCTotalRight: flowsDGCTotalRight,
        flowsTotalDGCTotalLeft: flowsTotalDGCTotalLeft,
        flowsTotalDGCTotalRight: flowsTotalDGCTotalRight,
        //Combustion Correlation
        //Air/Energy ratio
        flowsCombustionCorrAirLeft: flowsCombustionCorrAirLeft,
        flowsCombustionCorrAirRight: flowsCombustionCorrAirRight,
        flowsTotalCombustionCorrAirLeft: flowsTotalCombustionCorrAirLeft,
        flowsTotalCombustionCorrAirRight: flowsTotalCombustionCorrAirRight,
        //Correlation
        flowsTotalAvgCorrelationLeft: flowsTotalAvgCorrelationLeft,
        flowsTotalAvgCorrelationRight: flowsTotalAvgCorrelationRight,
        //Resulting Air Flows
        //Dexa (Excess Air Flow)(Nm3/h)
        flowsDexaLeft: flowsDexaLeft,
        flowsDexaRight: flowsDexaRight,
        flowsCorrelationTotalLeft: flowsCorrelationTotalLeft,
        flowsCorrelationTotalRight: flowsCorrelationTotalRight,
        //DAP (Parasitic Air Flow)(Nm3/h)
        flowsDAPParasiticLeft: flowsDAPParasiticLeft,
        flowsDAPParasiticRight: flowsDAPParasiticRight,
        flowsDAPParasiticTotalLeft: flowsDAPParasiticTotalLeft,
        flowsDAPParasiticTotalRight: flowsDAPParasiticTotalRight,
        //DFT(Total Fumes Flow)
        //DFN+DGC+DEXA (Nm3/h)
        flowsDFTDfnDgcDexaLeft: flowsDFTDfnDgcDexaLeft,
        flowsDFTDfnDgcDexaRight: flowsDFTDfnDgcDexaRight,
        flowsTotalDFTDfnDgcDexaLeft: flowsTotalDFTDfnDgcDexaLeft,
        flowsTotalDFTDfnDgcDexaRight: flowsTotalDFTDfnDgcDexaRight,
        //Previous+DAP (Nm3/h)
        flowsDFTPreviousDAPLeft: flowsDFTPreviousDAPLeft,
        flowsDFTPreviousDAPRight: flowsDFTPreviousDAPRight,
        flowsDFTPreviousDAPTotalLeft: flowsDFTPreviousDAPTotalLeft,
        flowsDFTPreviousDAPTotalRight: flowsDFTPreviousDAPTotalRight,
        //DAT(Total Air Flow)
        //DAN + DEXA(Nm3/h)
        flowsDATDanDexaLeft: flowsDATDanDexaLeft,
        flowsDATDanDexaRight: flowsDATDanDexaRight,
        flowsTotalDATDanDexaLeft: flowsTotalDATDanDexaLeft,
        flowsTotalDATDanDexaRight: flowsTotalDATDanDexaRight,
        //Measured at top CO2
        flowsMeasuredTopCO2Left: flowsMeasuredTopCO2Left,
        flowsMeasuredTopCO2Right: flowsMeasuredTopCO2Right,
        flowsAvgMeasuredTopCO2Left: flowsAvgMeasuredTopCO2Left,
        flowsAvgMeasuredTopCO2Right: flowsAvgMeasuredTopCO2Right,
        //Calculated at top
        //O2
        flowsCalTopO2Left: flowsCalTopO2Left,
        flowsCalTopO2Right: flowsCalTopO2Right,
        flowsAvgCalTopO2Left: flowsAvgCalTopO2Left,
        flowsAvgCalTopO2Right: flowsAvgCalTopO2Right,
        //CO2
        flowsCalTopCO2Left: flowsCalTopCO2Left,
        flowsCalTopCO2Right: flowsCalTopCO2Right,
        flowsAvgCalTopCO2Left: flowsAvgCalTopCO2Left,
        flowsAvgCalTopCO2Right: flowsAvgCalTopCO2Right,
        //Calculated Fumes
        //O2
        flowsCalFumesO2Left: flowsCalFumesO2Left,
        flowsCalFumesO2Right: flowsCalFumesO2Right,
        flowsAvgCalFumesO2Left: flowsAvgCalFumesO2Left,
        flowsAvgCalFumesO2Right: flowsAvgCalFumesO2Right,
        //CO2
        flowsCalculFumesCO2Left: flowsCalculFumesCO2Left,
        flowsCalculFumesCO2Right: flowsCalculFumesCO2Right,
        flowsAvgCalFumesCO2Left: flowsAvgCalFumesCO2Left,
        flowsAvgCalFumesCO2Right: flowsAvgCalFumesCO2Right,
        //H2O
        flowsCalculFumesH2OLeft: flowsCalculFumesH2OLeft,
        flowsCalculFumesH2ORight: flowsCalculFumesH2ORight,
        flowsAvgCalFumesH2OLeft: flowsAvgCalFumesH2OLeft,
        flowsAvgCalFumesH2ORight: flowsAvgCalFumesH2ORight,
        //N2
        flowsCalCulFumesN2Left: flowsCalCulFumesN2Left,
        flowsCalCulFumesN2Right: flowsCalCulFumesN2Right,
        flowsAvgCalFumesN2Left: flowsAvgCalFumesN2Left,
        flowsAvgCalFumesN2Right: flowsAvgCalFumesN2Right,
        //Verification
        flowsCalculVerifyLeft: flowsCalculVerifyLeft,
        flowsCalculVerifyRight: flowsCalculVerifyRight,
        flowsAvgCalFumesVerifyLeft: flowsAvgCalFumesVerifyLeft,
        flowsAvgCalFumesVerifyRight: flowsAvgCalFumesVerifyRight,
        //Efficiencies
        //DAT(Nm3/h)
        effDFTNm3Left: effDFTNm3Left,
        effDFTNm3Right: effDFTNm3Right,
        effTotalDFTNm3Left: effTotalDFTNm3Left,
        effTotalDFTNm3Right: effTotalDFTNm3Right,
        //Fumes/Air Ratio
        effFumesAirRatioLeft: effFumesAirRatioLeft,
        effFumesAirRatioRight: effFumesAirRatioRight,
        effTotalFumesAirRatioLeft: effTotalFumesAirRatioLeft,
        effTotalFumesAirRatioRight: effTotalFumesAirRatioRight,
        //Air Speed(m/s)
        effAirSpeedLeft: effAirSpeedLeft,
        effAirSpeedRight: effAirSpeedRight,
        effTotalAirSpeedLeft: effTotalAirSpeedLeft,
        effTotalAirSpeedRight: effTotalAirSpeedRight,
        //Fumes Speed(m/s)
        effFumesSpeedLeft: effFumesSpeedLeft,
        effFumesSpeedRight: effFumesSpeedRight,
        effTotalFumesSpeedLeft: effTotalFumesSpeedLeft,
        effTotalFumesSpeedRight: effTotalFumesSpeedRight,
        //Fumes Composition
        //Calculated Fumes
        effCalFumesCO2Left: effCalFumesCO2Left,
        effCalFumesCO2Right: effCalFumesCO2Right,
        effCalFumesN2Left: effCalFumesN2Left,
        effCalFumesN2Right: effCalFumesN2Right,
        effCalFumesH2OLeft: effCalFumesH2OLeft,
        effCalFumesH2ORight: effCalFumesH2ORight,
        effCalFumesO2Left: effCalFumesO2Left,
        effCalFumesO2Right: effCalFumesO2Right,
        //Calculated Fumes Total
        totalFumesCompoPercentVolLeft: totalFumesCompoPercentVolLeft,
        totalFumesCompoPercentVolRight: totalFumesCompoPercentVolRight,
        //Calculated Fumes //ρ(kg/m3)
        effCalFumesCompRLeft: effCalFumesCompRLeft,
        effCalFumesCompRRight: effCalFumesCompRRight,
        //Calculated Fumes //M(g/mol)
        effCalFumesCompMLeft: effCalFumesCompMLeft,
        effCalFumesCompMRight: effCalFumesCompMRight,
        //Calculated Fumes(Mass%)
        totalCalCO2PercentageMassLeft: totalCalCO2PercentageMassLeft,
        totalCalCO2PercentageMassRight: totalCalCO2PercentageMassRight,
        totalCalN2PercentageMassLeft: totalCalN2PercentageMassLeft,
        totalCalN2PercentageMassRight: totalCalN2PercentageMassRight,
        totalCalH2OPercentageMassLeft: totalCalH2OPercentageMassLeft,
        totalCalH2OPercentageMassRight: totalCalH2OPercentageMassRight,
        totalCalO2PercentageMassLeft: totalCalO2PercentageMassLeft,
        totalCalO2PercentageMassRight: totalCalO2PercentageMassRight,
        //Dusts Analysis
        //Generates per hour(Gr/h)
        //Carryovers
        dustsGeneCarryoversSIO2: dustsGeneCarryoversSIO2,
        dustsGeneCarryoversCAO: dustsGeneCarryoversCAO,
        dustsGeneCarryoversMGO: dustsGeneCarryoversMGO,
        dustsGeneCarryoversAL2O3: dustsGeneCarryoversAL2O3,
        //Alkaline Compounds
        dustsGeneAlkalineNA2O: dustsGeneAlkalineNA2O,
        dustsGeneAlkalineK2O: dustsGeneAlkalineK2O,
        dustsGeneAlkalineSO4: dustsGeneAlkalineSO4,
        //Others
        dustsGeneOthersP2O5: dustsGeneOthersP2O5,
        dustsGeneOthersCR2O3: dustsGeneOthersCR2O3,
        dustsGeneOthersFE2O3: dustsGeneOthersFE2O3,
        dustsGeneOthersPBO: dustsGeneOthersPBO,
        dustsGeneOthersV2O5: dustsGeneOthersV2O5,
        dustsGeneOthersCL: dustsGeneOthersCL,
        dustsGeneOthersNO3: dustsGeneOthersNO3,
        //Total Generates per hour(Gr/h)
        sumDustsAnalysisArrays: sumDustsAnalysisArrays,
        //Generates per year(t/y)
        //Carryovers
        dustsYearCarryoversSIO2: dustsYearCarryoversSIO2,
        dustsYearCarryoversCAO: dustsYearCarryoversCAO,
        dustsYearCarryoversMGO: dustsYearCarryoversMGO,
        dustsYearCarryoversAL2O3: dustsYearCarryoversAL2O3,
        //Alkaline Compounds
        dustsYearAlkalineNA2O: dustsYearAlkalineNA2O,
        dustsYearAlkalineK2O: dustsYearAlkalineK2O,
        dustsYearAlkalineSO4: dustsYearAlkalineSO4,
        //Others
        dustsYearOthersP2O5: dustsYearOthersP2O5,
        dustsYearOthersCR2O3: dustsYearOthersCR2O3,
        dustsYearOthersFE2O3: dustsYearOthersFE2O3,
        dustsYearOthersPBO: dustsYearOthersPBO,
        dustsYearOthersV2O5: dustsYearOthersV2O5,
        dustsYearOthersCL: dustsYearOthersCL,
        dustsYearOthersNO3: dustsYearOthersNO3,
        //Total Generates per year(t/y)
        dustsAnalysisYearArrays: dustsAnalysisYearArrays,
        //Efficiencies Average
        //Carryovers
        effAverageCarryoversSIO2: effAverageCarryoversSIO2,
        effAverageCarryoversCAO: effAverageCarryoversCAO,
        effAverageCarryoversMGO: effAverageCarryoversMGO,
        effAverageCarryoversAL2O3: effAverageCarryoversAL2O3,
        //Alkaline Compounds
        effAverageAlkalineNA2O: effAverageAlkalineNA2O,
        effAverageAlkalineK2O: effAverageAlkalineK2O,
        effAverageAlkalineSO4: effAverageAlkalineSO4,
        //Others
        effAverageOthersP2O5: effAverageOthersP2O5,
        effAverageOthersCR2O3: effAverageOthersCR2O3,
        effAverageOthersFE2O3: effAverageOthersFE2O3,
        effAverageOthersPBO: effAverageOthersPBO,
        effAverageOthersV2O5: effAverageOthersV2O5,
        effAverageOthersCL: effAverageOthersCL,
        effAverageOthersNO3: effAverageOthersNO3,
        //Average Total
        effOverallAverageTotal: effOverallAverageTotal,
        //Efficiencies Total
        //Carryovers
        effSumSIO2: effSumSIO2,
        effSumCAO: effSumCAO,
        effSumMGO: effSumMGO,
        effSumAL2O3: effSumAL2O3,
        //Alkaline Compounds
        effSumNA2O: effSumNA2O,
        effSumK2O: effSumK2O,
        effSumSO4: effSumSO4,
        //Others
        effSumP2O5: effSumP2O5,
        effSumCR2O3: effSumCR2O3,
        effSumFE2O3: effSumFE2O3,
        effSumPBO: effSumPBO,
        effSumV2O5: effSumV2O5,
        effSumCL: effSumCL,
        effSumNO3: effSumNO3,
        //Efficiencies Total(Total)
        effTotalSum: effTotalSum,
        //Carryovers Si+Ca+Al+Mg
        effCarryoversSum: effCarryoversSum,
        //Alkaline Compounds  Na+K+SO
        effAlkalineCompundsSum: effAlkalineCompundsSum,
        //Others
        effOthersSum: effOthersSum,
        //Overall
        effOverallSum: effOverallSum,
        //Efficiencies Correlation Measured Fumes Correlation
        corrALeft: corrALeft,
        corrARight: corrARight,
        corrBLeft: corrBLeft,
        corrBRight: corrBRight,
        corrCLeft: corrCLeft,
        corrCRight: corrCRight,
        corrDLeft: corrDLeft,
        corrDRight: corrDRight,
        //Efficiencies Correlation Calculated Fumes Correlation
        corrA1Left: corrA1Left,
        corrA1Right: corrA1Right,
        corrB1Left: corrB1Left,
        corrB1Right: corrB1Right,
        corrC1Left: corrC1Left,
        corrC1Right: corrC1Right,
        corrD1Left: corrD1Left,
        corrD1Right: corrD1Right,
        //Cp Air (J/kg.°K) at TSA
        cpAirTSALeft: cpAirTSALeft,
        cpAirTSARight: cpAirTSARight,
        totalCPAirTSALeft: totalCPAirTSALeft,
        totalCPAirTSARight: totalCPAirTSARight,
        //Cp Air (J/kg.°K) at TEA
        cpAirTEALeft: cpAirTEALeft,
        cpAirTEARight: cpAirTEARight,
        totalCPAirTEALeft: totalCPAirTEALeft,
        totalCPAirTEARight: totalCPAirTEARight,
        //Cp Fumes (kJ/kg.°K) at TEF(Measured Fumes Correlation)
        cpFumesTEFLeft: cpFumesTEFLeft,
        cpFumesTEFRight: cpFumesTEFRight,
        totalCPFumesTEFLeft: totalCPFumesTEFLeft,
        totalCPFumesTEFRight: totalCPFumesTEFRight,
        //Cp Fumes (kJ/kg.°K) at TEA(Measured Fumes Correlation)
        cpFumesTEALeft: cpFumesTEALeft,
        cpFumesTEARight: cpFumesTEARight,
        totalCPFumesTEALeft: totalCPFumesTEALeft,
        totalCPFumesTEARight: totalCPFumesTEARight,
        //Gained Air Energy (Wh)
        gainedAirEnergyLeft: gainedAirEnergyLeft,
        gainedAirEnergyRight: gainedAirEnergyRight,
        totalGainedAirEnergyLeft: totalGainedAirEnergyLeft,
        totalGainedAirEnergyRight: totalGainedAirEnergyRight,
        //Available Air Energy (Wh)
        availableAirEnLeft: availableAirEnLeft,
        availableAirEnRight: availableAirEnRight,
        totalAvailableAirEnergyLeft: totalAvailableAirEnergyLeft,
        totalAvailableAirEnergyRight: totalAvailableAirEnergyRight,
        //Furnace Air Energy (Wh)
        furnaceAirEnLeft: furnaceAirEnLeft,
        furnaceAirEnRight: furnaceAirEnRight,
        totalFurnaceAirEnergyLeft: totalFurnaceAirEnergyLeft,
        totalFurnaceAirEnergyRight: totalFurnaceAirEnergyRight,
        //Max Furnace Air Energy (Wh)
        maxFurnaceAirEnLeft: maxFurnaceAirEnLeft,
        maxFurnaceAirEnRight: maxFurnaceAirEnRight,
        totalMaxFurnaceAirEnergyLeft: totalMaxFurnaceAirEnergyLeft,
        totalMaxFurnaceAirEnergyRight: totalMaxFurnaceAirEnergyRight,
        //Gaz&Fuel Energy (Wh)
        gazFuelEnergyLeft: gazFuelEnergyLeft,
        gazFuelEnergyRight: gazFuelEnergyRight,
        totalGazFuelEnergyLeft: totalGazFuelEnergyLeft,
        totalGazFuelEnergyRight: totalGazFuelEnergyRight,
        //Fumes Energy (Wh)(Measured Fumes Correlation)
        fumesEnergyLeft: fumesEnergyLeft,
        fumesEnergyRight: fumesEnergyRight,
        totalFumesEnergyLeft: totalFumesEnergyLeft,
        totalFumesEnergyRight: totalFumesEnergyRight,
        //Furnace Fumes Energy (Wh)(Measured Fumes Correlation)
        furnaceFumesEnLeft: furnaceFumesEnLeft,
        furnaceFumesEnRight: furnaceFumesEnRight,
        totalFurnaceFumesEnLeft: totalFurnaceFumesEnLeft,
        totalFurnaceFumesEnRight: totalFurnaceFumesEnRight,
        //Energy Loss (Wh)(Measured Fumes Correlation)
        energyLossLeft: energyLossLeft,
        energyLossRight: energyLossRight,
        totalEnergyLossLeft: totalEnergyLossLeft,
        totalEnergyLossRight: totalEnergyLossRight,
        //Energy Loss%(Measured Fumes Correlation)
        energyLossPercentLeft: energyLossPercentLeft,
        energyLossPercentRight: energyLossPercentRight,
        totalEnergyLossPercentLeft: totalEnergyLossPercentLeft,
        totalEnergyLossPercentRight: totalEnergyLossPercentRight,
        //Chamber Efficiency%(Measured Fumes Correlation)
        chamberEfficiLeft: chamberEfficiLeft,
        chamberEfficiRight: chamberEfficiRight,
        totalChamberEfficiLeft: totalChamberEfficiLeft,
        totalChamberEfficiRight: totalChamberEfficiRight,
        //Max Chamber Efficiency%(Measured Fumes Correlation)
        maxChamberEffLeft: maxChamberEffLeft,
        maxChamberEffRight: maxChamberEffRight,
        totalMaxChamberEfficiLeft: totalMaxChamberEfficiLeft,
        totalMaxChamberEfficiRight: totalMaxChamberEfficiRight,
        //Bay Efficiency%(Measured Fumes Correlation)
        bayEfficiLeft: bayEfficiLeft,
        bayEfficiRight: bayEfficiRight,
        totalBayEfficiLeft: totalBayEfficiLeft,
        totalBayEfficiRight: totalBayEfficiRight,
        //Max Furnace Efficiency%(Measured Fumes Correlation)
        maxFurnaceEfficiLeft: maxFurnaceEfficiLeft,
        maxFurnaceEfficiRight: maxFurnaceEfficiRight,
        totalMaxFurnaceEfficiLeft: totalMaxFurnaceEfficiLeft,
        totalMaxFurnaceEfficiRight: totalMaxFurnaceEfficiRight,
        //EBM Energy Efficiency Definition %(Measured Fumes Correlation)
        ebmEnergyEffDefLeft: ebmEnergyEffDefLeft,
        ebmEnergyEffDefRight: ebmEnergyEffDefRight,
        totalEBMEnergyEffDefLeft: totalEBMEnergyEffDefLeft,
        totalEBMEnergyEffDefRight: totalEBMEnergyEffDefRight,
        //Cp Fumes (kJ/kg.°K) at TEF(Calculated Fumes Correlation)
        calcCPFumesTEFLeft: calcCPFumesTEFLeft,
        calcCPFumesTEFRight: calcCPFumesTEFRight,
        totalCalcCPFumesTEFLeft: totalCalcCPFumesTEFLeft,
        totalCalcCPFumesTEFRight: totalCalcCPFumesTEFRight,
        //Cp Fumes (kJ/kg.°K) at TEA(Calculated Fumes Correlation)
        calcCPFumesTEALeft: calcCPFumesTEALeft,
        calcCPFumesTEARight: calcCPFumesTEARight,
        totalCalcCPFumesTEALeft: totalCalcCPFumesTEALeft,
        totalCalcCPFumesTEARight: totalCalcCPFumesTEARight,
        //Fumes Energy(Wh)(Calculated Fumes Correlation)
        calcFumesEnergyLeft: calcFumesEnergyLeft,
        calcFumesEnergyRight: calcFumesEnergyRight,
        totalCalcFumesEnergyLeft: totalCalcFumesEnergyLeft,
        totalCalcFumesEnergyRight: totalCalcFumesEnergyRight,
        //Furnace Fumes Energy (Wh)(Calculated Fumes Correlation)
        calcFurnaceFumesEnLeft: calcFurnaceFumesEnLeft,
        calcFurnaceFumesEnRight: calcFurnaceFumesEnRight,
        totalCalcFurnaceFumesEnLeft: totalCalcFurnaceFumesEnLeft,
        totalCalcFurnaceFumesEnRight: totalCalcFurnaceFumesEnRight,
        //Energy Loss(Wh)(Calculated Fumes Correlation)
        calcEnergyLossWhLeft: calcEnergyLossWhLeft,
        calcEnergyLossWhRight: calcEnergyLossWhRight,
        totalCalcEnergyLossWhLeft: totalCalcEnergyLossWhLeft,
        totalCalcEnergyLossWhRight: totalCalcEnergyLossWhRight,
        //Energy Loss%(Calculated Fumes Correlation)
        calcEnergyLossPercentLeft: calcEnergyLossPercentLeft,
        calcEnergyLossPercentRight: calcEnergyLossPercentRight,
        totalCalcEnergyLossPercentLeft: totalCalcEnergyLossPercentLeft,
        totalCalcEnergyLossPercentRight: totalCalcEnergyLossPercentRight,
        //Chamber Efficiency%(Calculated Fumes Correlation)
        calcChamberEfficiLeft: calcChamberEfficiLeft,
        calcChamberEfficiRight: calcChamberEfficiRight,
        totalCalcChamberEfficiLeft: totalCalcChamberEfficiLeft,
        totalCalcChamberEfficiRight: totalCalcChamberEfficiRight,
        //Max Chamber Efficiency%(Calculated Fumes Correlation)
        calcMaxChamberEfficiLeft: calcMaxChamberEfficiLeft,
        calcMaxChamberEfficiRight: calcMaxChamberEfficiRight,
        totalMaxCalcChamberEfficiLeft: totalMaxCalcChamberEfficiLeft,
        totalMaxCalcChamberEfficiRight: totalMaxCalcChamberEfficiRight,
        //Bay Efficiency%(Calculated Fumes Correlation)
        calcBayEfficiLeft: calcBayEfficiLeft,
        calcBayEfficiRight: calcBayEfficiRight,
        totalCalcBayEfficiLeft: totalCalcBayEfficiLeft,
        totalCalcBayEfficiRight: totalCalcBayEfficiRight,
        //Max Furnace Efficiency%(Calculated Fumes Correlation)
        calcMaxFurnaceEfficiLeft: calcMaxFurnaceEfficiLeft,
        calcMaxFurnaceEfficiRight: calcMaxFurnaceEfficiRight,
        totalCalcMaxFurnaceEfficiLeft: totalCalcMaxFurnaceEfficiLeft,
        totalCalcMaxFurnaceEfficiRight: totalCalcMaxFurnaceEfficiRight,
        //EBM Energy Efficiency Definition %(Calculated Fumes Correlation)
        calcEBMEnergyEffDefLeft: calcEBMEnergyEffDefLeft,
        calcEBMEnergyEffDefRight: calcEBMEnergyEffDefRight,
        totalCalcEBMEnergyEffDefLeft: totalCalcEBMEnergyEffDefLeft,
        totalCalcEBMEnergyEffDefRight: totalCalcEBMEnergyEffDefRight,
      },
    };
  },
});

//Prepopulation Json
export const getConvertedJson = selector({
  key: "getConvertedJson",
  get: ({ get }) => {
    let trialDetails = {};
    let resData = deepCopy(get(selectedTrialObj));
    if (resData !== null && Object.keys(resData).length > 0) {
      const furnaceDet = resData["batch_info"]["BatchData_Inputs"];
      const gazDet =
        resData["natual_gas_properties"]["Natural_gas_properties"][
        "Consumption Gas"
        ];
      const fuelDet =
        resData["heavy_oil_properties"]["Heavy_Oil_Properties"][
        "Consumption Oil"
        ];
      trialDetails = {
        IDS: {
          customer_info_id: resData.customer_info_id,
          fuel_info_id: resData.fuel_info_id,
          furnace_detail_id: resData.furnace_detail_id,
          fumes_composition_id: resData.fumes_composition_id,
          thermal_balance_id: resData.thermal_balance_id,
          dust_analysis_id: resData.dust_analysis_id,
        },
        CustomerCreate: {
          customerName: resData["customer_name"],
          plantName: resData["plant"],
          date: resData["trial_date"],
          furnaceName: resData["furnace"],
          furnaceType: resData["furnace_type"],
          numberOfPorts: resData["no_of_port"],
          chamberType: resData["chamber_type"],
          country: resData["country"],
        },
        flowsProperties: {
          gaz: {
            status:
              resData["natual_gas_properties"]["Natural_gas_properties"][
              "status"
              ],
            volPercentage:
              resData["natual_gas_properties"]["Natural_gas_properties"][
              "Vol(%)"
              ],
            consumption: gazDet["Consumption Gas(Nm3/h)"],
          },
          oil: {
            status:
              resData["heavy_oil_properties"]["Heavy_Oil_Properties"]["status"],
            analysisPercMass:
              resData["heavy_oil_properties"]["Heavy_Oil_Properties"][
              "Analysis"
              ]["Mass%"],
            consumption: fuelDet["Consumption Oil(kg/h)"],
          },
        },
        BatchData: {
          pullRate:
            resData["batch_info"]["BatchData_Inputs"]["Pull rate (t/d)"],
          cullet: resData["batch_info"]["BatchData_Inputs"]["Cullet (%)"],
          h2oPercBatch:
            resData["batch_info"]["BatchData_Inputs"]["H2O in batch (%)"],
          ignitionLoss:
            resData["batch_info"]["BatchData_Inputs"]["Loss on ignition (%)"],
          distribution: furnaceDet["DGC Distribution(%)"],
        },
        RegeneratorDesign: resData["regenerator_properties"],
        Temperatures: resData["temperatures"],
        FumesComposition: resData["composition"],
        DustAnalysis: resData["mesurements_by_port"]["mesurements_by_port"],
      };
    } else {
      trialDetails = {
        customerInfo: {
          trial_date: new Date(),
        },
      };
    }
    return trialDetails;
  },
});