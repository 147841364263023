import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = (data) => {
  const chartData = {
    series: data.series.map((item) => Number(item.total_trials)),
    options: {
      chart: {
        type: "pie",
      },
      title: {
        text: "Zone-wise Trials Data",
        style: {
          fontSize: "14px",
          fontWeight: "550",
        },
      },
      labels: data.series.map((item) => item.zone_name),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#F6AE2D", "#0A78A1", "#00A7A4", "#ED5958"]
    },
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="pie"
      height={350}
    />
  );
};

export default PieChart;