import React from "react";
import { useRecoilState } from "recoil";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Grid } from "@mui/material";
import {
  customerInfoData,
  errorHandle,
  error,
  fuelInfo,
  furnaceDetails,
  temparatures,
  fumesComposition,
  errorSection
} from "../../../../src/recoil/atoms";

const CustomTabs = (props) => {
  const [customerInfo] = useRecoilState(customerInfoData);
  const [furnaceData] = useRecoilState(furnaceDetails);
  const [fuelData] = useRecoilState(fuelInfo);
  const [temparatureData] = useRecoilState(temparatures);
  const [fumesData] = useRecoilState(fumesComposition);
  const [, setStepper] = useRecoilState(errorHandle);
  const [, setStepError] = useRecoilState(error);
  const [, setErrorSection] = useRecoilState(errorSection);

  const AntTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
      border: "none",
    },
    "& .MuiTabScrollButton-root": {
      color: "white",
      backgroundColor: "#203468",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
    backgroundColor: "#E8EAEF",
    color: "#212121",
    display: "flex",
    fontSize: "18px",
    flexDirection: "row",
    padding: "16px",
    gap: "12px",
    height: "48px",
    borderRadius: "0px",
    flex: "none",
    order: "0",
    flexGrow: 1,
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "-0.015em",
    justifyContent: "space-between",
    textTransform: "capitalize",
    maxWidth: 'none',

    "&:hover": {
      opacity: 1,
    },

    "&.Mui-selected": {
      backgroundColor: "#ffffff",
      borderTop: "4px solid #0842A0",
      color: "#2D2D2D",
      fontWeight: "600",
    },

    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }));

  const [value, setValue] = React.useState(0);

  const isEndFired = () => {
    if (customerInfo.furnaceType === "End Fired") {
      return true;
    } else {
      return false;
    }
  };

  const isSymmetric = () => {
    if (customerInfo.chamberType === "One Side") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event, val) => {
    let validation = false;
    let mandatorySection = "";
    let mandatoryFields = [];
    let mandatoryFieldsGaz = [
      "h2",
      "co",
      "ch4",
      "c2h4",
      "c2h6",
      "c3h6",
      "c3h8",
      "c4h8",
      "c4h10",
      "c5h12",
      "co2",
      "n2",
      "o2",
    ];
    let mandatoryFieldsOil = [
      "carbon",
      "hydrogen",
      "oxygen",
      "nitrogen",
      "sulphur",
      "humidity",
    ];
    let mandatoryFieldsBatchData = [
      "pullRate",
      "cullet",
      "h2oPercBatch",
      "ignitionLoss",
    ];

    const checkPreviousCases = (newValue) => {
      switch (newValue) {
        case 0:
          const numberOfPortsField = isEndFired() ? "" : "numberOfPorts";
          mandatoryFields = [
            "customerName",
            "plantName",
            "furnaceName",
            "furnaceType",
            numberOfPortsField,
            "chamberType",
            "country",
          ];
          validation = checkAllValues(customerInfo, mandatoryFields);
          break;
        case 1:
          // Gaz
          if (fuelData.gaz.status) {
            for (let i = 0; i < mandatoryFieldsGaz.length; i++) {
              const gazField = mandatoryFieldsGaz[i];
              const val = fuelData.gaz.volPercentage[gazField];
              if (gazField !== "" && typeof val === "string" && (!val || val.length === 0)) {
                validation = true;
                mandatorySection = "fuel";
                break;
              }
            }
          }
          // Oil
          if (fuelData.oil.status) {
            for (let i = 0; i < mandatoryFieldsOil.length; i++) {
              const oilField = mandatoryFieldsOil[i];
              const val = fuelData.oil.analysisPercMass[oilField];
              if (oilField !== "" && typeof val === "string" && (!val || val.length === 0)) {
                validation = true;
                mandatorySection = "fuel";
                break;
              }
            }
          }
          if (validation === false) {
            // Batch Data
            for (let i = 0; i < mandatoryFieldsBatchData.length; i++) {
              const batchField = mandatoryFieldsBatchData[i];
              const val = furnaceData.batchData[batchField];
              if (batchField !== "" && typeof val === "string" && (!val || val.length === 0)) {
                validation = true;
                mandatorySection = "batch";
                break;
              }
            }
          }

          if (isEndFired()) {
            if (isSymmetric()) {
              let val = fuelData.gaz?.status ? fuelData.gaz.consumptionEFF.symmetricData : "";
              let val1 = fuelData.oil?.status ? fuelData.oil.consumptionEFF.symmetricData : "";
              if ((!val?.length || !val1?.length) && typeof val === "string" && typeof val1 === "string") {
                validation = true;
                mandatorySection = "fuel";
              }

              // Batch Data
              let val2 = furnaceData.batchData.distributionEFF.symmetricData;

              if (
                (typeof val2 === "string" && !val2 && val2.length === 0)) {
                validation = true;
                mandatorySection = "batch";
              }
              // Regenerator Design
              let val3 = furnaceData.regeneratorDesign.chamberDimensionsEFF;
              // Regenerator Design
              let val4 = furnaceData.regeneratorDesign.packingPropertiesEFF;
              // Regenerator Design
              let val5 = furnaceData.regeneratorDesign.noOfChannelsEFF;
              if (
                (typeof val3.depth === "string" &&
                  !val3.depth &&
                  val3.depth.length === 0) ||
                (typeof val3.width === "string" &&
                  !val3.width &&
                  val3.width.length === 0) ||
                (typeof val4.size === "string" &&
                  !val4.size &&
                  val4.size.length === 0) ||
                (typeof val4.thickness === "string" &&
                  !val4.thickness &&
                  val4.thickness.length === 0) ||
                (typeof val5.depthChannels === "string" &&
                  !val5.depthChannels &&
                  val5.depthChannels.length === 0) ||
                (typeof val5.widthChannels === "string" &&
                  !val5.widthChannels &&
                  val5.widthChannels.length === 0)) {
                validation = true;
                mandatorySection = "regenerator";
              }
            } else {
              let val;
              if (fuelData.gaz.status) {
                val = fuelData.gaz.consumptionEFF;
              }
              let val1;
              if (fuelData.oil.status) {
                val1 = fuelData.oil.consumptionEFF;
              }

              if (
                (val &&
                  typeof val.left === "string" &&
                  !val.left &&
                  val.left.length === 0) ||
                (val &&
                  typeof val.right === "string" &&
                  !val.right &&
                  val.right.length === 0) ||
                (val1 &&
                  typeof val1.left === "string" &&
                  !val1.left &&
                  val1.left.length === 0) ||
                (val1 &&
                  typeof val1.right === "string" &&
                  !val1.right &&
                  val1.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "fuel";
              }
              // Batch Data
              let val2 = furnaceData.batchData.distributionEFF;
              if (
                (typeof val2.left === "string" &&
                  !val2.left &&
                  val2.left.length === 0) ||
                (typeof val2.right === "string" &&
                  !val2.right &&
                  val2.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "batch";
              }

              // Regenerator Design
              let val3 = furnaceData.regeneratorDesign.chamberDimensionsEFF;
              if (
                (typeof val3.depth === "string" &&
                  !val3.depth &&
                  val3.depth.length === 0) ||
                (typeof val3.width === "string" &&
                  !val3.width &&
                  val3.width.length === 0)
              ) {
                validation = true;
                mandatorySection = "regenerator";
              }

              // Regenerator Design
              let val4 = furnaceData.regeneratorDesign.packingPropertiesEFF;
              if (
                (typeof val4.size === "string" &&
                  !val4.size &&
                  val4.size.length === 0) ||
                (typeof val4.thickness === "string" &&
                  !val4.thickness &&
                  val4.thickness.length === 0)
              ) {
                validation = true;
                mandatorySection = "regenerator";
              }

              // Regenerator Design
              let val5 = furnaceData.regeneratorDesign.noOfChannelsEFF;
              if (
                (typeof val5.depthChannels === "string" &&
                  !val5.depthChannels &&
                  val5.depthChannels.length === 0) ||
                (typeof val5.widthChannels === "string" &&
                  !val5.widthChannels &&
                  val5.widthChannels.length === 0)
              ) {
                validation = true;
                mandatorySection = "regenerator";
              }

            }
          } else {
            if (isSymmetric()) {
              for (let i = 0; i <= customerInfo.numberOfPorts; i++) {
                let val;
                if (fuelData.gaz.status) {
                  val = fuelData.gaz.consumptionCFF.symmetricData;
                }
                let val1;
                if (fuelData.oil.status) {
                  val1 = fuelData.oil.consumptionCFF.symmetricData;
                }
                // Batch Data
                let val2 = furnaceData.batchData.distributionCFF.symmetricData;
                // Regenerator Design
                let val3 = furnaceData.regeneratorDesign.chamberDimensionsCFF;
                // Regenerator Design
                let val4 = furnaceData.regeneratorDesign.packingPropertiesCFF;
                // Regenerator Design
                let val5 = furnaceData.regeneratorDesign.noOfChannelsCFF;
                if (
                  (val &&
                    typeof val[i] === "string" &&
                    !val[i] &&
                    val[i].length === 0) ||
                  (val1 &&
                    typeof val1[i] === "string" &&
                    !val1[i] &&
                    val1[i].length === 0) ||
                  (val2 &&
                    typeof val2[i] === "string" &&
                    !val2[i] &&
                    val2[i].length === 0) ||
                  (val3 &&
                    typeof val3.depth[i] === "string" &&
                    !val3.depth[i] &&
                    val3.depth[i].length === 0) ||
                  (val3 &&
                    typeof val3.width[i] === "string" &&
                    !val3.width[i] &&
                    val3.width[i].length === 0) ||
                  (val4 &&
                    typeof val4.size[i] === "string" &&
                    !val4.size[i] &&
                    val4.size[i].length === 0) ||
                  (val4 &&
                    typeof val4.thickness[i] === "string" &&
                    !val4.thickness[i] &&
                    val4.thickness[i].length === 0) ||
                  (val5 &&
                    typeof val5.depthChannels[i] === "string" &&
                    !val5.depthChannels[i] &&
                    val5.depthChannels[i].length === 0) ||
                  (val5 &&
                    typeof val5.widthChannels[i] === "string" &&
                    !val5.widthChannels[i] &&
                    val5.widthChannels[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "batch";
                }
              }
            } else {
              for (let i = 0; i <= customerInfo.numberOfPorts; i++) {
                let val;
                if (fuelData.gaz.status) {
                  val = fuelData.gaz.consumptionCFF;
                }
                let val1;
                if (fuelData.oil.status) {
                  val1 = fuelData.oil.consumptionCFF;
                }
                if (
                  (val &&
                    typeof val.left[i] === "string" &&
                    !val.left[i] &&
                    val.left[i].length === 0) ||
                  (val &&
                    typeof val.right[i] === "string" &&
                    !val.right[i] &&
                    val.right[i].length === 0) ||
                  (val1 &&
                    typeof val1.left[i] === "string" &&
                    !val1.left[i] &&
                    val1.left[i].length === 0) ||
                  (val1 &&
                    typeof val1.right[i] === "string" &&
                    !val1.right[i] &&
                    val1.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "fuel";
                }
                // Batch Data
                let val2 = furnaceData.batchData.distributionCFF;
                if (
                  (typeof val2.left[i] === "string" &&
                    !val2.left[i] &&
                    val2.left[i].length === 0) ||
                  (typeof val2.right[i] === "string" &&
                    !val2.right[i] &&
                    val2.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "batch";
                }
                // Regenerator Design
                let val3 = furnaceData.regeneratorDesign.chamberDimensionsCFF;
                if (
                  (typeof val3.depth[i] === "string" &&
                    !val3.depth[i] &&
                    val3.depth[i].length === 0) ||
                  (typeof val3.width[i] === "string" &&
                    !val3.width[i] &&
                    val3.width[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "regenerator";
                }
                // Regenerator Design
                let val4 = furnaceData.regeneratorDesign.packingPropertiesCFF;
                if (
                  (typeof val4.size[i] === "string" &&
                    !val4.size[i] &&
                    val4.size[i].length === 0) ||
                  (typeof val4.thickness[i] === "string" &&
                    !val4.thickness[i] &&
                    val4.thickness[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "regenerator";
                }
                // Regenerator Design
                let val5 = furnaceData.regeneratorDesign.noOfChannelsCFF;
                if (
                  (typeof val5.depthChannels[i] === "string" &&
                    !val5.depthChannels[i] &&
                    val5.depthChannels[i].length === 0) ||
                  (typeof val5.widthChannels[i] === "string" &&
                    !val5.widthChannels[i] &&
                    val5.widthChannels[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "regenerator";
                }
              }
            }
          }
          break;
        case 2:
          // Temparatures && Fumes Composition
          if (isEndFired()) {
            if (isSymmetric()) {
              // Temparatures
              let val = temparatureData.airTemperatureEFF.top.symmetricData;
              let val2 = temparatureData.airTemperatureEFF.bottom.symmetricData;
              let val3 = temparatureData.fumesTemperatureEFF.top.symmetricData;
              let val4 =
                temparatureData.fumesTemperatureEFF.bottom.symmetricData;
              // Composition
              let val5 = fumesData.compositionEFF.o2Top.symmetricData;
              let val6 = fumesData.compositionEFF.o2Bottom.symmetricData;
              if (
                (typeof val === "string" && !val && val.length === 0) ||
                (typeof val2 === "string" && !val2 && val2.length === 0) ||
                (typeof val3 === "string" && !val3 && val3.length === 0) ||
                (typeof val4 === "string" && !val4 && val4.length === 0)
              ) {
                validation = true;
                mandatorySection = "air";
              }
              else if (
                (typeof val5 === "string" && !val5 && val5.length === 0) || (typeof val6 === "string" && !val6 && val6.length === 0)
              ) {
                validation = true;
                mandatorySection = "fumes";
              }
            } else {
              // Temparatures
              let val = temparatureData.airTemperatureEFF.top;
              if (
                (typeof val.left === "string" &&
                  !val.left &&
                  val.left.length === 0) ||
                (typeof val.right === "string" &&
                  !val.right &&
                  val.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "air";
              }
              let val2 = temparatureData.airTemperatureEFF.bottom;
              if (
                (typeof val2.left === "string" &&
                  !val2.left &&
                  val2.left.length === 0) ||
                (typeof val2.right === "string" &&
                  !val2.right &&
                  val2.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "air";
              }
              let val3 = temparatureData.fumesTemperatureEFF.top;
              if (
                (typeof val3.left === "string" &&
                  !val3.left &&
                  val3.left.length === 0) ||
                (typeof val3.right === "string" &&
                  !val3.right &&
                  val3.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "air";
              }
              let val4 = temparatureData.fumesTemperatureEFF.bottom;
              if (
                (typeof val4.left === "string" &&
                  !val4.left &&
                  val4.left.length === 0) ||
                (typeof val4.right === "string" &&
                  !val4.right &&
                  val4.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "air";
              }

              // Composition
              let val5 = fumesData.compositionEFF.o2Top;
              if (
                (typeof val5.left === "string" &&
                  !val5.left &&
                  val5.left.length === 0) ||
                (typeof val5.right === "string" &&
                  !val5.right &&
                  val5.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "fumes";
              }

              let val6 = fumesData.compositionEFF.o2Bottom;
              if (
                (typeof val6.left === "string" &&
                  !val6.left &&
                  val6.left.length === 0) ||
                (typeof val6.right === "string" &&
                  !val6.right &&
                  val6.right.length === 0)
              ) {
                validation = true;
                mandatorySection = "fumes";
              }
            }
          } else {
            if (isSymmetric()) {
              for (let i = 0; i <= customerInfo.numberOfPorts; i++) {
                // Temparatures
                let val = temparatureData.airTemperatureCFF.top.symmetricData;
                let val2 =
                  temparatureData.airTemperatureCFF.bottom.symmetricData;
                let val3 =
                  temparatureData.fumesTemperatureCFF.top.symmetricData;
                let val4 =
                  temparatureData.fumesTemperatureCFF.bottom.symmetricData;
                // Composition
                let val5 = fumesData.compositionCFF.o2Top.symmetricData;
                let val6 = fumesData.compositionCFF.o2Bottom.symmetricData;
                if (
                  (typeof val[i] === "string" &&
                    !val[i] &&
                    val[i].length === 0) ||
                  (typeof val2[i] === "string" &&
                    !val2[i] &&
                    val2[i].length === 0) ||
                  (typeof val3[i] === "string" &&
                    !val3[i] &&
                    val3[i].length === 0) ||
                  (typeof val4[i] === "string" &&
                    !val4[i] &&
                    val4[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "air";
                }
                else if (
                  (typeof val5[i] === "string" &&
                    !val5[i] &&
                    val5[i].length === 0) ||
                  (typeof val6[i] === "string" &&
                    !val6[i] &&
                    val6[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "fumes";
                }
              }
            } else {
              for (let i = 0; i <= customerInfo.numberOfPorts; i++) {
                // Temparatures
                let val = temparatureData.airTemperatureCFF.top;
                if (
                  (typeof val.left[i] === "string" &&
                    !val.left[i] &&
                    val.left[i].length === 0) ||
                  (typeof val.right[i] === "string" &&
                    !val.right[i] &&
                    val.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "air";
                }
                let val2 = temparatureData.airTemperatureCFF.bottom;
                if (
                  (typeof val2.left[i] === "string" &&
                    !val2.left[i] &&
                    val2.left[i].length === 0) ||
                  (typeof val2.right[i] === "string" &&
                    !val2.right[i] &&
                    val2.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "air";
                }
                let val3 = temparatureData.fumesTemperatureCFF.top;
                if (
                  (typeof val3.left[i] === "string" &&
                    !val3.left[i] &&
                    val3.left[i].length === 0) ||
                  (typeof val3.right[i] === "string" &&
                    !val3.right[i] &&
                    val3.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "air";
                }
                let val4 = temparatureData.fumesTemperatureCFF.bottom;
                if (
                  (typeof val4.left[i] === "string" &&
                    !val4.left[i] &&
                    val4.left[i].length === 0) ||
                  (typeof val4.right[i] === "string" &&
                    !val4.right[i] &&
                    val4.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "air";
                }

                // Composition
                let val5 = fumesData.compositionCFF.o2Top;
                if (
                  (typeof val5.left[i] === "string" &&
                    !val5.left[i] &&
                    val5.left[i].length === 0) ||
                  (typeof val5.right[i] === "string" &&
                    !val5.right[i] &&
                    val5.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "fumes";
                }
                let val6 = fumesData.compositionCFF.o2Bottom;
                if (
                  (typeof val6.left[i] === "string" &&
                    !val6.left[i] &&
                    val6.left[i].length === 0) ||
                  (typeof val6.right[i] === "string" &&
                    !val6.right[i] &&
                    val6.right[i].length === 0)
                ) {
                  validation = true;
                  mandatorySection = "fumes";
                }
              }
            }
          }
          break;
        case 3:
          break;
      }
    };

    // Perform the validation for the current case
    switch (val) {
      case 0:
        checkPreviousCases(0);
        break;
      case 1:
        checkPreviousCases(0);
        break;
      case 2:
        checkPreviousCases(1);
        break;
      case 3:
        checkPreviousCases(0);
        checkPreviousCases(1);
        checkPreviousCases(2);
        break;
      default:
        break;
    }
    console.log(validation, val, mandatorySection)
    if (validation) {
      setStepper(val);
      setStepError(true);
      setErrorSection(mandatorySection);
    } else {
      setValue(val);
      props.onChangeStep(val);
      setStepError(false);
      setErrorSection("");
    }
  };

  const checkAllValues = (value, mandatoryFields) => {
    let validationCheck = false;
    for (let i = 0; i <= mandatoryFields.length; i++) {
      if (
        mandatoryFields[i] !== "" &&
        typeof value[mandatoryFields[i]] === "string" &&
        !value[mandatoryFields[i]] &&
        value[mandatoryFields[i]].length === 0
      ) {
        return true;
      }
    }
    return validationCheck;
  };

  return (
    <Box sx={{ width: "100%" }} elevation={0}>
      <Box sx={{}}>
        <Grid
          item
          direction="column"
          alignItems="center"
          justify="center"
          style={{ marginTop: "9px" }}
        >
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            {props.tabs.map((step, index) => (
              <AntTab label={step} index={index} key={index} />
            ))}
          </AntTabs>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomTabs;