import React, { useEffect, useState } from "react";
import { Button, Grid, Box, Tooltip } from "@mui/material";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import { Add, Delete } from "@mui/icons-material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import UserForm from "./UserForm";
import SuccessDialog from "../../input/SuccessDialog";
import DeleteDialog from "../../input/DeleteDialog";
import axios from "axios";
import API from "../Services/API";

const RightContainer = () => {
  const [userData, setUserData] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [rowIndex, setRowIndex] = useState();

  const userDetails = localStorage.getItem("user");
  const userValue = JSON.parse(userDetails);
  const access_token = localStorage.getItem("accesstoken");
console.log(access_token,"access_tokenaccess_token")


  console.log("userValuesssss", userValue)
  let roleID = JSON.parse(userDetails);

  const addNewRow = () => {
    setType("Add User");
    setCurrentUsers([]);
    setOpen(true);
  };

  const onHandleEdit = (index) => {
    setType("Update User");
    setCurrentUsers(index.row);
    setOpen(true);
  };

  const onHandleDelete = (index) => {
    setType("Delete User");
    setDeleteRow(true);
    setRowIndex(index);
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      filterable: false,
      width: 150,
    },
    {
      field: "sgid",
      headerName: "SG ID",
      width: 220,
      editable: false,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 300,
      editable: false,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 300,
      editable: false,
    },
    {
      field: "zone_name",
      headerName: "Zone",
      width: 280,
      editable: false,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 220,
      editable: false,
    },
    roleID["role_id"] === 2 || roleID["role_id"] === 1 ? (
      {
        field: "Action",
        headerName: "Actions",
        sortable: false,
        width: 300,
        disableClickEventBubbling: false,
        renderCell: (index) => {
          return (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}
            >
              <Tooltip title="Edit">
                <Edit
                  id={index}
                  style={{ marginRight: "15px", cursor: "pointer" }}
                  onClick={() => {
                    onHandleEdit(index);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Delete
                  style={{ cursor: "pointer", color: "#203468" }}
                  onClick={() => onHandleDelete(index)}
                />
              </Tooltip>
            </div>
          );
        },
      }
    ) : (
      <></>
    ),
  ];

  const getZoneName = (zone) => {
    switch (zone) {
      case 1:
        return "America";
      case 2:
        return "Asia";
      case 3:
        return "Europe";
      default:
        return "Central";
    }
  };
  const getRoleName = (role) => {
    switch (role) {
      case 1:
        return "Super Admin";
      case 2:
        return "Admin";
      default:
        return "User";
    }
  };
  const getUserDetails = () => {
    const userId = JSON.parse(localStorage.getItem("user"));
    const sgid = userId.sgid;
    axios
      .get(`${API.GET_USER_DETAILS}?sgid=${sgid}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.user?.length > 0) {
          const newArray = response.data.data.user.map((u, i) => {
            let object = { ...u };
            object["zone_name"] = getZoneName(u.zone_id);
            object["zone_id"] = u.zone_id;
            object["role_name"] = getRoleName(u.role_id);
            object["role_id"] = u.role_id;
            object["sno"] = i + 1;
            return object;
          });
          setUserData(newArray);
          console.log(userData, "userData");
        } else {
          setUserData([]);
        }
      })
      .catch(function (error) {
        console.log("ERROR", error);
      });
  };

  const getOtherUserDetails = () => {
    axios
      .get(API.GET_OTHER_USER_DETAILS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.user?.length > 0) {
          const newArray = response.data.data.user.map((u, i) => {
            let object = { ...u };
            object["zone_name"] = getZoneName(u.zone_id);
            object["zone_id"] = u.zone_id;
            object["role_name"] = getRoleName(u.role_id);
            object["role_id"] = u.role_id;
            object["sno"] = i + 1;
            return object;
          });
          setTableData(newArray);
        } else {
          setTableData([]);
        }
      })
      .catch(function (error) {
        console.log("ERROR", error);
      });
  };

  const handleClose = () => {
    getUserDetails();
    getOtherUserDetails();
    setOpen(false);
    setDeleteRow(false);
  };

  const rowDelete = async (userData) => {
    let id = userData.id;
    try {
      await axios.delete(`${API.DELETE_USER}?id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      }).then((res) => {
        if (res.status === 200) {
          handleClose();
          getOtherUserDetails();
          onHandleAlert();
        }
      }).catch((error) => {
        console.log("ERROR", error);
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }
  useEffect(() => {
    getUserDetails();
    getOtherUserDetails();
  }, []);

  const setStatusValue = (value) => {
    setStatus(value);
    console.log(status, "status")
  };

  const onHandleAlert = () => {
    setShow(true);
  };

  const onHandleClosed = () => {
    setShow(false);
    setDeleteRow(false);
  };
  let message = "";
  if (type === "Add User") {
    message = "User Added Successfully";
  } else if (type === "Update User") {
    message = "User Updated Successfully";
  } else if (type === "Delete User") {
    message = "User Deleted Successfully";
  }

  return (
    <div>
      <SuccessDialog
        open={show}
        onClose={onHandleClosed}
        msg={message}
      />
      <DeleteDialog
        open={deleteRow}
        onClose={onHandleClosed}
        handleDelete={() => rowDelete(rowIndex)}
      />
      <UserForm
        open={open}
        handleClose={handleClose}
        type={type}
        data={currentUsers}
        setStatus={setStatusValue}
        onHandleAlert={onHandleAlert}
      />

      <Grid container style={{ marginTop: 30 }}>
        <Grid item xs={12}>
          <Box sx={{ height: 453, width: "100%" }}>
            <DataGrid
              rows={tableData && tableData.length > 0 ? tableData : []}
              columns={columns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              components={{
                Toolbar: (props) => (
                  <div style={{ display: "flex", justifyContent: "space-between", padding: 8 }}>
                    <GridToolbarQuickFilter {...props} />
                    {roleID["role_id"] === 2 || roleID["role_id"] === 1 ? (
                      <Button
                        variant="contained"
                        startIcon={<Add />}
                        style={{
                          backgroundColor: "#203468",
                          textTransform: "capitalize",
                        }}
                        onClick={addNewRow}
                      >
                        Add User
                      </Button>
                    ) : null}
                  </div>
                ),
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RightContainer;