import { atom } from "recoil";

export const drawerMode = atom({
  key: "drawerMode",
  default: false,
});

export const themeMode = atom({
  key: "themeMode",
  default: "light",
});

export const activeStep = atom({
  key: "activeStep",
  default: 0,
});

export const user = atom({
  key: "user",
  default: {
    sgid: "",
    name: "",
    lastName: "",
    id: "",
    role: "",
    roleId: "",
    zone: "",
  },
});

export const menuList = atom({
  key: "menuList",
  default: "Case",
});

export const actionType = atom({
  key: "actionType",
  default: "create",
});

export const errorHandle = atom({
  key: "errorHandle",
  default: 0,
});

export const errorSection = atom({
  key: "errorSection",
  default: "",
});
export const error = atom({
  key: "error",
  default: false,
});

// Customer Info
export const customerInfoData = atom({
  key: "customerInfoData",
  default: {
    customerName: "",
    plantName: "",
    furnaceName: "",
    furnaceType: "",
    numberOfPorts: "",
    country: "",
    date: new Date().toISOString(),
    chamberType: "",
  },
});

// Cross Fired
export const portObj = (num) => {
  let tmpVal = num ? 0 : "";
  return {
    1: tmpVal,
    2: tmpVal,
    3: tmpVal,
    4: tmpVal,
    5: tmpVal,
    6: tmpVal,
    7: tmpVal,
    8: tmpVal,
  };
};

// Flows Info
export const fuelInfo = atom({
  key: "fuelInfo",
  default: {
    gaz: {
      status: true,
      volPercentage: {
        h2: "",
        co: "",
        ch4: "",
        c2h4: "",
        c2h6: "",
        c3h6: "",
        c3h8: "",
        c4h8: "",
        c4h10: "",
        c5h12: "",
        co2: "",
        n2: "",
        o2: "",
      },
      density: {
        h2: "0.0695",
        co: "0.968",
        ch4: "0.555",
        c2h4: "0.976",
        c2h6: "1.048",
        c3h6: "1.48",
        c3h8: "1.557",
        c4h8: "2.007",
        c4h10: "2.096",
        c5h12: "2.671",
        co2: "1.529",
        n2: "0.968",
        o2: "1.105",
      },
      airCombustion: {
        h2: "2.36",
        co: "2.38",
        ch4: "9.54",
        c2h4: "14.4",
        c2h6: "16.84",
        c3h6: "21.84",
        c3h8: "24.37",
        c4h8: "29.64",
        c4h10: "32.41",
        c5h12: "40.87",
        co2: "0",
        n2: "0",
        o2: "-4.77",
      },
      airCombustionEFF: {
        h2: "2.38",
        co: "4.76",
        ch4: "9.52",
        c2h4: "14.29",
        c2h6: "16.67",
        c3h6: "21.43",
        c3h8: "23.81",
        c4h8: "28.57",
        c4h10: "30.95",
        c5h12: "38.10",
        co2: "0",
        n2: "0",
        o2: "-4.77",
      },
      combustionFumes: {
        co2: {
          h2: "0",
          co: "1",
          ch4: "1",
          c2h4: "2",
          c2h6: "2",
          c3h6: "3",
          c3h8: "3",
          c4h8: "4",
          c4h10: "4",
          c5h12: "5",
          co2: "1",
          n2: "0",
          o2: "0",
        },
        h2o: {
          h2: "1",
          co: "0",
          ch4: "2",
          c2h4: "2",
          c2h6: "3",
          c3h6: "3",
          c3h8: "4",
          c4h8: "4",
          c4h10: "5",
          c5h12: "6",
          co2: "0",
          n2: "0",
          o2: "0",
        },
      },
      combustionHeat: {
        lhv: {
          h2: "2.96",
          co: "3.51",
          ch4: "9.97",
          c2h4: "16.53",
          c2h6: "17.88",
          c3h6: "24.34",
          c3h8: "25.94",
          c4h8: "32.68",
          c4h10: "34.49",
          c5h12: "43.52",
          co2: "0",
          n2: "0",
          o2: "0",
        },
        hhv: {
          h2: "3.52",
          co: "3.51",
          ch4: "11.08",
          c2h4: "17.65",
          c2h6: "19.58",
          c3h6: "26.06",
          c3h8: "28.22",
          c4h8: "34.99",
          c4h10: "37.41",
          c5h12: "47.11",
          co2: "0",
          n2: "0",
          o2: "0",
        },
      },
      consumptionCFF: {
        left: portObj(),
        right: portObj(),
        symmetricData: portObj(),
      },
      consumptionEFF: {
        left: "",
        right: "",
        symmetricData: "",
      },
    },
    oil: {
      status: false,
      analysisPercMass: {
        carbon: "",
        hydrogen: "",
        oxygen: "",
        nitrogen: "",
        sulphur: "",
        humidity: "",
      },
      consumptionCFF: {
        left: portObj(),
        right: portObj(),
        symmetricData: portObj(),
      },
      consumptionEFF: {
        left: "",
        right: "",
        symmetricData: "",
      },
    },
  },
});

// Furnace Details
export const noOfPortObj = () => {
  return {
    1: "10.69",
    2: "10.55",
    3: "10.60",
    4: "10.64",
    5: "10.76",
    6: "0",
    7: "0",
    8: "0",
  };
};

export const furnaceDetails = atom({
  key: "furnaceDetails",
  default: {
    batchData: {
      pullRate: "",
      cullet: "",
      h2oPercBatch: "",
      ignitionLoss: "",
      distributionCFF: {
        left: portObj(),
        right: portObj(),
        symmetricData: portObj(),
      },
      distributionEFF: {
        left: "",
        right: "",
        symmetricData: "",
      },
      //Need to check this later
      correlationCFF: {
        left: noOfPortObj(),
        right: noOfPortObj(),
        symmetricData: noOfPortObj(),
      },
      correlationEFF: {
        left: "12.02",
        right: "11.28",
        symmetricData: "12.02",
      },
    },
    regeneratorDesign: {
      chamberDimensionsCFF: {
        depth: portObj(),
        width: portObj(),
      },
      packingPropertiesCFF: {
        size: portObj(),
        thickness: portObj(),
      },
      noOfChannelsCFF: {
        depthChannels: portObj(),
        widthChannels: portObj(),
      },
      chamberDimensionsEFF: {
        depth: "",
        width: "",
      },
      packingPropertiesEFF: {
        size: "",
        thickness: "",
      },
      noOfChannelsEFF: {
        depthChannels: "",
        widthChannels: "",
      }
    },
  },
});

// Temperatures
export const temparatureTypes = (val) => {
  return {
    left: val === "EFF" ? "" : portObj(),
    right: val === "EFF" ? "" : portObj(),
    symmetricData: val === "EFF" ? "" : portObj(),
  };
};

export const temparatures = atom({
  key: "temparatures",
  default: {
    airTemperatureCFF: {
      top: temparatureTypes("CFF"),
      bottom: temparatureTypes("CFF"),
      symmetricData: temparatureTypes("CFF"),
    },
    fumesTemperatureCFF: {
      top: temparatureTypes("CFF"),
      bottom: temparatureTypes("CFF"),
      symmetricData: temparatureTypes("CFF"),
    },
    airTemperatureEFF: {
      top: temparatureTypes("EFF"),
      bottom: temparatureTypes("EFF"),
      symmetricData: "",
    },
    fumesTemperatureEFF: {
      top: temparatureTypes("EFF"),
      bottom: temparatureTypes("EFF"),
      symmetricData: "",
    },
  },
});

// Fumes Composition
export const compositionTypes = (num) => {
  let tmpVal = num ? 0 : "";
  return {
    co2: tmpVal,
    h2o: tmpVal,
    o2: tmpVal,
    o2Bottom: tmpVal,
    o2Top: tmpVal,
  };
};

export const fumesCompoTypes = (val) => {
  return {
    left: val === "EFF" ? "" : portObj(),
    right: val === "EFF" ? "" : portObj(),
    symmetricData: val === "EFF" ? "" : portObj(),
  };
};

export const fumesComposition = atom({
  key: "fumesComposition",
  default: {
    compositionCFF: {
      co2: fumesCompoTypes("CFF"),
      h2o: fumesCompoTypes("CFF"),
      o2: fumesCompoTypes("CFF"),
      o2Bottom: fumesCompoTypes("CFF"),
      o2Top: fumesCompoTypes("CFF"),
    },
    compositionEFF: {
      co2: fumesCompoTypes("EFF"),
      h2o: fumesCompoTypes("EFF"),
      o2: fumesCompoTypes("EFF"),
      o2Bottom: fumesCompoTypes("EFF"),
      o2Top: fumesCompoTypes("EFF"),
    },
  },
});

// Dusts Analysis
export const carryovers = (val) => {
  return {
    sio2: val === "EFF" ? "" : portObj(),
    cao: val === "EFF" ? "" : portObj(),
    mgo: val === "EFF" ? "" : portObj(),
    al2o3: val === "EFF" ? "" : portObj(),
  };
};

export const alkalineCompounds = (val) => {
  return {
    na2o: val === "EFF" ? "" : portObj(),
    k2o: val === "EFF" ? "" : portObj(),
    so4: val === "EFF" ? "" : portObj(),
  };
};

export const othersCompounds = (val) => {
  return {
    p2o5: val === "EFF" ? "" : portObj(),
    cr2o3: val === "EFF" ? "" : portObj(),
    fe2o3: val === "EFF" ? "" : portObj(),
    pbo: val === "EFF" ? "" : portObj(),
    v2o5: val === "EFF" ? "" : portObj(),
    cl: val === "EFF" ? "" : portObj(),
    no3: val === "EFF" ? "" : portObj(),
  };
};

export const dustsAnalysis = atom({
  key: "dustsAnalysis",
  default: {
    carryoversCFF: carryovers("CFF"),
    alkalineCompoundsCFF: alkalineCompounds("CFF"),
    othersCFF: othersCompounds("CFF"),
    carryoversEFF: carryovers("EFF"),
    alkalineCompoundsEFF: alkalineCompounds("EFF"),
    othersEFF: othersCompounds("EFF"),
  },
});

//Efficiencies Fumes Elementary Correlation & Measured Fumes Correlation
export const correlationTypes = (val1, val2, val3, val4) => {
  return {
    A: val1,
    B: val2,
    C: val3,
    D: val4,
  };
};

export const fumesElemCorrelation = atom({
  key: "fumesElemCorrelation",
  default: {
    co2: correlationTypes("841.4", "0.5932", "-0.0002415", "0.00000004523"),
    n2: correlationTypes("938.9", "0.3018", "-0.00008109", "0.000000008264"),
    h2o: correlationTypes("1610", "0.7405", "-0.00000913", "-0.00000003814"),
    o2: correlationTypes("811.2", "0.4108", "-0.0001751", "0.00000003758"),
    airCorrelation: correlationTypes(
      "935.3",
      "0.1686",
      "0.00006805",
      "-0.00000003887"
    ),
  },
});

export const newVisitStatus = atom({
  key: "newVisitStatus",
  default: true,
});

export const selectedTrialObj = atom({
  key: "selectedTrialObj",
  default: {},
});

export const getTrialDataID = atom({
  key: "getTrialDataID",
  default: {
    customer_info_id: "",
    fuel_info_id: "",
    furnace_details_id: "",
    thermal_balance_id: "",
    fumes_composition_id: "",
    dust_analysis_id: "",
  },
});

export const savedTrialID = atom({
  key: "savedTrialID",
  default: "",
});

export const fuelStatus = atom({
  key: "fuelStatus",
  default: [],
});


//  new

export const apiTimeout = atom({
  key: "apiTimeout",
  default: false,
});

export const numberStatus = atom({
  key: 'serialNumber',
  default: false
});
export const menuChange = atom({
  key: 'menuChange',
  default: false
});
export const isUpdateBeforeStatus = atom({
  key: 'update',
  default: false
});
export const isUpdateAfterStatus = atom({
  key: 'updateAfter',
  default: false
});
  export const imageType = atom({
    key: "imageType",
    default: '',
  });
  export const afterImageType = atom({
    key: "afterImageType",
    default: '',
  });
  export const fileName = atom({
    key: "fileName",
    default: '',
  });
  export const beforeImageData = atom({
    key: "beforeImageData",
    default: '',
  });
  export const recordInfo = atom({
    key: "recordInfo",
    default: '',
  });
  export const reportData = atom({
    key: "reportData",
    default: '',
  });
  export const beforeImageDescriptionState = atom({
    key: 'beforeImageDescriptionState',
    default: '', // Set the initial value to an empty string
  });
  
  export const afterImageDescriptionState = atom({
    key: 'afterImageDescriptionState',
    default: '', // Set the initial value to an empty string
  });
  export const kaizenDataUpdateState = atom({
    key: 'kaizenDataUpdateState',
    default: '', // Set the initial value to an empty string
  });

  export const newKaizenUpdatestate = atom({
    key: 'newKaizenUpdateState',
    default: {}, // Set the initial value to an empty string
  });
