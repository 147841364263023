import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  newVisitStatus,
  selectedTrialObj,
  customerInfoData,
  fuelInfo,
  furnaceDetails,
  fumesComposition,
  temparatures,
  dustsAnalysis,
  getTrialDataID,
  savedTrialID,
  activeStep,
  actionType,
} from "../../recoil/atoms";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import configParam from "../../config";
import DataTable from "../../components/ui/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { getConvertedJson } from "../../recoil/selectors";
import { Download, Delete } from "@mui/icons-material";
import DeleteDialog from "../../input/DeleteDialog";
import axios from "axios";
import API from "../Services/API";
import { Grid, Skeleton, Tooltip } from "@mui/material";
// import generateExcelFile from "../CrossFired/Stepper/Summary/GenerateExcel";

const SavedTrials = () => {
  const [tableData, setTableData] = useState([]);
  const [, setVisitStatus] = useRecoilState(newVisitStatus);
  const [selectTrialObj, setSelectTrialObj] = useRecoilState(selectedTrialObj);
  const convertedJSON = useRecoilValue(getConvertedJson);
  const [, setCustInfo] = useRecoilState(customerInfoData);
  const [fuelInfoDet, setFuelInfoDet] = useRecoilState(fuelInfo);
  const [furnaceDet, setFurnaceDet] = useRecoilState(furnaceDetails);
  const [fumesCompo, setFumesCompo] = useRecoilState(fumesComposition);
  const [temperatures, setTemperatures] = useRecoilState(temparatures);
  const [dusts, setDusts] = useRecoilState(dustsAnalysis);
  const [trialDataID, setTrialDataID] = useRecoilState(getTrialDataID);
  const [deleteRow, setDeleteRow] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [trialID, setTrialID] = useState(false);
  const [, setTrial_Id] = useRecoilState(savedTrialID);
  const [, setActiveStep] = useRecoilState(activeStep);
  const [, setType] = useRecoilState(actionType);
  const deepCopy = configParam.DEEP_COPY;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const userDetails = localStorage.getItem("user");
  const userValue = JSON.parse(userDetails);
  const access_token = localStorage.getItem("accesstoken");




  const visitNo = configParam.VISIT_NO;

  const portObj = (num) => {
    let tmpVal = num ? 0 : "";
    return {
      1: tmpVal,
      2: tmpVal,
      3: tmpVal,
      4: tmpVal,
      5: tmpVal,
      6: tmpVal,
      7: tmpVal,
      8: tmpVal,
    };
  };

  const onHandleSavedEdit = (trial_id) => {
    axios
      .get(`${API.GET_TRIAL}?trial_id=${trial_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.trials?.length > 0) {
          const trial = response.data.data.trials[0];
          setSelectTrialObj(trial);
          setActiveStep(0);
          let tempObj = deepCopy(trialDataID);
          tempObj.customer_info_id = trial.customer_info_id;
          tempObj.fuel_info_id = trial.fuel_info_id;
          tempObj.furnace_details_id = trial.furnace_details_id;
          tempObj.thermal_balance_id = trial.thermal_balance_id;
          tempObj.fumes_composition_id = trial.fumes_composition_id;
          tempObj.dust_analysis_id = trial.dust_analysis_id;
          setTrialDataID(tempObj);
          setVisitStatus(false);
          setTrial_Id(trial_id);
          let visit = visitNo();
          localStorage.setItem("visitNumber", visit || 0);
          setType("edit");
          navigate(`/AddCustomerInfo`);
        }
      });
  };

  const onHandleDownload = (trial_id) => {
    axios
      .get(`${API.GET_TRIAL}?trial_id=${trial_id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          }       
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.trials?.length > 0) {
          setSelectTrialObj(response.data.data.trials[0]);
          // generateExcelFile(response.data);
        }
      });
  }

  const onHandleDelete = (val, index) => {
    setDeleteRow(true);
    setRowIndex(index);
    console.log(rowIndex, "rowIndex");
    setTrialID(val.trial_id);
  };

  const onHandleClosed = () => {
    setDeleteRow(false);
  };

  const rowDelete = async (trial_id) => {
    try {
      await axios.delete(
        `${API.DELETE_TRIALS}?id=${trial_id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      }
      ).then((res) => {
        if (res.status === 200) {
          onHandleClosed();
          loadSavedVisits();
        }
      }).catch((error) => {
        console.log("ERROR", error);
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  const formatData = (data) => {
    let resultArray = [];
    data.map((val, index) => {
      let [trDate] = val.trialdate ? val.trialdate.split("T") : "";
      let [upDate] = val.updated_at ? val.updated_at.split("T") : "";
      let createdBy = val.createdby ? val.firstname + " " + val.lastname : "";
      let actions = (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer", width: "100px" }}
        >
          <Tooltip title="Edit">
            <Edit
              style={{ cursor: "pointer", margin: "3%" }}
              onClick={() => onHandleSavedEdit(val.trial_id)}
            />
          </Tooltip>
          <Tooltip title="Download">
            <Download
              style={{ cursor: "pointer", margin: "3%" }}
              onClick={() => onHandleDownload(val.trial_id)} />
          </Tooltip>
          <Tooltip title="Edit">
            <Delete
              style={{ margin: "3%", cursor: "pointer", color: "#203468" }}
              onClick={() => onHandleDelete(val, index)}
            />
          </Tooltip>
        </div>
      );
      resultArray.push([
        index + 1,
        val.customername,
        val.plant,
        trDate,
        val.furnace,
        val.furnacetype,
        val.noofport > 0 ? val.noofport : "NA",
        val.chambertype,
        val.country,
        createdBy,
        // crDate,
        upDate,
        actions,
      ]);
      return null;
    });
    return resultArray;
  };

  const loadSavedVisits = () => {
    axios
      .get(API.GET_ALL_TRIALS_LIST, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        }
      })
      .then(function (response) {
        if (response.status === 200 && response.data?.data?.trials?.length > 0) {
          setTableData(formatData(response.data.data.trials));
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log("ERROR", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadSavedVisits();
  }, []);

  useEffect(() => {
    if (selectTrialObj && Object.keys(selectTrialObj).length > 0) {
      let tempConvertedJson = deepCopy(convertedJSON);
      const custDet = tempConvertedJson.CustomerCreate;
      setCustInfo(tempConvertedJson.CustomerCreate);

      let tempFuelInfoDetails = deepCopy(fuelInfoDet);
      tempFuelInfoDetails.gaz["volPercentage"] =
        tempConvertedJson.flowsProperties.gaz["volPercentage"];
      tempFuelInfoDetails.oil["analysisPercMass"] =
        tempConvertedJson.flowsProperties.oil["analysisPercMass"];
      if (custDet.furnaceType === "Cross Fired") {
        if (custDet["chamberType"] === "Both Side") {
          tempFuelInfoDetails.gaz["consumptionCFF"] = {
            ...tempConvertedJson.flowsProperties.gaz["consumption"],
            symmetricData: portObj(),
          };
          tempFuelInfoDetails.oil["consumptionCFF"] = {
            ...tempConvertedJson.flowsProperties.oil["consumption"],
            symmetricData: portObj(),
          };
        } else {
          tempFuelInfoDetails.gaz["consumptionCFF"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.flowsProperties.gaz["consumption"],
          };
          tempFuelInfoDetails.oil["consumptionCFF"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.flowsProperties.oil["consumption"],
          };
        }
      } else {
        if (custDet["chamberType"] === "Both Side") {
          tempFuelInfoDetails.gaz["consumptionEFF"] = {
            ...tempConvertedJson.flowsProperties.gaz["consumption"],
            symmetricData: "",
          };
          tempFuelInfoDetails.oil["consumptionEFF"] = {
            ...tempConvertedJson.flowsProperties.oil["consumption"],
            symmetricData: "",
          };
        } else {
          tempFuelInfoDetails.gaz["consumptionEFF"] = {
            left: "",
            right: "",
            ...tempConvertedJson.flowsProperties.gaz["consumption"],
          };
          tempFuelInfoDetails.oil["consumptionEFF"] = {
            left: "",
            right: "",
            ...tempConvertedJson.flowsProperties.oil["consumption"],
          };
        }
      }
      setFuelInfoDet(tempFuelInfoDetails);

      let tempFurnaceDetails = deepCopy(furnaceDet);
      tempFurnaceDetails.batchData["pullRate"] =
        tempConvertedJson.BatchData["pullRate"];
      tempFurnaceDetails.batchData["cullet"] =
        tempConvertedJson.BatchData["cullet"];
      tempFurnaceDetails.batchData["h2oPercBatch"] =
        tempConvertedJson.BatchData["h2oPercBatch"];
      tempFurnaceDetails.batchData["ignitionLoss"] =
        tempConvertedJson.BatchData["ignitionLoss"];

      if (custDet.furnaceType === "Cross Fired") {
        if (custDet["chamberType"] === "Both Side") {
          tempFurnaceDetails.batchData["distributionCFF"] = {
            ...tempConvertedJson.BatchData["distribution"],
            symmetricData: portObj(),
          };
        } else {
          tempFurnaceDetails.batchData["distributionCFF"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.BatchData["distribution"],
          };
        }

        tempFurnaceDetails.regeneratorDesign["chamberDimensionsCFF"] = {
          depth:
            tempConvertedJson.RegeneratorDesign["Chamber_Dimensions"][
            "depth(mm)"
            ],
          width:
            tempConvertedJson.RegeneratorDesign["Chamber_Dimensions"][
            "width(mm)"
            ],
        };

        tempFurnaceDetails.regeneratorDesign["packingPropertiesCFF"] = {
          size: tempConvertedJson.RegeneratorDesign[
            "Packing_Properties_Inputs"
          ]["Channel size(mm)"],
          thickness:
            tempConvertedJson.RegeneratorDesign["Packing_Properties_Inputs"][
            "Checkers thickness(mm)"
            ],
        };

        tempFurnaceDetails.regeneratorDesign["noOfChannelsCFF"] = {
          depthChannels:
            tempConvertedJson.RegeneratorDesign["NoOfChannels_Inputs"][
            "depthChannels(mm)"
            ],
          widthChannels:
            tempConvertedJson.RegeneratorDesign["NoOfChannels_Inputs"][
            "widthChannels(mm)"
            ],
        };
      } else {
        if (custDet["chamberType"] === "Both Side") {
          tempFurnaceDetails.batchData["distributionEFF"] = {
            ...tempConvertedJson.BatchData["distribution"],
            symmetricData: "",
          };
        } else {
          tempFurnaceDetails.batchData["distributionEFF"] = {
            left: "",
            right: "",
            ...tempConvertedJson.BatchData["distribution"],
          };
        }

        tempFurnaceDetails.regeneratorDesign["chamberDimensionsEFF"] = {
          depth:
            tempConvertedJson.RegeneratorDesign["Chamber_Dimensions"][
            "depth(mm)"
            ],
          width:
            tempConvertedJson.RegeneratorDesign["Chamber_Dimensions"][
            "width(mm)"
            ],
        };

        tempFurnaceDetails.regeneratorDesign["packingPropertiesEFF"] = {
          size: tempConvertedJson.RegeneratorDesign[
            "Packing_Properties_Inputs"
          ]["Channel size(mm)"],
          thickness:
            tempConvertedJson.RegeneratorDesign["Packing_Properties_Inputs"][
            "Checkers thickness(mm)"
            ],
        };

        tempFurnaceDetails.regeneratorDesign["noOfChannelsEFF"] = {
          depthChannels: tempConvertedJson.RegeneratorDesign[
            "NoOfChannels_Inputs"
          ]["depthChannels(mm)"],
          widthChannels:
            tempConvertedJson.RegeneratorDesign["NoOfChannels_Inputs"][
            "widthChannels(mm)"
            ],
        };
      }
      setFurnaceDet(tempFurnaceDetails);

      let temperatureDetails = deepCopy(temperatures);
      if (custDet.furnaceType === "Cross Fired") {
        if (custDet["chamberType"] === "Both Side") {
          temperatureDetails["airTemperatureCFF"]["bottom"] = {
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Bottom(cold air)[°C]"
            ],
            symmetricData: portObj(),
          };
          temperatureDetails["airTemperatureCFF"]["top"] = {
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Top(preheated air)[°C]"
            ],
            symmetricData: portObj(),
          };
          temperatureDetails["fumesTemperatureCFF"]["bottom"] = {
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Bottom(cold fumes)[°C]"
            ],
            symmetricData: portObj(),
          };
          temperatureDetails["fumesTemperatureCFF"]["top"] = {
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Top(hot fumes)[°C]"
            ],
            symmetricData: portObj(),
          };
        } else {
          temperatureDetails["airTemperatureCFF"]["bottom"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Bottom(cold air)[°C]"
            ],
          };
          temperatureDetails["airTemperatureCFF"]["top"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Top(preheated air)[°C]"
            ],
          };
          temperatureDetails["fumesTemperatureCFF"]["bottom"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Bottom(cold fumes)[°C]"
            ],
          };
          temperatureDetails["fumesTemperatureCFF"]["top"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Top(hot fumes)[°C]"
            ],
          };
        }
      } else {
        if (custDet["chamberType"] === "Both Side") {
          temperatureDetails["airTemperatureEFF"]["bottom"] = {
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Bottom(cold air)[°C]"
            ],
            symmetricData: "",
          };
          temperatureDetails["airTemperatureEFF"]["top"] = {
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Top(preheated air)[°C]"
            ],
            symmetricData: "",
          };
          temperatureDetails["fumesTemperatureEFF"]["bottom"] = {
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Bottom(cold fumes)[°C]"
            ],
            symmetricData: "",
          };
          temperatureDetails["fumesTemperatureEFF"]["top"] = {
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Top(hot fumes)[°C]"
            ],
            symmetricData: "",
          };
        } else {
          temperatureDetails["airTemperatureEFF"]["bottom"] = {
            left: "",
            right: "",
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Bottom(cold air)[°C]"
            ],
          };
          temperatureDetails["airTemperatureEFF"]["top"] = {
            left: "",
            right: "",
            ...tempConvertedJson.Temperatures["Air_Temperatures"][
            "Top(preheated air)[°C]"
            ],
          };
          temperatureDetails["fumesTemperatureEFF"]["bottom"] = {
            left: "",
            right: "",
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Bottom(cold fumes)[°C]"
            ],
          };
          temperatureDetails["fumesTemperatureEFF"]["top"] = {
            left: "",
            right: "",
            ...tempConvertedJson.Temperatures["Fumes_Temperature"][
            "Top(hot fumes)[°C]"
            ],
          };
        }
      }
      setTemperatures(temperatureDetails);

      let tempFumesCompoDetails = deepCopy(fumesCompo);
      if (custDet.furnaceType === "Cross Fired") {
        if (custDet["chamberType"] === "Both Side") {
          tempFumesCompoDetails["compositionCFF"]["co2"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "CO2 concentration[vol%]"
            ],
            symmetricData: portObj(),
          };
          tempFumesCompoDetails["compositionCFF"]["h2o"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "H2O concentration[vol%]"
            ],
            symmetricData: portObj(),
          };
          tempFumesCompoDetails["compositionCFF"]["o2"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 concentration[vol%]"
            ],
            symmetricData: portObj(),
          };
          tempFumesCompoDetails["compositionCFF"]["o2Bottom"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 measured bottom%"
            ],
            symmetricData: portObj(),
          };
          tempFumesCompoDetails["compositionCFF"]["o2Top"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 Dry air Top%"
            ],
            symmetricData: portObj(),
          };
        } else {
          tempFumesCompoDetails["compositionCFF"]["co2"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "CO2 concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionCFF"]["h2o"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "H2O concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionCFF"]["o2"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionCFF"]["o2Bottom"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 measured bottom%"
            ],
          };
          tempFumesCompoDetails["compositionCFF"]["o2Top"] = {
            left: portObj(),
            right: portObj(),
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 Dry air Top%"
            ],
          };
        }
      } else {
        if (custDet["chamberType"] === "Both Side") {
          tempFumesCompoDetails["compositionEFF"]["co2"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "CO2 concentration[vol%]"
            ],
            symmetricData: "",
          };
          tempFumesCompoDetails["compositionEFF"]["h2o"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "H2O concentration[vol%]"
            ],
            symmetricData: "",
          };
          tempFumesCompoDetails["compositionEFF"]["o2"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 concentration[vol%]"
            ],
            symmetricData: "",
          };
          tempFumesCompoDetails["compositionEFF"]["o2Bottom"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 measured bottom%"
            ],
            symmetricData: "",
          };
          tempFumesCompoDetails["compositionEFF"]["o2Top"] = {
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 Dry air Top%"
            ],
            symmetricData: "",
          };
        } else {
          tempFumesCompoDetails["compositionEFF"]["co2"] = {
            left: "",
            right: "",
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "CO2 concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionEFF"]["h2o"] = {
            left: "",
            right: "",
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "H2O concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionEFF"]["o2"] = {
            left: "",
            right: "",
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 concentration[vol%]"
            ],
          };
          tempFumesCompoDetails["compositionEFF"]["o2Bottom"] = {
            left: "",
            right: "",
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 measured bottom%"
            ],
          };
          tempFumesCompoDetails["compositionEFF"]["o2Top"] = {
            left: "",
            right: "",
            ...tempConvertedJson.FumesComposition["Concentration_Inputs"][
            "O2 Dry air Top%"
            ],
          };
        }
      }
      setFumesCompo(tempFumesCompoDetails);

      let tempDustsDetails = deepCopy(dusts);
      if (custDet.furnaceType === "Cross Fired") {
        tempDustsDetails.carryoversCFF = tempConvertedJson.DustAnalysis["Carryovers"];
        tempDustsDetails.alkalineCompoundsCFF = tempConvertedJson.DustAnalysis["AlkalineCompounds"];
        tempDustsDetails.othersCFF = tempConvertedJson.DustAnalysis["OthersCompounds"];
      } else {
        tempDustsDetails.carryoversEFF = tempConvertedJson.DustAnalysis["Carryovers"];
        tempDustsDetails.alkalineCompoundsEFF = tempConvertedJson.DustAnalysis["AlkalineCompounds"];
        tempDustsDetails.othersEFF = tempConvertedJson.DustAnalysis["OthersCompounds"];
      }
      setDusts(tempDustsDetails);
    }
  }, [selectTrialObj]);

  const columns = [
    { id: "", label: "S.No" },
    { id: "", label: "Name" },
    { id: "", label: "Plant" },
    { id: "", label: "Date of Trial" },
    { id: "", label: "Furnace" },
    { id: "", label: "Furnace Type" },
    { id: "", label: "Number of Ports" },
    { id: "", label: "Chamber Type" },
    { id: "", label: "Country" },
    { id: "", label: "Created By" },
    // { id: "", label: "Created Date" },
    { id: "", label: "Updated Date" },
    { id: "", label: "Actions" },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container style={{ marginTop: "20px" }}>
            <Skeleton style={{ width: "100%", height: 450, transform: "none" }} />
          </Grid>
        </div>
      ) : (
        <div>
          <DeleteDialog
            open={deleteRow}
            onClose={onHandleClosed}
            handleDelete={() => rowDelete(trialID)}
          />
          <DataTable
            className="trials_table"
            data={tableData}
            columns={columns}
            noPagination={false}
            rowCount={10}
            noRowsPerPage={10}
          />
        </div>
      )}
    </React.Fragment>
  );
};
export default SavedTrials;