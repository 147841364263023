import * as React from "react";
import { Typography, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";

export default function TrialDate(props) {
  // Convert the date value to a Dayjs object to keep consistency
  const handleDateChange = (date) => {
    const formattedDate = date ? dayjs(date).format("YYYY-MM-DD") : null;
    props.onChange(formattedDate);
  };

  const handleTimeChange = (time) => {
    const formattedTime = time ? dayjs(time).format("HH:mm") : null;
    props.onChange(formattedTime);
  };

  return (
    <>
      <Typography style={{ fontSize: "0.95rem" }}>{props.label}</Typography>
      <FormControl style={{ width: "100%" }}>
        {!props.isDate ? (
          <DatePicker
            selected={props.value ? dayjs(props.value).toDate() : null}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="HH:mm"
            isClearable
            maxDate={props.maxLimit ? new Date(props.maxLimit) : null}
            disabled={props.readOnly ? props.readOnly : false}
            customInput={
              <TextField
                  fullWidth
                  style={{ width: "100%" }}
                size="medium"
                variant="outlined"
                error={props.error ? true : false}
                helperText={props.helperText || ""}
              />
            }
          />
        ) : (
          <DatePicker
            selected={props.value ? dayjs(props.value).toDate() : null}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            maxDate={props.maxLimit ? new Date(props.maxLimit) : null}
            isClearable
            disabled={props.readOnly ? props.readOnly : false}
            customInput={
              <TextField
                fullWidth
                style={{ width: "100%" }}
                size="medium"
                variant="outlined"
                error={props.error ? true : false}
                helperText={props.helperText || ""}
              />
            }
          />
        )}
      </FormControl>
    </>
  );
}
