import { saveAs } from "file-saver";
// import XlsxPopulate from "xlsx-populate";

function getBatchInfo(jsonData, worksheet, currentRowIndex) {
  // Batch Info
  worksheet
    .cell(currentRowIndex, 2)
    .value("Pull Rate (t/d)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("Cullet %")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("H2O% in batch")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 5)
    .value("LOI%")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });

  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value(jsonData["batch_info"]["BatchData_Inputs"]["Pull rate (t/d)"]);
  worksheet
    .cell(currentRowIndex, 3)
    .value(jsonData["batch_info"]["BatchData_Inputs"]["Cullet (%)"]);
  worksheet
    .cell(currentRowIndex, 4)
    .value(jsonData["batch_info"]["BatchData_Inputs"]["H2O in batch (%)"]);
  worksheet
    .cell(currentRowIndex, 5)
    .value(jsonData["batch_info"]["BatchData_Inputs"]["Loss on ignition (%)"]);
}

function insertvalueHrz(jsonData, worksheet, currentRowIndex, val, column) {
  // //console.log(jsonData, jsonData["furnace_type"], val, column, "insertvalueHrz val");
  if (jsonData["furnace_type"] === "Cross Fired") {
    if (val) {
      //console.log(typeof val === 'object')
      // //console.log(val, "val insertvalueHrz")
      if (typeof val === "object") {
        Object.values(val).map((key, index) => {
          worksheet.cell(currentRowIndex, column + index).value(key);
          return null;
        });
      } else if (val.includes(",")) {
        let arr = val.split(",");
        arr.map((key, index) => {
          worksheet.cell(currentRowIndex, column + index).value(key);
          return null;
        });
      }
    }
  } else {
    worksheet.cell(currentRowIndex, column).value(val);
  }
}

function insertGeneralData(
  jsonData,
  worksheet,
  currentRowIndex,
  val1,
  val2,
  col
) {
  worksheet
    .cell(currentRowIndex, col)
    .value(val1)
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, col)
    .value(val2)
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  currentRowIndex++;
}

function insertCompositionData(
  jsonData,
  worksheet,
  currentRowIndex,
  column,
  data
) {
  if (typeof data === "object") {
    Object.values(data).map((key) => {
      //console.log(key)
      worksheet.cell(currentRowIndex, column).value(key);
      currentRowIndex++;
      currentRowIndex++;
      return null;
    });
  } else if (data.includes(",")) {
    let arr = data.split(",");
    arr.map((key) => {
      //console.log(key)
      worksheet.cell(currentRowIndex, column).value(key);
      currentRowIndex++;
      currentRowIndex++;
      return null;
    });
  }
}

function insertCompositionPortsData(jsonData, worksheet, currentRowIndex, val, column, range) {
  //console.log(val, column)
  if (jsonData["furnace_type"] === "Cross Fired") {
    if (range) {
      let arr = val;
      if (typeof arr === "string") {
        arr = val.split(",")
      }
      arr.map((key) => {
        worksheet
          .range(`${range}${currentRowIndex}:${range}${currentRowIndex + 1}`)
          .style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            wrapText: true,
          })
          .value(key)
          .merged(true);
        currentRowIndex++;
        currentRowIndex++;
        return null;
      });
    } else {
      if (val) {
        if (typeof val === "object") {
          Object.values(val).map((key, index) => {
            if (typeof key === "string" && key.includes(",")) {
              let arr = key.split(",");
              arr.map((key2, index) => {
                worksheet.cell(currentRowIndex, column).value(key2);
                currentRowIndex + 2;
                return null;
              });
            }
            else {
              worksheet.cell(currentRowIndex, column).value(key);
              currentRowIndex++;
            }
            currentRowIndex++;
            return null;
          });
        } else if (val.includes(",")) {
          let arr = val.split(",");
          arr.map((key, index) => {
            worksheet.cell(currentRowIndex, column).value(key);
            currentRowIndex++;
            currentRowIndex++;
            return null;
          });
        }
      }
    }
  } else {
    if (range) {
      let arr = val;
      if (typeof arr === "string") {
        arr = val.split(",")
      }
      arr.map((key) => {
        worksheet
          .range(`${range}${currentRowIndex}:${range}${currentRowIndex + 1}`)
          .style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            wrapText: true,
          })
          .value(key)
          .merged(true);
        //console.log(jsonData, "jsonData insertGeneralData")
        currentRowIndex++;
        currentRowIndex++;
        return null;
      });
    } else {
      if (Array.isArray(val)) {
        val = val[0]
      }
      worksheet.cell(currentRowIndex, column).value(val);
    }
  }
}

function insertFumesComposition(
  jsonData,
  worksheet,
  currentRowIndex,
  val,
  col1,
  range
) {
  const symetricData = val?.symmetricData;
  const symmetricDataValue = symetricData ?? (Array.isArray(symetricData) ? symetricData[0] : "");
  if (val && jsonData["chamber_type"] === "Both Side") {
    insertCompositionPortsData(jsonData, worksheet, currentRowIndex, val.left, col1, range);
    insertCompositionPortsData(jsonData, worksheet, Number(currentRowIndex) + Number(16), val.right, col1, range);
  }
  else {
    insertCompositionPortsData(
      jsonData,
      worksheet,
      currentRowIndex,
      symmetricDataValue,
      col1,
      range
    );
  }
}

function insertFixedValueVrt(
  jsonData,
  worksheet,
  currentRowIndex,
  val,
  column,
  range
) {
  if (range) {
    let arr = val;
    arr.map((key) => {
      worksheet
        .range(`${range}${currentRowIndex}:${range}${currentRowIndex + 1}`)
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          bold: true,
          wrapText: true,
        })
        .value(key)
        .merged(true);

      insertGeneralData(
        jsonData,
        worksheet,
        currentRowIndex,
        "% vol",
        "% mass",
        28
      );
      //console.log(jsonData, "jsonData insertGeneralData")
      currentRowIndex++;
      currentRowIndex++;
      return null;
    });
  } else {
    if (val) {
      //console.log(typeof val, val)
      if (typeof val === "object") {
        Object.values(val).map((key, index) => {
          worksheet.cell(currentRowIndex, column).value(key);
          currentRowIndex++;
          return null;
        });
      } else if (Array.isArray(val)) {
        // (val.includes(",")
        let arr = val.split(",");
        arr.map((key) => {
          worksheet.cell(currentRowIndex, column).value(key);
          currentRowIndex++;
          return null;
        });
      }
      else {
        worksheet.cell(currentRowIndex, column).value(val);
      }
    }
  }
}

function getPorts(jsonData, worksheet, currentRowIndex, column) {
  if (jsonData["furnace_type"] === "Cross Fired") {
    worksheet
      .cell(currentRowIndex, column)
      .value("#1")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 1)
      .value("#2")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 2)
      .value("#3")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 3)
      .value("#4")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 4)
      .value("#5")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 5)
      .value("#6")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 6)
      .value("#7")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
    worksheet
      .cell(currentRowIndex, column + 7)
      .value("#8")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
  } else {
    worksheet
      .cell(currentRowIndex, column)
      .value("#1")
      .style({
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        wrapText: true,
      });
  }
}

function getChamberPortsData(
  jsonData,
  worksheet,
  currentRowIndex,
  val,
  col1,
  col2
) {
  // //console.log(jsonData, currentRowIndex, val, jsonData["chamber_type"])
  const symmetricDataValue = val?.symmetricData ?? (Array.isArray(val?.symmetricData) ? val.symmetricData[0] : "");
  if (jsonData["chamber_type"] === "Both Side") {
    insertvalueHrz(jsonData, worksheet, currentRowIndex, val.left, col1);
    insertvalueHrz(jsonData, worksheet, currentRowIndex, val.right, col2);
  } else {
    //console.log(Array.isArray(val), val, "value sym")
    insertvalueHrz(
      jsonData,
      worksheet,
      currentRowIndex,
      symmetricDataValue,
      col1
    );
  }
}

function getChambers(
  jsonData,
  worksheet,
  currentRowIndex,
  range1,
  range2,
  range3,
  range4,
  col1,
  col2,
  row
) {
  if (jsonData["chamber_type"] === "Both Side") {
    worksheet
      .range(`${range1}:${range2}`)
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        bold: true,
        wrapText: true,
        fill: "0d1d54",
        fontColor: "FFFFFF",
      })
      .value("LEFT SIDE")
      .merged(true);
    worksheet
      .range(`${range3}:${range4}`)
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        bold: true,
        wrapText: true,
        fill: "0d1d54",
        fontColor: "FFFFFF",
      })
      .value("RIGHT SIDE")
      .merged(true);
    currentRowIndex++;
    getPorts(jsonData, worksheet, row, col1);
    getPorts(jsonData, worksheet, row, col2);
  } else {
    worksheet
      .range(`${range1}:${range2}`)
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        bold: true,
        wrapText: true,
        fill: "0d1d54",
        fontColor: "FFFFFF",
      })
      .value("LEFT SIDE")
      .merged(true);
    currentRowIndex++;
    //console.log(currentRowIndex, row)
    getPorts(jsonData, worksheet, currentRowIndex, col1);
  }
}

function getRegeneratorInfo(jsonData, worksheet, currentRowIndex) {
  //console.log(jsonData);
  // Regenerator Design
  getPorts(jsonData, worksheet, currentRowIndex, 10);

  currentRowIndex++;
  worksheet
    .range("G7:G8")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Chambers dimensions")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 8)
    .value("Depth (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Chamber_Dimensions"]["depth(mm)"],
    10
  );

  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Width (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Chamber_Dimensions"]["width(mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .range("G9:G14")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Checkerpack")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 8)
    .value("Checker thickness (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Packing_Properties_Inputs"]["Checkers thickness(mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Channel size (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Packing_Properties_Inputs"]["Channel size(mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Number of Channels Depth (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["NoOfChannels_Inputs"]["depthChannels(mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Number of Channels Width (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["NoOfChannels_Inputs"]["widthChannels(mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Free flow area Depth (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Packing_Properties_Outputs"]["Free flow area (m2)"]["Depth (mm)"],
    10
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Free flow area Width (mm)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  insertvalueHrz(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["regenerator_properties"]["Packing_Properties_Outputs"]["Free flow area (m2)"]["Width (mm)"],
    10
  );
}

function getGasProperties(jsonData, worksheet, currentRowIndex) {
  //console.log(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["h2"])
  //console.log(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"])
  //console.log(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["co"])
  worksheet
    .range("G19:H19")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Combustion Fumes [Nm3/Gas Nm3]")
    .merged(true);
  worksheet
    .range("I19:J19")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Combustion Heat [kWh/Nm3]")
    .merged(true);
  worksheet
    .range("K19:L19")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Smoke Index [Nm3/Gas Nm3]")
    .merged(true);
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Species")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("Molecule")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("vol%")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 5)
    .value("Density")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 6)
    .value("Air/combustible ratio [Air Nm3/Gas Nm3]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 7)
    .value("CO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 8)
    .value("H2O")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value("LHV")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 10)
    .value("HHV")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 11)
    .value("Dry")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 12)
    .value("Wet")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });

  currentRowIndex++;

  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    [
      "Hydrogen",
      "Carbon monoxide",
      "Methane",
      "Ethylene",
      "Ethane",
      "Propylene",
      "Propane",
      "Butylene",
      "Butane",
      "Pentane",
      "Carbon dioxide",
      "Nitrogen",
      "Oxygen",
      "Total",
    ],
    2
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    [
      "H2",
      "CO",
      "CH4",
      "C2H4",
      "C2H6",
      "C3H6",
      "C3H8",
      "C4H8",
      "C4H10",
      "C5H12",
      "CO2",
      "N2",
      "O2",
      "Total"
    ],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["h2"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["co"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["ch4"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c2h4"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c2h6"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c3h6"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c3h8"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c4h8"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c4h10"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["c5h12"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["co2"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["n2"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["o2"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Vol(%)"]["Total"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["h2"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["co"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["ch4"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c2h4"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c2h6"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c3h6"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c3h8"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c4h8"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c4h10"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["c5h12"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["co2"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["n2"],
    5
  ); insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["o2"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Density"]["densityTotal"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Air/combustible ratio (Air Nm3/Gas Nm3)"]["h2"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Air/combustible ratio (Air Nm3/Gas Nm3)"]["co"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"]["Air/combustible ratio (Air Nm3/Gas Nm3)"]["ch4"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c2h4"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c2h6"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c3h6"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c3h8"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c4h8"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c4h10"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["c5h12"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["co2"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["n2"],
    6
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Air/combustible ratio (Air Nm3/Gas Nm3)"
    ]["o2"],
    6
  );
  if (jsonData["furnace_type"] === "Cross Fired") {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Air/combustible ratio (Air Nm3/Gas Nm3)"]["airCombustibleRatioTotal"]) {
      insertFixedValueVrt(
        jsonData,
        worksheet,
        34,
        jsonData["natual_gas_properties"]["Natural_gas_properties"][
        "Air/combustible ratio (Air Nm3/Gas Nm3)"
        ]["airCombustibleRatioTotal"],
        6
      );
    }
  } else {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Air/combustible ratio (Air Nm3/Gas Nm3)"]["airCombustibleRatioEFFTotal"]) {
      insertFixedValueVrt(
        jsonData,
        worksheet,
        34,
        jsonData["natual_gas_properties"]["Natural_gas_properties"][
        "Air/combustible ratio (Air Nm3/Gas Nm3)"
        ]["airCombustibleRatioEFFTotal"],
        6
      );
    }
  }
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["h2"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["co"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["ch4"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c2h4"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c2h6"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c3h6"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c3h8"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c4h8"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c4h10"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["c5h12"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["co2"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["n2"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["o2"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["CO2"]["combustionFumesCO2Total"],
    7
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["h2"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["co"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["ch4"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c2h4"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c2h6"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c3h6"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c3h8"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c4h8"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c4h10"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["c5h12"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["co2"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["n2"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["o2"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Fumes (Nm3/Gas Nm3)"
    ]["H2O"]["combustionFumesH2OTotal"],
    8
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["h2"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["co"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["ch4"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c2h4"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c2h6"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c3h6"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c3h8"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c4h8"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c4h10"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["c5h12"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["co2"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["n2"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["o2"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["LHV"]["CombustionHeatLHVTotal"],
    9
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    21,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["h2"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    22,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["co"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["ch4"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    24,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c2h4"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    25,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c2h6"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    26,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c3h6"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    27,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c3h8"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    28,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c4h8"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    29,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c4h10"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    30,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["c5h12"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    31,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["co2"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    32,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["n2"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    33,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["o2"],
    10
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    34,
    jsonData["natual_gas_properties"]["Natural_gas_properties"][
    "Combustion Heat (kWh/Nm3)"
    ]["HHV"]["CombustionHeatHHVTotal"],
    10
  );
  worksheet
    .cell(currentRowIndex, 11)
    .value(
      jsonData["natual_gas_properties"]["Natural_gas_properties"][
      "Smoke Index ([Nm3/Gas Nm3])"
      ]["Dry"]
    );
  worksheet
    .cell(currentRowIndex, 12)
    .value(
      jsonData["natual_gas_properties"]["Natural_gas_properties"][
      "Smoke Index ([Nm3/Gas Nm3])"
      ]["Wet"]
    );
}
function getOilProperties(jsonData, worksheet, currentRowIndex) {
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Species")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("Analysis % mass")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("Analysis moles/kg")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 5)
    .value("Air moles/kg")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .range("F37:H37")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    })
    .value("Air/combustible ratio [Nm3air/kg]")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Pouvoir comburivore(Nm3air/kg)"]
    );

  currentRowIndex++;
  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    [
      "Carbon",
      "Hydrogen",
      "Oxygen",
      "Nitrogen",
      "Sulphur",
      "Humidity",
      "Total",
    ],
    2
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    38,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["carbon"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    39,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["hydrogen"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    40,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["oxygen"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    41,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["nitrogen"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    42,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["sulphur"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    43,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["humidity"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    44,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Mass%"
    ]["Total"],
    3
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    38,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["carbon"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    39,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["hydrogen"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    40,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["oxygen"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    41,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["nitrogen"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    42,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["sulphur"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    43,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["humidity"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    44,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ]["moleskgTotal"],
    4
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    38,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["carbon"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    39,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["hydrogen"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    40,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["oxygen"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    41,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["nitrogen"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    42,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["sulphur"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    43,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["humidity"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    44,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
    "Air (moles/kg)"
    ]["airTotal"],
    5
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Analysis"][
    "Moles / kg"
    ],
    6
  );

  worksheet
    .range("F38:G41")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Combustion Fumes [Nm3/kg fuel]")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 8)
    .value("CO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Fumes CO2(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("H2O")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Fumes H2O(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("N2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Fumes N2(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("SO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Fumes SO2(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .range("F42:G43")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Smoke Index [Nm3/kg fuel]")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 8)
    .value("Dry")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Smoke Index Dry(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("Wet")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Smoke Index Wet(Nm3/kg fuel)"]
    );
  currentRowIndex++;
  worksheet
    .range("F44:G45")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Combustion Heat [kWh/kg]")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 8)
    .value("HHV")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Heat HHV(kwh/kg)"]
    );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 8)
    .value("LHV")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 9)
    .value(
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"][
      "Air/combustible ratio"
      ]["Combustion Heat LHV(kwh/kg)"]
    );
}

//Thermal Balance //Combustion //Total/Average (Gas & Fuel)
function getTotalforCombustion(jsonData, worksheet) {
  worksheet.cell(52, 13)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  worksheet.cell(52, 22)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas"]["Total Consumption Gas"]) {
      worksheet.cell(53, 13)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas"]["Total Consumption Gas"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  else {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas"]["Total Consumption Gas"]) {
      worksheet.cell(53, 13)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas"]["Total Consumption Gas"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(53, 22)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas"]["Total Consumption Gas"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas(%)"]["Total Consumption Gas(%)"]) {
      worksheet.cell(54, 13)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas(%)"]["Total Consumption Gas(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas(%)"]["Total Consumption Gas(%)"]) {
      worksheet.cell(54, 13)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas(%)"]["Total Consumption Gas(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(54, 22)
        .value(jsonData["natual_gas_properties"]["Natural_gas_properties"]["Total Consumption Gas(%)"]["Total Consumption Gas(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil"]["Total Consumption Oil"]) {
      worksheet.cell(55, 13)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil"]["Total Consumption Oil"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil"]["Total Consumption Oil"]) {
      worksheet.cell(55, 13)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil"]["Total Consumption Oil"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(55, 22)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil"]["Total Consumption Oil"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil(%)"]["Total Consumption Oil(%)"]) {
      worksheet.cell(56, 13)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil(%)"]["Total Consumption Oil(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil(%)"]["Total Consumption Oil(%)"]) {
      worksheet.cell(56, 13)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil(%)"]["Total Consumption Oil(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(56, 22)
        .value(jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Total Consumption Oil(%)"]["Total Consumption Oil(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DFN Gas"]["Total DFN Gas(Nm3/h)"]) {
      worksheet.cell(57, 13)
        .value(jsonData["combustion"]["Total DFN Gas"]["Total DFN Gas(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DFN Gas"]["Total DFN Gas(Nm3/h)"]) {
      worksheet.cell(57, 13)
        .value(jsonData["combustion"]["Total DFN Gas"]["Total DFN Gas(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(57, 22)
        .value(jsonData["combustion"]["Total DFN Gas"]["Total DFN Gas(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DFN Oil"]["Total DFN Oil(Nm3/h)"]) {
      worksheet.cell(58, 13)
        .value(jsonData["combustion"]["Total DFN Oil"]["Total DFN Oil(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DFN Oil"]["Total DFN Oil(Nm3/h)"]) {
      worksheet.cell(58, 13)
        .value(jsonData["combustion"]["Total DFN Oil"]["Total DFN Oil(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(58, 22)
        .value(jsonData["combustion"]["Total DFN Oil"]["Total DFN Oil(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DFN Total"]["Total DFN Total(Nm3/h)"]) {
      worksheet.cell(59, 13)
        .value(jsonData["combustion"]["Total DFN Total"]["Total DFN Total(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DFN Total"]["Total DFN Total(Nm3/h)"]) {
      worksheet.cell(59, 13)
        .value(jsonData["combustion"]["Total DFN Total"]["Total DFN Total(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(59, 22)
        .value(jsonData["combustion"]["Total DFN Total"]["Total DFN Total(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DAN Gas"]["Total DAN Gas(Nm3/h)"]) {
      worksheet.cell(60, 13)
        .value(jsonData["combustion"]["Total DAN Gas"]["Total DAN Gas(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DAN Gas"]["Total DAN Gas(Nm3/h)"]) {
      worksheet.cell(60, 13)
        .value(jsonData["combustion"]["Total DAN Gas"]["Total DAN Gas(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(60, 22)
        .value(jsonData["combustion"]["Total DAN Gas"]["Total DAN Gas(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DAN Oil"]["Total DAN Oil(Nm3/h)"]) {
      worksheet.cell(61, 13)
        .value(jsonData["combustion"]["Total DAN Oil"]["Total DAN Oil(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DAN Oil"]["Total DAN Oil(Nm3/h)"]) {
      worksheet.cell(61, 13)
        .value(jsonData["combustion"]["Total DAN Oil"]["Total DAN Oil(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(61, 22)
        .value(jsonData["combustion"]["Total DAN Oil"]["Total DAN Oil(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["Total DAN Total"]["Total DAN Total(Nm3/h)"]) {
      worksheet.cell(62, 13)
        .value(jsonData["combustion"]["Total DAN Total"]["Total DAN Total(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["Total DAN Total"]["Total DAN Total(Nm3/h)"]) {
      worksheet.cell(62, 13)
        .value(jsonData["combustion"]["Total DAN Total"]["Total DAN Total(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(62, 22)
        .value(jsonData["combustion"]["Total DAN Total"]["Total DAN Total(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["batch_info"]["BatchData_Outputs"]["Total DGC Distribution(%)"]) {
      worksheet.cell(63, 13)
        .value(jsonData["batch_info"]["BatchData_Outputs"]["Total DGC Distribution(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["batch_info"]["BatchData_Outputs"]["Total DGC Distribution(%)"]) {
      worksheet.cell(63, 13)
        .value(jsonData["batch_info"]["BatchData_Outputs"]["Total DGC Distribution(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(63, 22)
        .value(jsonData["batch_info"]["BatchData_Outputs"]["Total DGC Distribution(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DGC"]["Total DGC Dehydration(kg/batch)"]) {
      worksheet.cell(64, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(kg/batch)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DGC"]["Total DGC Dehydration(kg/batch)"]) {
      worksheet.cell(64, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(kg/batch)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(64, 22)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(kg/batch)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DGC"]["Total DGC Dehydration(Nm3/h)"]) {
      worksheet.cell(65, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DGC"]["Total DGC Dehydration(Nm3/h)"]) {
      worksheet.cell(65, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(65, 22)
        .value(jsonData["combustion"]["DGC"]["Total DGC Dehydration(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DGC"]["Total DGC Decarbonation(kg)"]) {
      worksheet.cell(66, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(kg)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DGC"]["Total DGC Decarbonation(kg)"]) {
      worksheet.cell(66, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(kg)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(66, 22)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(kg)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DGC"]["Total DGC Decarbonation(Nm3/h)"]) {
      worksheet.cell(67, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DGC"]["Total DGC Decarbonation(Nm3/h)"]) {
      worksheet.cell(67, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(67, 22)
        .value(jsonData["combustion"]["DGC"]["Total DGC Decarbonation(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DGC"]["Total DGC Total(Nm3/h)"]) {
      worksheet.cell(68, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Total(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DGC"]["Total DGC Total(Nm3/h)"]) {
      worksheet.cell(68, 13)
        .value(jsonData["combustion"]["DGC"]["Total DGC Total(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(68, 22)
        .value(jsonData["combustion"]["DGC"]["Total DGC Total(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["combustionCorrelation"]["Total Air/Energy ratio(Mean for stoechio)"]) {
      worksheet.cell(69, 13)
        .value(jsonData["combustion"]["combustionCorrelation"]["Total Air/Energy ratio(Mean for stoechio)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["combustionCorrelation"]["Total Air/Energy ratio(Mean for stoechio)"]) {
      worksheet.cell(69, 13)
        .value(jsonData["combustion"]["combustionCorrelation"]["Total Air/Energy ratio(Mean for stoechio)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(69, 22)
        .value(jsonData["combustion"]["combustionCorrelation"]["Total Air/Energy ratio(Mean for stoechio)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["correlationOutput"]["Total Correlation"]) {
      worksheet.cell(70, 13)
        .value(jsonData["combustion"]["correlationOutput"]["Total Correlation"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["correlationOutput"]["Total Correlation"]) {
      worksheet.cell(70, 13)
        .value(jsonData["combustion"]["correlationOutput"]["Total Correlation"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(70, 22)
        .value(jsonData["combustion"]["correlationOutput"]["Total Correlation"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["resultingDexa"]["Total Dexa (Excess Air Flow)(Nm3/h)"]) {
      worksheet.cell(71, 13)
        .value(jsonData["combustion"]["resultingDexa"]["Total Dexa (Excess Air Flow)(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["resultingDexa"]["Total Dexa (Excess Air Flow)(Nm3/h)"]) {
      worksheet.cell(71, 13)
        .value(jsonData["combustion"]["resultingDexa"]["Total Dexa (Excess Air Flow)(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(71, 22)
        .value(jsonData["combustion"]["resultingDexa"]["Total Dexa (Excess Air Flow)(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["composition"]["Concentration_Outputs"]["O2 measured bottom(%)"]["O2 measured bottom(%)"]) {
      worksheet.cell(72, 13)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 measured bottom(%)"]["O2 measured bottom(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["composition"]["Concentration_Outputs"]["O2 measured bottom(%)"]["O2 measured bottom(%)"]) {
      worksheet.cell(72, 13)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 measured bottom(%)"]["O2 measured bottom(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(72, 22)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 measured bottom(%)"]["O2 measured bottom(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["resultingDexa"]["Total DAP(Parasitic Air Flow)(Nm3/h)"]) {
      worksheet.cell(73, 13)
        .value(jsonData["combustion"]["resultingDexa"]["Total DAP(Parasitic Air Flow)(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["resultingDexa"]["Total DAP(Parasitic Air Flow)(Nm3/h)"]) {
      worksheet.cell(73, 13)
        .value(jsonData["combustion"]["resultingDexa"]["Total DAP(Parasitic Air Flow)(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(73, 22)
        .value(jsonData["combustion"]["resultingDexa"]["Total DAP(Parasitic Air Flow)(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DFT"]["Total DFN+DGC+DEXA (Nm3/h)"]) {
      worksheet.cell(74, 13)
        .value(jsonData["combustion"]["DFT"]["Total DFN+DGC+DEXA (Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DFT"]["Total DFN+DGC+DEXA (Nm3/h)"]) {
      worksheet.cell(74, 13)
        .value(jsonData["combustion"]["DFT"]["Total DFN+DGC+DEXA (Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(74, 22)
        .value(jsonData["combustion"]["DFT"]["Total DFN+DGC+DEXA (Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["previousDAP"]["Total DFT Previous+DAP (Nm3/h)"]) {
      worksheet.cell(75, 13)
        .value(jsonData["combustion"]["previousDAP"]["Total DFT Previous+DAP (Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["previousDAP"]["Total DFT Previous+DAP (Nm3/h)"]) {
      worksheet.cell(75, 13)
        .value(jsonData["combustion"]["previousDAP"]["Total DFT Previous+DAP (Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(75, 22)
        .value(jsonData["combustion"]["previousDAP"]["Total DFT Previous+DAP (Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["DAT"]["Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"]) {
      worksheet.cell(76, 13)
        .value(jsonData["combustion"]["DAT"]["Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["DAT"]["Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"]) {
      worksheet.cell(76, 13)
        .value(jsonData["combustion"]["DAT"]["Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(76, 22)
        .value(jsonData["combustion"]["DAT"]["Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["composition"]["Concentration_Outputs"]["O2 Dry air Top(%)"]["O2 Dry air Top(%)"]) {
      worksheet.cell(77, 13)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 Dry air Top(%)"]["O2 Dry air Top(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["composition"]["Concentration_Outputs"]["O2 Dry air Top(%)"]["O2 Dry air Top(%)"]) {
      worksheet.cell(77, 13)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 Dry air Top(%)"]["O2 Dry air Top(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(77, 22)
        .value(jsonData["composition"]["Concentration_Outputs"]["O2 Dry air Top(%)"]["O2 Dry air Top(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["measuredCO2"]["Total Measured at top CO2(Dry air%)"]) {
      worksheet.cell(78, 13)
        .value(jsonData["combustion"]["measuredCO2"]["Total Measured at top CO2(Dry air%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["measuredCO2"]["Total Measured at top CO2(Dry air%)"]) {
      worksheet.cell(78, 13)
        .value(jsonData["combustion"]["measuredCO2"]["Total Measured at top CO2(Dry air%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(78, 22)
        .value(jsonData["combustion"]["measuredCO2"]["Total Measured at top CO2(Dry air%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedTopO2"]["Total Calculated at top O2(Dry air)"]) {
      worksheet.cell(79, 13)
        .value(jsonData["combustion"]["calculatedTopO2"]["Total Calculated at top O2(Dry air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedTopO2"]["Total Calculated at top O2(Dry air)"]) {
      worksheet.cell(79, 13)
        .value(jsonData["combustion"]["calculatedTopO2"]["Total Calculated at top O2(Dry air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(79, 22)
        .value(jsonData["combustion"]["calculatedTopO2"]["Total Calculated at top O2(Dry air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedTopCO2"]["Total Calculated at top CO2(Dry air)"]) {
      worksheet.cell(80, 13)
        .value(jsonData["combustion"]["calculatedTopCO2"]["Total Calculated at top CO2(Dry air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedTopCO2"]["Total Calculated at top CO2(Dry air)"]) {
      worksheet.cell(80, 13)
        .value(jsonData["combustion"]["calculatedTopCO2"]["Total Calculated at top CO2(Dry air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(80, 22)
        .value(jsonData["combustion"]["calculatedTopCO2"]["Total Calculated at top CO2(Dry air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedFumesO2"]["Total Calculated Fumes O2(Wet air)"]) {
      worksheet.cell(81, 13)
        .value(jsonData["combustion"]["calculatedFumesO2"]["Total Calculated Fumes O2(Wet air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedFumesO2"]["Total Calculated Fumes O2(Wet air)"]) {
      worksheet.cell(81, 13)
        .value(jsonData["combustion"]["calculatedFumesO2"]["Total Calculated Fumes O2(Wet air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(81, 22)
        .value(jsonData["combustion"]["calculatedFumesO2"]["Total Calculated Fumes O2(Wet air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedFumesCO2"]["Total Calculated Fumes CO2(Wet air)"]) {
      worksheet.cell(82, 13)
        .value(jsonData["combustion"]["calculatedFumesCO2"]["Total Calculated Fumes CO2(Wet air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedFumesCO2"]["Total Calculated Fumes CO2(Wet air)"]) {
      worksheet.cell(82, 13)
        .value(jsonData["combustion"]["calculatedFumesCO2"]["Total Calculated Fumes CO2(Wet air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(82, 22)
        .value(jsonData["combustion"]["calculatedFumesCO2"]["Total Calculated Fumes CO2(Wet air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedFumesH2O"]["Total Calculated Fumes H2O(Wet air)"]) {
      worksheet.cell(83, 13)
        .value(jsonData["combustion"]["calculatedFumesH2O"]["Total Calculated Fumes H2O(Wet air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedFumesH2O"]["Total Calculated Fumes H2O(Wet air)"]) {
      worksheet.cell(83, 13)
        .value(jsonData["combustion"]["calculatedFumesH2O"]["Total Calculated Fumes H2O(Wet air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(83, 22)
        .value(jsonData["combustion"]["calculatedFumesH2O"]["Total Calculated Fumes H2O(Wet air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedFumesN2"]["Total Calculated Fumes N2(Wet air)"]) {
      worksheet.cell(84, 13)
        .value(jsonData["combustion"]["calculatedFumesN2"]["Total Calculated Fumes N2(Wet air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedFumesN2"]["Total Calculated Fumes N2(Wet air)"]) {
      worksheet.cell(84, 13)
        .value(jsonData["combustion"]["calculatedFumesN2"]["Total Calculated Fumes N2(Wet air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(84, 22)
        .value(jsonData["combustion"]["calculatedFumesN2"]["Total Calculated Fumes N2(Wet air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["combustion"]["calculatedFumesVerify"]["Total Calculated Fumes Verification(Wet air)"]) {
      worksheet.cell(85, 13)
        .value(jsonData["combustion"]["calculatedFumesVerify"]["Total Calculated Fumes Verification(Wet air)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["combustion"]["calculatedFumesVerify"]["Total Calculated Fumes Verification(Wet air)"]) {
      worksheet.cell(85, 13)
        .value(jsonData["combustion"]["calculatedFumesVerify"]["Total Calculated Fumes Verification(Wet air)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(85, 22)
        .value(jsonData["combustion"]["calculatedFumesVerify"]["Total Calculated Fumes Verification(Wet air)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
}

//Temperatures (Efficiencies)
function getTotalforTemperatures(jsonData, worksheet) {
  worksheet.cell(90, 12)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  worksheet.cell(90, 21)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(91, 12)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Top(hot fumes)[°C]"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(91, 12)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Top(hot fumes)[°C]"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(91, 21)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Top(hot fumes)[°C]"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(92, 12)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Bottom(cold fumes)[°C]"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(92, 12)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Bottom(cold fumes)[°C]"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(92, 21)
      .value(jsonData["temperatures"]["Fumes_Temperature"]["Total Bottom(cold fumes)[°C]"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(93, 12)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Top(preheated air)[°C]"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(93, 12)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Top(preheated air)[°C]"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(93, 21)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Top(preheated air)[°C]"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(94, 12)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Bottom(cold air)[°C]"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(94, 12)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Bottom(cold air)[°C]"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(94, 21)
      .value(jsonData["temperatures"]["Air_Temperatures"]["Total Bottom(cold air)[°C]"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    if (jsonData["temperatures"]["Total Thermal Efficiency(%)"]) {
      worksheet.cell(95, 12)
        .value(jsonData["temperatures"]["Total Thermal Efficiency(%)"]["symmetricData"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  } else {
    if (jsonData["temperatures"]["Total Thermal Efficiency(%)"]) {
      worksheet.cell(95, 12)
        .value(jsonData["temperatures"]["Total Thermal Efficiency(%)"]["left"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
      worksheet.cell(95, 21)
        .value(jsonData["temperatures"]["Total Thermal Efficiency(%)"]["right"])
        .style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
          fontFamily: "arial",
        });
    }
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(96, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DAT(Nm3/h)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(96, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DAT(Nm3/h)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(96, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DAT(Nm3/h)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(97, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DFT(Nm3/h)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(97, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DFT(Nm3/h)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(97, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total DFT(Nm3/h)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(98, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes/Air Ratio"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(98, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes/Air Ratio"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(98, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes/Air Ratio"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(99, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Air Speed(m/s)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(99, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Air Speed(m/s)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(99, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Air Speed(m/s)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(100, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Speed(m/s)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(100, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Speed(m/s)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(100, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Speed(m/s)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(101, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (J/kg.°K) at TSA"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(101, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (J/kg.°K) at TSA"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(101, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (J/kg.°K) at TSA"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(102, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (KJ/kg.°K) at TEA"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(102, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (KJ/kg.°K) at TEA"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(102, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Air (KJ/kg.°K) at TEA"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(103, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(103, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(103, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(104, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(104, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(104, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(105, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gained Air Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(105, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gained Air Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(105, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gained Air Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(106, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Available Air Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(106, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Available Air Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(106, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Available Air Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(107, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Air Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(107, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Air Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(107, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Air Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(108, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Air Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(108, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Air Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(108, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Air Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(109, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gaz & Fuel Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(109, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gaz & Fuel Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(109, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Gaz & Fuel Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(110, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(110, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(110, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Fumes Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(111, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Fumes Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(111, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Fumes Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(111, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Furnace Fumes Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(112, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(112, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(112, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(113, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(113, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(113, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Energy Loss%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(114, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Chamber Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(114, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Chamber Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(114, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Chamber Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(115, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Chamber Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(115, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Chamber Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(115, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Chamber Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(116, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Bay Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(116, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Bay Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(116, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Bay Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(117, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(117, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(117, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total Max Furnace Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(118, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total EBM Energy Efficiency Definition(%)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(118, 12)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total EBM Energy Efficiency Definition(%)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(118, 21)
      .value(jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Total EBM Energy Efficiency Definition(%)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
}

//Calculated Fumes Correlation (Efficiencies)
function getTotalforCalculatedFumesCorr(jsonData, worksheet) {
  worksheet.cell(122, 12)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  worksheet.cell(122, 21)
    .value("Total/Average")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    });
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(123, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(123, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(123, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEF"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(124, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(124, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(124, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Cp Fumes (kJ/kg.°K) at TEA"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(125, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Fumes Energy(Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(125, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Fumes Energy(Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(125, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Fumes Energy(Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(126, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Furnace Fumes Energy (Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(126, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Furnace Fumes Energy (Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(126, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Furnace Fumes Energy (Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(127, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(Wh)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(127, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(Wh)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(127, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(Wh)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(128, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(%)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(128, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(%)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(128, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Energy Loss(%)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(129, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Chamber Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(129, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Chamber Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(129, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Chamber Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(130, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Chamber Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(130, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Chamber Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(130, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Chamber Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(131, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Bay Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(131, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Bay Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(131, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Bay Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(132, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Furnace Efficiency%"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(132, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Furnace Efficiency%"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(132, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total Max Furnace Efficiency%"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
  if (jsonData["furnace_type"] === "Cross Fired" && jsonData["chamber_type"] === "One Side") {
    worksheet.cell(133, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total EBM Energy Efficiency Definition(%)"]["symmetricData"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  } else {
    worksheet.cell(133, 12)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total EBM Energy Efficiency Definition(%)"]["left"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
    worksheet.cell(133, 21)
      .value(jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"]["Total EBM Energy Efficiency Definition(%)"]["right"])
      .style({
        horizontalAlignment: "center",
        verticalAlignment: "center",
        fontFamily: "arial",
      });
  }
}

function getSection1(jsonData, worksheet, currentRowIndex, date) {
  // section 1
  worksheet
    .range("A2:R2")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
      fontFamily: "arial",
    })
    .value(
      `${jsonData["customer_name"]} / ${jsonData["furnace_name"]} / ${date}`
    )
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  worksheet
    .range("B4:E4")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("BATCH INFORMATION")
    .merged(true);
  worksheet
    .range("G4:Q4")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("REGENERATORS PROPERTIES")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;

  getBatchInfo(jsonData, worksheet, currentRowIndex);
  getRegeneratorInfo(jsonData, worksheet, currentRowIndex);
}

function getSection2(jsonData, worksheet, currentRowIndex) {
  currentRowIndex = 16;
  // Section 2
  worksheet
    .range("A16:M16")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("FUEL INFORMATION")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  worksheet
    .range("B18:L18")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("NATURAL GAS PROPERTIES")
    .merged(true);
  currentRowIndex++;
  getGasProperties(jsonData, worksheet, currentRowIndex);

  currentRowIndex++;
  worksheet
    .range("B36:I36")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("HEAVY OIL PROPERTIES")
    .merged(true);

  currentRowIndex = 36;
  getOilProperties(jsonData, worksheet, currentRowIndex);
}

function getSection3(jsonData, worksheet, currentRowIndex) {
  currentRowIndex = 47;
  // Section 2
  worksheet
    .range("A47:V47")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("THERMAL BALANCE")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  worksheet
    .range("B49:V49")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("COMBUSTION")
    .merged(true);
  getChambers(
    jsonData,
    worksheet,
    51,
    "E51",
    "M51",
    "N51",
    "U51",
    5,
    14,
    52
  );
  currentRowIndex = 53;

  // Consumption
  worksheet
    .range("B53:B56")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Consumption")
    .merged(true);

  getTotalforCombustion(jsonData, worksheet);

  worksheet
    .range("C53:C54")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Gaz")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Consumption Gas"]["Consumption Gas(Nm3/h)"]) {
    getChamberPortsData(
      jsonData,
      worksheet,
      currentRowIndex,
      jsonData["natual_gas_properties"]["Natural_gas_properties"]["Consumption Gas"]["Consumption Gas(Nm3/h)"],
      5,
      14
    );
  }
  if (jsonData["natual_gas_properties"]["Natural_gas_properties"]["Consumption Gas(%)"]["Consumption Gas(%)"]) {
    getChamberPortsData(
      jsonData,
      worksheet,
      currentRowIndex + 1,
      jsonData["natual_gas_properties"]["Natural_gas_properties"]["Consumption Gas(%)"]["Consumption Gas(%)"],
      5,
      14
    );
  }

  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .range("C55:C56")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Fuel")
    .merged(true);
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("(kg/h)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Consumption Oil"]["Consumption Oil(kg/h)"]) {
    getChamberPortsData(
      jsonData,
      worksheet,
      currentRowIndex,
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Consumption Oil"]["Consumption Oil(kg/h)"],
      5,
      14
    );
  }

  if (jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Consumption Oil(%)"]["Consumption Oil(%)"]) {
    getChamberPortsData(
      jsonData,
      worksheet,
      currentRowIndex + 1,
      jsonData["heavy_oil_properties"]["Heavy_Oil_Properties"]["Consumption Oil(%)"]["Consumption Oil(%)"],
      5,
      14
    );
  }

  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  currentRowIndex++;

  // DFN
  worksheet
    .range("B57:B59")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("DFN (Neutral Fumes Flow)")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("Gaz")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DFN Gas"]["DFN Gas(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Oil")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DFN Oil"]["DFN Oil(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Total")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DFN Total"]["DFN Total(Nm3/h)"],
    5,
    14
  )
  currentRowIndex++;

  // DAN
  worksheet
    .range("B60:B62")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("DAN (Neutral Air Flow)")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("Gaz")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DAN Gas"]["DAN Gas(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Oil")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DAN Oil"]["DAN Oil(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Total")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(jsonData, worksheet, currentRowIndex, jsonData["combustion"]["DAN Total"]["DAN Total(Nm3/h)"], 5, 14)
  currentRowIndex++;

  // DGC
  worksheet
    .range("B63:B68")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("DGC (Batch Gaz Flow)")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("Distribution")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(jsonData, worksheet, currentRowIndex, jsonData["batch_info"]["BatchData_Inputs"]["DGC Distribution(%)"], 5, 14)
  currentRowIndex++;
  worksheet
    .range("C64:C65")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Dehydration")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 4)
    .value("[kg/batch]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DGC"]["DGC Dehydration(kg/batch)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DGC"]["DGC Dehydration(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .range("C66:C67")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Decarbonation")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 4)
    .value("[kg/batch]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DGC"]["DGC Decarbonation(kg)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DGC"]["DGC Decarbonation(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Total")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DGC"]["DGC Total(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;

  // Combustion Correlation
  worksheet
    .range("B69:B70")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Combustion Correlation")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("Air/Energy ratio")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("Mean for stoechio")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["combustionCorrelation"][
    "Air/Energy ratio(Mean for stoechio)"
    ],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("to be changed so that O2 calculated is equal to measured (below)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("Correlation")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["correlationInput"]["Correlation"],
    5,
    14
  );
  currentRowIndex++;

  // Resulting Air Flows
  worksheet
    .range("B71:B73")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Resulting Air Flows")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("DEXA (Excess Air Flow)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("(Nm3/h)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["resultingDexa"]["Dexa (Excess Air Flow)(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .range("C72:C73")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("DAP (Parasitic Air Flow)")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 4)
    .value("O2 measured bottom %")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(jsonData, worksheet, currentRowIndex, jsonData["composition"]["Concentration_Inputs"]["O2 measured bottom%"], 5, 14)
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["resultingDexa"]["DAP(Parasitic Air Flow)(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(74, 2)
    .value("DFT (Total Fumes Flow)")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })

  worksheet
    .cell(75, 2)
    .value("DFT (Total Fumes Flow)")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
  worksheet
    .cell(currentRowIndex, 3)
    .value("DFN+DGC+DEXA")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("(Nm3/h)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DFT"]["DFN+DGC+DEXA (Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Previous + DAP")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["previousDAP"]["DFT Previous+DAP (Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;

  //DAT (Total Air Flow)
  worksheet
    .cell(currentRowIndex, 2)
    .value("DAT (Total Air Flow)")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("DAN+DEXA")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 4)
    .value("[Nm3/h]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["DAT"]["DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)"],
    5,
    14
  );
  currentRowIndex++;

  // Measured at top
  worksheet
    .range("B77:B78")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Measured at top")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("O2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(jsonData, worksheet, currentRowIndex, jsonData["composition"]["Concentration_Inputs"]["O2 Dry air Top%"], 5, 14)
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("CO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["measuredCO2"]["Measured at top CO2(Dry air%)"],
    5,
    14
  );
  worksheet
    .range("D77:D78")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Dry air %")
    .merged(true);
  currentRowIndex++;

  // Calculated at top
  worksheet
    .range("B79:B80")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Calculated at top")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("O2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedTopO2"]["Calculated at top O2(Dry air)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("CO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedTopCO2"][
    "Calculated at top CO2(Dry air)"
    ],
    5,
    14
  );
  worksheet
    .range("D79:D80")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Dry air %")
    .merged(true);
  currentRowIndex++;

  // Calculated Fumes
  worksheet
    .range("B81:B85")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Calculated Fumes")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("O2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedFumesO2"]["Calculated Fumes O2(Wet air)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("CO2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedFumesCO2"][
    "Calculated Fumes CO2(Wet air)"
    ],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("H2O")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedFumesH2O"][
    "Calculated Fumes H2O(Wet air)"
    ],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("N2")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedFumesN2"]["Calculated Fumes N2(Wet air)"],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("Verification")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["combustion"]["calculatedFumesVerify"][
    "Calculated Fumes Verification(Wet air)"
    ],
    5,
    14
  );
  currentRowIndex++;
  worksheet
    .range("D81:D85")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Wet air %")
    .merged(true);
  currentRowIndex++;
  currentRowIndex++;

  worksheet
    .range("B87:U87")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("TEMPERATURES")
    .merged(true);
  currentRowIndex++;
  worksheet
    .range("B89:C90")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("MEASURED TEMPERATURES")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  getChambers(
    jsonData,
    worksheet,
    89,
    "D89",
    "L89",
    "M89",
    "U89",
    4,
    13,
    90
  );
  currentRowIndex = 91;

  getTotalforTemperatures(jsonData, worksheet)

  worksheet
    .cell(currentRowIndex, 2)
    .value("Bottom (cold air) [°C]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Fumes_Temperature"]["Top(hot fumes)[°C]"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Top (preheated air) [°C]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Fumes_Temperature"]["Bottom(cold fumes)[°C]"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Bottom (cold fumes) [°C]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Temperatures"]["Top(preheated air)[°C]"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Top (hot fumes) [°C]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Temperatures"]["Bottom(cold air)[°C]"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B95:C95")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Thermal Efficiency [%]")
    .merged(true);
  worksheet
    .range("C91:C94")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("[°C]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Thermal Efficiency(%)"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("DAT")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["DAT(Nm3/h)"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("DFT")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["DFT(Nm3/h)"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Fumes/Air Ratio")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Fumes/Air Ratio"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Air Speed")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"]["Air Speed(m/s)"],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Fumes Speed")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Fumes Speed(m/s)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("C96:C97")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("[Nm3/h]")
    .merged(true);
  worksheet
    .range("C99:C100")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("[m/s]")
    .merged(true);
  worksheet
    .range("B101:C101")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Air at TSA [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Cp Air (J/kg.°K) at TSA"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B102:C102")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Air at TEA [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Cp Air (KJ/kg.°K) at TEA"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B103:C103")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Fumes at TEF [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Cp Fumes (kJ/kg.°K) at TEF"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B104:C104")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Fumes at TEA [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Cp Fumes (kJ/kg.°K) at TEA"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Gained Air Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Gained Air Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Available Air Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Available Air Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Furnace Air Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Furnace Air Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Max Furnace Air Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Max Furnace Air Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Gaz&Fuel Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Gaz & Fuel Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Fumes Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Fumes Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Furnace Fumes Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Furnace Fumes Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B112:B113")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Energy Loss")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("[Wh]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Energy Loss (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Energy Loss%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Chamber Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Chamber Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Max Chamber Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Max Chamber Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Bay efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Bay Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Max Furnace Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "Max Furnace Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B118:C118")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("EBM Energy Efficiency Definition [%]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["temperatures"]["Air_Fumes_Temperature_Outputs"][
    "EBM Energy Efficiency Definition(%)"
    ],
    4,
    13
  );

  worksheet
    .range("B121:C122")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("CALCULATED FUMES CORRELATION")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  getChambers(
    jsonData,
    worksheet,
    currentRowIndex + 1,
    "D121",
    "L121",
    "M121",
    "U121",
    4,
    13,
    122
  );
  currentRowIndex = 123;

  getTotalforCalculatedFumesCorr(jsonData, worksheet)
  worksheet
    .range("B123:C123")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Fumes at TEF [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Cp Fumes (kJ/kg.°K) at TEF"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B124:C124")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Cp Fumes at TEA [kJ/kg.K]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Cp Fumes (kJ/kg.°K) at TEA"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Fumes Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Fumes Energy(Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Furnace Fumes Energy")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Furnace Fumes Energy (Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("C125:C126")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("[Wh]")
    .merged(true);
  worksheet
    .range("B127:B128")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("Energy Loss")
    .merged(true);
  worksheet
    .cell(currentRowIndex, 3)
    .value("[Wh]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Energy Loss(Wh)"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Energy Loss%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Chamber Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Chamber Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Max Chamber Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Max Chamber Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Bay efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Bay Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .cell(currentRowIndex, 2)
    .value("Max Furnace Efficiency")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  worksheet
    .cell(currentRowIndex, 3)
    .value("[%]")
    .style({
      bold: true,
      horizontalAlignment: "center",
      verticalAlignment: "center",
      wrapText: true,
    });
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "Max Furnace Efficiency%"
    ],
    4,
    13
  );
  currentRowIndex++;
  worksheet
    .range("B133:C133")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
    })
    .value("EBM Energy Efficiency Definition [%]")
    .merged(true);
  getChamberPortsData(
    jsonData,
    worksheet,
    currentRowIndex,
    jsonData["calculated_fumes_correlation"]["Calculated Fumes Correlation"][
    "EBM Energy Efficiency Definition(%)"
    ],
    4,
    13
  );
}

function getSection4(jsonData, worksheet, currentRowIndex) {
  currentRowIndex = 2;
  worksheet
    .range("X2:AR2")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("FUMES COMPOSITION & CARRY OVER ANALYSIS")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  currentRowIndex++;
  worksheet
    .range("Y4:AK4")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("FUMES COMPOSITION")
    .merged(true);
  currentRowIndex++;
  worksheet
    .range("Y6:AB6")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Fumes Composition")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 29).value("CO2");
  worksheet.cell(currentRowIndex, 30).value("N2");
  worksheet.cell(currentRowIndex, 31).value("H2O");
  worksheet.cell(currentRowIndex, 32).value("O2");
  worksheet.cell(currentRowIndex, 33).value("Total");
  worksheet.cell(currentRowIndex, 34).value("ρ (kg/m3)");
  worksheet.cell(currentRowIndex, 35).value("M (g/mol)");
  worksheet
    .range("Y7:Y38")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Plant gas measurement at top chamber")
    .merged(true);
  worksheet
    .range("Z7:Z22")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Left Side")
    .merged(true);
  worksheet
    .range("Z23:Z38")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Right Side")
    .merged(true);
  currentRowIndex++;
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Inputs"]["CO2 concentration[vol%]"],
    29
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    8,
    jsonData["composition"]["Concentration_Outputs"]["CO2(Mass%)"],
    29
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Outputs"]["N2 (%)Vol"],
    30
  );

  insertFumesComposition(
    jsonData,
    worksheet,
    8,
    jsonData["composition"]["Concentration_Outputs"]["N2(Mass%)"],
    30
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Inputs"]["H2O concentration[vol%]"],
    31
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    8,
    jsonData["composition"]["Concentration_Outputs"]["H2O(Mass%)"],
    31
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Inputs"]["O2 concentration[vol%]"],
    32
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    8,
    jsonData["composition"]["Concentration_Outputs"]["O2(Mass%)"],
    32
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Outputs"]["Total (CO2, N2, H2O, O2)"],
    33,
    "AG"
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Outputs"]["ρ(kg/m3)"],
    34,
    "AH"
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    7,
    jsonData["composition"]["Concentration_Outputs"]["M(g/mol)"],
    35,
    "AI"
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    23,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27,
    "AA"
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27,
    "AA"
  );
  worksheet
    .range("Y40:Y71")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Calculated Fumes")
    .merged(true);
  worksheet
    .range("Z40:Z55")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Left Side")
    .merged(true);
  worksheet
    .range("Z56:Z71")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Right Side")
    .merged(true);
  currentRowIndex++;
  currentRowIndex = 40;
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Calculated Fumes CO2(vol%)"],
    29
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Calculated Fumes N2(vol%)"],
    30
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Calculated Fumes H2O(vol%)"],
    31
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Calculated Fumes O2(vol%)"],
    32
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes vol%"],
    33,
    "AG"
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes ρ(kg/m3)"],
    34,
    "AH"
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    40,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes M(g/mol)"],
    35,
    "AI"
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    41,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes CO2(Mass%)"],
    29
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    41,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes N2(Mass%)"],
    30
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    41,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes H2O(Mass%)"],
    31
  );
  insertFumesComposition(
    jsonData,
    worksheet,
    41,
    jsonData["calculated_fumes_composition"]["Calculated_Fumes_Composition"]["Total Calculated Fumes O2(Mass%)"],
    32
  );

  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27,
    "AA"
  );
  currentRowIndex = 56;
  insertFixedValueVrt(
    jsonData,
    worksheet,
    currentRowIndex,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27,
    "AA"
  );
}

function insertMeasurementsByPort(jsonData, worksheet, val, row, col) {
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["Carryovers"]["sio2"],
    28
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["Carryovers"]["cao"],
    29
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["Carryovers"]["mgo"],
    30
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["Carryovers"]["al2o3"],
    31
  );

  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["AlkalineCompounds"]["na2o"],
    32
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["AlkalineCompounds"]["k2o"],
    33
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["AlkalineCompounds"]["so4"],
    34
  );

  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["p2o5"],
    35
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["cr2o3"],
    36
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["fe2o3"],
    37
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["pbo"],
    38
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["v2o5"],
    39
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["cl"],
    40
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["OthersCompounds"]["no3"],
    41
  );
  insertFixedValueVrt(
    jsonData,
    worksheet,
    row,
    jsonData["mesurements_by_port"][val]["Total"],
    42
  );
}

function getSection5(jsonData, worksheet, currentRowIndex) {
  currentRowIndex = 73;
  // Section 2
  worksheet
    .range("Y73:AQ73")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("DUSTS ANALYSIS")
    .merged(true);
  currentRowIndex++;
  worksheet.cell(currentRowIndex, 2).value(""); // Empty cell for spacing
  worksheet
    .range("Y75:AA76")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Mean LEFT/RIGHT measurements by port")
    .merged(true);
  worksheet
    .range("AB75:AE75")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Carryovers")
    .merged(true);
  worksheet
    .range("AF75:AH75")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Alkaline Compounds")
    .merged(true);
  worksheet
    .range("AI75:AO75")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Others")
    .merged(true);
  currentRowIndex = 76;
  // insertvalueHrz(jsonData, worksheet, currentRowIndex, ["SiO2", "CaO", "MgO", "AI2O3", "Na2O", "K2O", "SO4", "P2O5", "Cr2O3", "Fe2O3", "PbO", "V2O5", "Cl", "NO3", "Total"], 28)

  worksheet.cell(currentRowIndex, 28).value("SiO2");
  worksheet.cell(currentRowIndex, 29).value("CaO");
  worksheet.cell(currentRowIndex, 30).value("MgO");
  worksheet.cell(currentRowIndex, 31).value("AI2O3");
  worksheet.cell(currentRowIndex, 32).value("Na2O");
  worksheet.cell(currentRowIndex, 33).value("K2O");
  worksheet.cell(currentRowIndex, 34).value("SO4");
  worksheet.cell(currentRowIndex, 35).value("P2O5");
  worksheet.cell(currentRowIndex, 36).value("Cr2O3");
  worksheet.cell(currentRowIndex, 37).value("Fe2O3");
  worksheet.cell(currentRowIndex, 38).value("PbO");
  worksheet.cell(currentRowIndex, 39).value("V2O5");
  worksheet.cell(currentRowIndex, 40).value("Cl");
  worksheet.cell(currentRowIndex, 41).value("NO3");
  worksheet.cell(currentRowIndex, 42).value("Total");
  worksheet
    .range("Y77:Z84")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Measured (Mg/Nm3 )")
    .merged(true);
  insertFixedValueVrt(
    jsonData,
    worksheet,
    77,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27
  );
  insertMeasurementsByPort(jsonData, worksheet, "mesurements_by_port", 77, 28);
  worksheet
    .range("Y85:Z92")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Generates per hour (Gr/h)")
    .merged(true);
  insertFixedValueVrt(
    jsonData,
    worksheet,
    85,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27
  );
  insertMeasurementsByPort(jsonData, worksheet, "GeneratesPerHour", 85, 28);
  worksheet
    .range("Y93:Z100")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Generates per year (t/y)")
    .merged(true);
  insertFixedValueVrt(
    jsonData,
    worksheet,
    93,
    ["1", "2", "3", "4", "5", "6", "7", "8"],
    27
  );
  insertMeasurementsByPort(jsonData, worksheet, "GeneratesPerYear", 93, 28);
  insertFixedValueVrt(jsonData, worksheet, 101, ["Average", "Total"], 27);
  insertMeasurementsByPort(jsonData, worksheet, "AverageYear", 101, 28);
  insertMeasurementsByPort(jsonData, worksheet, "TotalYear", 102, 28);
  worksheet
    .range("Y104:Z104")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Carryovers Si+Ca+Al+Mg")
    .merged(true);
  worksheet
    .cell(104, 28)
    .value(
      jsonData["mesurements_by_port"]["OverallDusts"][
      "Carryovers Si+Ca+Al+Mg(Mean/Chamber(t/y))"
      ]
    );
  worksheet
    .range("Y105:Z105")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Alkaline Compounds  Na+K+SO")
    .merged(true);
  worksheet
    .cell(105, 28)
    .value(
      jsonData["mesurements_by_port"]["OverallDusts"][
      "Alkaline Compounds Na+K+SO(Mean/Chamber(t/y))"
      ]
    );
  worksheet
    .range("Y106:Z106")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Others")
    .merged(true);
  worksheet
    .cell(106, 28)
    .value(
      jsonData["mesurements_by_port"]["OverallDusts"][
      "Others(Mean/Chamber(t/y))"
      ]
    );
  worksheet
    .range("Y107:Z107")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Overall")
    .merged(true);
  worksheet
    .cell(107, 28)
    .value(
      jsonData["mesurements_by_port"]["OverallDusts"][
      "Overall(Mean/Chamber(t/y))"
      ]
    );
  worksheet
    .range("AA104:AA107")
    .style({
      horizontalAlignment: "center",
      verticalAlignment: "center",
      bold: true,
      wrapText: true,
      fill: "0d1d54",
      fontColor: "FFFFFF",
    })
    .value("Mean / Chamber  (t/y)")
    .merged(true);
}

function generateExcelFile(data) {
  console.log(data, "excel data");
  let result = data.data.trials[0];
  let [date] = result.trial_date.split("T");
  const jsonData = {
    trial_date: date,
    plant: result.plant,
    no_of_port: result.no_of_port,
    furnace_type: result.furnace_type,
    furnace_name: result.furnace,
    customer_name: result.customer_name,
    country: result.country,
    chamber_type: result.chamber_type,
    cumulative: "{}",
    mesurements_by_port: result.mesurements_by_port,
    heavy_oil_properties: result.heavy_oil_properties,
    natual_gas_properties: result.natual_gas_properties,
    fumes_correlation: result.fumes_correlation,
    composition: result.composition,
    calculated_fumes_composition: result.calculated_fumes_composition,
    batch_info: result.batch_info,
    regenerator_properties: result.regenerator_properties,
    calculated_fumes_correlation: result.calculated_fumes_correlation,
    combustion: result.combustion,
    temperatures: result.temperatures,
  };
  console.log(jsonData, "jsonData")
  // XlsxPopulate.fromBlankAsync()
  //   .then((workbook) => {
  //     // Create worksheet
  //     const worksheet = workbook.sheet(0);

  //     let currentRowIndex = 1;

  //     // Add section and sub-section keys as horizontal header
  //     worksheet.cell(currentRowIndex, 1).value(""); // Empty cell for spacing

  //     currentRowIndex++;
  //     getSection1(jsonData, worksheet, currentRowIndex, date);
  //     getSection2(jsonData, worksheet, currentRowIndex);
  //     getSection3(jsonData, worksheet, currentRowIndex);
  //     getSection4(jsonData, worksheet, currentRowIndex);
  //     getSection5(jsonData, worksheet, currentRowIndex);
  //     worksheet.column("B").width(20);
  //     worksheet.column("C").width(15);
  //     worksheet.column("D").width(15);
  //     worksheet.column("E").width(15);
  //     worksheet.column("F").width(15);
  //     worksheet.column("G").width(15);
  //     worksheet.column("H").width(15);
  //     worksheet.column("I").width(15);
  //     worksheet.column("J").width(15);
  //     worksheet.column("K").width(15);
  //     worksheet.column("L").width(15);
  //     worksheet.column("M").width(15);
  //     worksheet.column("N").width(15);
  //     worksheet.column("O").width(15);
  //     worksheet.column("P").width(15);
  //     worksheet.column("Q").width(15);
  //     worksheet.column("R").width(15);
  //     worksheet.column("S").width(15);
  //     worksheet.column("T").width(15);
  //     worksheet.column("U").width(15);
  //     worksheet.column("V").width(15);
  //     worksheet.column("W").width(15);
  //     worksheet.column("X").width(15);
  //     worksheet.column("Y").width(15);
  //     worksheet.column("Z").width(15);
  //     worksheet.column("AA").width(15);
  //     worksheet.column("AB").width(15);
  //     worksheet.column("AC").width(15);
  //     worksheet.column("AD").width(15);
  //     worksheet.column("AE").width(15);
  //     worksheet.column("AF").width(15);
  //     worksheet.column("AG").width(15);
  //     worksheet.column("AH").width(15);
  //     worksheet.column("AI").width(15);
  //     worksheet.column("AJ").width(15);
  //     worksheet.column("AK").width(15);
  //     worksheet.column("AL").width(15);
  //     worksheet.column("AM").width(15);
  //     worksheet.column("AN").width(15);
  //     worksheet.column("AO").width(15);

  //     // Adjust section header column width
  //     worksheet.column(1).width(3);

  //     // Generate the Excel file
  //     return workbook.outputAsync();
  //   })
  //   .then((excelFile) => {
  //     // Save the file
  //     const fileName = `${date}_${result.customer_name}.xlsx`;
  //     const file = new Blob([excelFile], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     saveAs(file, fileName);
  //     console.log("excel generated")
  //   })
    .catch((error) => {
      console.error("Error generating Excel file:", error);
    });
}

export default generateExcelFile;